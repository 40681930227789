export const processErrorToString = (err: any): string => {
  let message: string | undefined = '';
  if (typeof err === 'string') {
    message = err;
  } else if (err instanceof Error) {
    message = err.message;
  } else if (err === undefined) {
    message = 'undefined';
  } else {
    try {
      message = JSON.stringify(err);
    } catch (err) {
      message = `Unable to parse error [${(err as Error).message}]`;
    }
  }
  return message;
};
