import React, {useEffect, useRef, useState} from 'react';
import {
  StyleSheet,
  Animated,
  Easing,
  ActivityIndicator,
  Dimensions,
} from 'react-native';

import {COLOR_BACKGROUND, COLOR_YELLOW} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  isLoading: boolean;
  displayDurationMs?: number;
}

export const LoadingAnimation: React.FC<IProps> = (props) => {
  const {isLoading, displayDurationMs = 1000} = props;
  const [removeView, setRemoveView] = useState(false);
  const screenOpacity = useRef(new Animated.Value(1)).current;
  const logoOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (!isLoading) {
      Animated.timing(screenOpacity, {
        toValue: 0,
        duration: displayDurationMs,
        easing: Easing.in(Easing.cubic),
        useNativeDriver: true,
      }).start(() => setRemoveView(true));
      Animated.timing(logoOpacity, {
        toValue: 0,
        duration: displayDurationMs - displayDurationMs / 5,
        easing: Easing.inOut(Easing.cubic),
        useNativeDriver: true,
      }).start();
    }
  }, [isLoading, screenOpacity, logoOpacity, displayDurationMs]);

  useEffect(() => {
    Animated.timing(logoOpacity, {
      toValue: 1,
      duration: 1400,
      delay: 200,
      easing: Easing.inOut(Easing.cubic),
      useNativeDriver: true,
    }).start();
  }, [logoOpacity]);

  return !removeView ? (
    <Animated.View
      style={[
        {
          ...styles.container,
          opacity: screenOpacity,
        },
        props.style,
      ]}
      pointerEvents="none">
      <Animated.View style={{...styles.loaderContainer, opacity: logoOpacity}}>
        <ActivityIndicator size="large" color={COLOR_YELLOW} />
      </Animated.View>
    </Animated.View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: Dimensions.get('screen').width,
    height: Dimensions.get('screen').height,
    flex: 1,
    backgroundColor: COLOR_BACKGROUND,
  },
  loaderContainer: {
    width: 100,
    height: 20,
    marginBottom: 85,
  },
});
