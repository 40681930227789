import React, {useEffect} from 'react';

import {
  IOverlay,
  IOverlayViewProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';
import './ShareOverlay.scss';

import {trackScreenView} from '../../../analytics/FirebaseAnalytics';
import {MessageOverlay} from '../Containers/MessageOverlay';
import {ShareComponent} from './ShareComponent';

interface IProps extends IOverlay, IOverlayViewProps {}

export const ShareOverlay: React.FC<IProps> = (props) => {
  useEffect(() => {
    trackScreenView('ShareOverlay');
  }, []);
  useCloseOverlayAfterTransition(
    props.displayState,
    props.onTransitionOutComplete,
    props.transitionDuration,
  );
  return (
    <MessageOverlay
      closing={props.displayState === OverlayDisplayState.TRANSITION_OUT}
      transitionDuration={props.transitionDuration}>
      <ShareComponent onClose={props.onClose} />
    </MessageOverlay>
  );
};
