import {IAction, ActionType} from '../Actions';
import {ILocalAnswersState} from '../../state/AppState';
import {TFirebaseCompEntry} from '../../../interfaces/firestore/FirestoreClientInterfaces';

export const setLocalAnswers = (
  answers: ILocalAnswersState,
): IAction<ILocalAnswersState> => ({
  payload: answers,
  type: ActionType.SET_LOCAL_ANSWERS,
});

export const cleanUpLocalAnswers = (
  answers: TFirebaseCompEntry[],
): IAction<TFirebaseCompEntry[]> => ({
  payload: answers,
  type: ActionType.CLEAN_UP_LOCAL_ANSWERS,
});
