import {UploadTask, ref, uploadBytesResumable} from 'firebase/storage';
import {Epic, StateObservable, ofType} from 'redux-observable';
import {Subject, concat, of} from 'rxjs';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';

import {ActionType, IAction} from '@chancer/common/lib/core/actions/Actions';
import {
  IFetchMediaUpload,
  IUploadMediaSettings,
  uploadImageError,
  uploadImageProgress,
  uploadImageRequest,
  uploadImageResponse,
} from '@chancer/common/lib/core/actions/mediaUploads/MediaUploadsActions';
import {processErrorToString} from '@chancer/common/lib/utils/ErrorUtils';
import log from '@chancer/common/lib/utils/Log';

import {storage} from '../../firebase/Firebase';
import {getConfig} from '../../selectors/config/WebConfigSelectors';
import {IWebAppState} from '../../state/WebAppState';

export const uploadImageEpic: Epic<
  IAction<IFetchMediaUpload>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_UPLOAD_IMAGE),
    switchMap((action) =>
      uploadStream()(state$, action.payload.settings, action.payload.data),
    ),
  );

export const uploadStream =
  () =>
  (
    state$: StateObservable<IWebAppState>,
    settings: IUploadMediaSettings,
    fileDetails: {asString: string; asBlob: Blob},
  ) =>
    of(fileDetails).pipe(
      map((fileDetails) =>
        createUploadDetails(
          settings,
          fileDetails.asString,
          fileDetails.asBlob,
          state$.value,
        ),
      ),
      switchMap((uploadDetails) =>
        concat(
          of(uploadImageProgress(settings.uploadId, 0.01)),
          of(
            uploadImageRequest({
              uploadId: settings.uploadId,
              localPath: uploadDetails.localFileAsString,
              remotePath: uploadDetails.remotePath,
            }),
          ),
          createUploadStream()(
            uploadDetails.localFile,
            uploadDetails.remotePath,
          ).pipe(
            map((progress) =>
              uploadImageProgress(settings.uploadId, Math.max(progress, 0.01)),
            ),
            catchError((err) =>
              of(
                uploadImageError({
                  uploadId: settings.uploadId,
                  error: processErrorToString(err),
                }),
              ),
            ),
          ),
          of(
            uploadImageResponse({
              uploadId: settings.uploadId,
              url: uploadDetails.remoteUrl,
            }),
          ),
          of(settings.onSuccessAction).pipe(
            filter((factory) => factory !== undefined),
            map((factory) => factory!(uploadDetails.remoteUrl)),
          ),
        ),
      ),
      catchError((err) =>
        of(
          uploadImageError({
            uploadId: settings.uploadId,
            error: processErrorToString(err),
          }),
        ).pipe(tap(() => log.warning(err))),
      ),
    );

const createUploadDetails = (
  settings: IUploadMediaSettings,
  localFileString: string,
  localFileBlob: Blob,
  state: IWebAppState,
) => {
  const imageName: string = `${new Date().valueOf()}.jpg`;
  const cdnUrl = getConfig(state).cdnUrl;
  const remotePath = `${settings.remoteFolderPath}/${imageName}`;

  return {
    localFile: localFileBlob,
    localFileAsString: localFileString,
    remotePath,
    remoteUrl: `${cdnUrl}/${remotePath}`,
  };
};

export const createResizedImageStream = async (
  width: number,
  height: number,
  localFile: File,
) => {
  return new Promise<{asString: string; asBlob: Blob}>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.onload = () => {
        try {
          const canvas = document.createElement('canvas');

          const ratio = Math.min(
            Math.max(width / image.width, height / image.height),
            1,
          );
          canvas.width = image.width * ratio;
          canvas.height = image.height * ratio;
          canvas
            .getContext('2d')
            ?.drawImage(image, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {
            if (!blob) {
              reject(new Error('Could not create blob'));
              return;
            }
            resolve({
              asString: canvas.toDataURL('image/jpeg'),
              asBlob: blob,
            });
          }, 'image/jpeg');
        } catch (err) {
          reject(err);
          return;
        }
      };
      image.onerror = (err) => {
        reject(err);
        return;
      };
      if (reader.result) {
        image.src = reader.result as string;
      } else {
        reject(new Error('Could not read contents of inage file'));
        return;
      }
    };
    reader.onerror = (err) => {
      reject(err);
      return;
    };
    reader.readAsDataURL(localFile);
  });
};

const createUploadStream = () => (localFile: Blob, remotePath: string) => {
  const subject = new Subject<number>();
  const reference = ref(storage(), remotePath);

  let uploadTask: UploadTask = uploadBytesResumable(reference, localFile);

  uploadTask.on('state_changed', (taskSnapshot) => {
    subject.next(taskSnapshot.bytesTransferred / taskSnapshot.totalBytes);
  });
  uploadTask.then(() => {
    subject.next(1);
    subject.complete();
  });
  uploadTask.catch((err) => {
    subject.error(err);
  });
  return subject;
};
