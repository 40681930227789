import qs from 'qs';
import {createSelector} from 'reselect';

import {getCurrentCompetitionsLocalAnswers} from '@chancer/common/lib/core/selectors/answers/AnswersSelectors';
import {getLocalStorage} from '@chancer/common/lib/core/selectors/localStorage/LocalStorageSelectors';
import {getUser} from '@chancer/common/lib/core/selectors/user/UserSelectors';
import {ChancerURLParams} from '@chancer/common/lib/core/state/model/ChancerUrlParams';
import {LocalStorageKey} from '@chancer/common/lib/core/state/model/LocalStorage';
import {
  MILLISECONDS_IN_DAY,
  MILLISECONDS_IN_MINUTE,
} from '@chancer/common/lib/utils/DateUtils';
import log from '@chancer/common/lib/utils/Log';
import {IWebAppState} from '../../state/WebAppState';

const INIT_HISTORY_LENGTH: number = window.history.length;

export const getCanGoBack = () => window.history.length > INIT_HISTORY_LENGTH;

const getQuerystringParam = (param: string) => {
  const props = getAllQuerystringParams();
  if (props[param] !== undefined) {
    return props[param] as string;
  }
  return null;
};

export const getAllQuerystringParams = () => {
  const queryString = window.location.search;
  if (queryString && queryString.length > 0) {
    return qs.parse(queryString.substring(1), {depth: 0}) as {
      [key: string]: string;
    };
  }
  return {};
};

export const getAttributionIdFromUrl = (state: IWebAppState) =>
  getQuerystringParam(ChancerURLParams.ATTRIBUTION_PARAM);

export const getTempRemotIdFromUrl = (state: IWebAppState) =>
  getQuerystringParam(ChancerURLParams.TEMP_REMOTE_ID_PARAM);

export const getEmailAuthUrl = (state: IWebAppState) => {
  const tempRemoteId: string | null =
    getCurrentCompetitionsLocalAnswers(state).tempRemoteId;
  const queryString =
    window.location.search.length > 1 ? window.location.search.substr(1) : '';
  let props = qs.parse(queryString);
  if (tempRemoteId) {
    props = {...props, [ChancerURLParams.TEMP_REMOTE_ID_PARAM]: tempRemoteId};
  }
  const port =
    document.location.port !== '' ? `:${document.location.port}` : '';

  return `${document.location.protocol}//${document.location.hostname}${port}${
    document.location.pathname
  }?${qs.stringify(props)}`;
};

export const isIOS = (_: IWebAppState) => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isAndroid = (_: IWebAppState) => {
  return /android/i.test(navigator.userAgent.toLowerCase());
};

export const getIsMobileBrowser = createSelector(
  isIOS,
  isAndroid,
  (ios, android) => ios || android,
);

export const getAppPromptRequirement = createSelector(
  getLocalStorage,
  (localStorage) => {
    if (
      window.location.pathname.startsWith('/apps') ||
      window.location.pathname.startsWith('/groups') ||
      window.location.pathname.startsWith('/challenge')
    ) {
      return {now: false};
    }

    const lastPrompt = localStorage[LocalStorageKey.LAST_APP_PROMPT] || null;
    log.debug('LAST_APP_PROMPT', lastPrompt);
    if (lastPrompt instanceof Date) {
      const diff = new Date().valueOf() - lastPrompt.valueOf();
      const days = Math.floor(diff / MILLISECONDS_IN_DAY);
      return {now: days >= 6};
    }

    return {
      now: false,
      // Ask for the last prompt time to be set to 5 days ago from the start of the day
      // so that will be shown tomorrow.
      setLast: new Date(
        new Date().setHours(0, 0, 0, 0) - 5 * MILLISECONDS_IN_DAY,
      ),
    };
  },
);

export const shouldUpgradePrompt = (
  daysRemaining: number,
  lastPrompt: Date,
  nowMs: number = new Date().getTime(),
  days: number[] = [28, 21, 14, 7, 3, 2, 1, 0],
) => {
  const filteredDays = days.filter(
    (d) =>
      d <
      daysRemaining +
        Math.floor((nowMs - lastPrompt.valueOf()) / MILLISECONDS_IN_DAY),
  );
  return (
    filteredDays.includes(daysRemaining) ||
    filteredDays.some((d) => d > daysRemaining)
  );
};

export const getUpgradePromptRequirement = createSelector(
  getUser,
  getLocalStorage,
  (user, localStorage) => {
    const nowMs = new Date().getTime();

    // Skip case
    if (
      !user ||
      !user.temporary ||
      !user.created ||
      // DJ: This was happening locally after anonymous account creation and
      // it breaks the app. I'm not sure why it's happening, but I'm adding
      // this check to prevent the app from breaking.
      (typeof user.created.toMillis === 'function') === false ||
      // Give the user some time before we start prompting them to upgrade
      nowMs - user.created.toMillis() < 15 * MILLISECONDS_IN_MINUTE
    ) {
      return {
        now: false,
        daysRemaining: 0,
      };
    }

    const daysRemaining =
      28 -
      Math.min(
        Math.floor((nowMs - user.created.toMillis()) / MILLISECONDS_IN_DAY),
        28,
      );

    const lastPrompt =
      localStorage[LocalStorageKey.LAST_UPGRADE_PROMPT] || null;
    log.debug('LAST_UPGRADE_PROMPT', lastPrompt);

    // First prompt case
    if (!(lastPrompt instanceof Date)) {
      return {
        now: true,
        daysRemaining,
      };
    }

    // Subsequent prompt case
    if (shouldUpgradePrompt(daysRemaining, lastPrompt, nowMs)) {
      const diff = new Date().valueOf() - lastPrompt.valueOf();
      const days = Math.max(Math.floor(diff / MILLISECONDS_IN_DAY), 0);
      return {
        now: days > 0,
        daysRemaining,
      };
    }

    return {
      now: false,
      daysRemaining,
    };
  },
);

export const getGroupId = createSelector(() => {
  if (
    window.location.pathname.startsWith('/groups') &&
    window.location.pathname.split('/').length > 1 &&
    window.location.pathname.split('/')[2]
  ) {
    return window.location.pathname.split('/')[2];
  }

  return undefined;
});
