/* eslint-disable @typescript-eslint/no-shadow */
import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {Observable, Subscription} from 'rxjs';

import {ClientStatus} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  TFirebaseCompCounts,
  TFirebaseCompEntry,
  TFirebaseCompSummary,
  TFirebaseQuestion,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  CompQuestionType,
  TCompEntryAnswers,
  TCompResolvedQuestion,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';

import {
  getAnswersBreakdownForQuestion,
  getNumberCorrect,
} from '@chancer/common/lib/utils/CompetitionUtils';
import {QuestionItem} from '../../Answers/QuestionItem';
import {ChallengeHeader} from '../../Challenges/ChallengeHeader';
import {LoadingAnimation} from '../../Loading/LoadingAnimation';

interface IProps {
  safeAreaTopPadding?: number;
  hideHeader?: boolean;
  comp: TFirebaseCompSummary;
  compStatus: ClientStatus;
  counts: TFirebaseCompCounts;
  questions: TFirebaseQuestion[];
  isCurrentUser: boolean;
  user: TFirebaseUser;
  userAnswers: TCompEntryAnswers | undefined;
  compareUser: TFirebaseUser;
  compareUserAnswers?: TCompEntryAnswers | undefined;
  getUserAnswersStream: (userId: string) => Observable<TFirebaseCompEntry>;
  onPlayComp: () => void;
  onChangeAnswer?: (questionId: string, answer: number) => void;
}

export const ComparisonContent: React.FC<IProps> = (props) => {
  const {hideHeader = false, user, compareUser, getUserAnswersStream} = props;
  const userAnswers = useLoadAnswers(
    user.id,
    props.userAnswers,
    getUserAnswersStream,
  );

  const compareAnswers = useLoadAnswers(
    compareUser.id,
    props.compareUserAnswers,
    getUserAnswersStream,
  );

  const _createList = (
    questions: TFirebaseQuestion[],
    resolutions: TCompResolvedQuestion[],
    compData: TFirebaseCompCounts,
    compStatus: ClientStatus,
    isCurrentUser: boolean,
    userAnswers: TCompEntryAnswers | undefined,
    userImageUrl: string | null,
    compareAnswers: TCompEntryAnswers | undefined,
    compareImageUrl: string | null | undefined,
    onPlayComp: () => void,
    onChangeAnswer?: (questionId: string, answer: number) => void,
  ) =>
    questions.map((question) => {
      return (
        <QuestionItem
          key={`question${question.id}`}
          index={question.index}
          question={question.question}
          hint={question.hint ?? undefined}
          answers={question.answers}
          answersBreakdown={getAnswersBreakdownForQuestion(
            question.id,
            compData,
          )}
          status={question.status}
          isCompetitionOpen={
            compStatus === ClientStatus.OPEN ||
            compStatus === ClientStatus.PRE_START
          }
          result={getResolutionForQuestion(question.id, resolutions)}
          type={question.type as CompQuestionType}
          isCurrentUser={isCurrentUser}
          userAnswer={userAnswers ? userAnswers[question.id] : undefined}
          userImageUrl={userImageUrl}
          isComparison={true}
          compareAnswer={
            compareAnswers ? compareAnswers[question.id] : undefined
          }
          compareImageUrl={compareImageUrl}
          followedUserEntries={[]}
          starts={question.starts?.toDate() ?? undefined}
          onPlayComp={onPlayComp}
          onChangeAnswer={(answer: number) =>
            onChangeAnswer?.(question.id, answer)
          }
        />
      );
    });

  return (
    <View style={styles.container}>
      {!hideHeader && (
        <ChallengeHeader
          style={styles.challengeHeader}
          challengingUser={user}
          challengingUserScore={
            userAnswers.answers
              ? getNumberCorrect(
                  props.counts.resolvedQuestions,
                  userAnswers.answers,
                )
              : 0
          }
          challengedUser={compareUser}
          challengedUserScore={
            compareAnswers.answers
              ? getNumberCorrect(
                  props.counts.resolvedQuestions,
                  compareAnswers.answers,
                )
              : 0
          }
          createFallbackUserIcons={true}
        />
      )}
      <View style={styles.answersContainer}>
        {!userAnswers.loading &&
          !compareAnswers.loading &&
          _createList(
            props.questions,
            props.counts.resolvedQuestions,
            props.counts,
            props.compStatus,
            props.isCurrentUser,
            userAnswers.answers,
            props.user.media?.image?.url ?? null,
            compareAnswers.answers,
            props.compareUser.media?.image?.url ?? null,
            props.onPlayComp,
            props.onChangeAnswer,
          )}
        <LoadingAnimation
          style={styles.loader}
          isLoading={userAnswers.loading || compareAnswers.loading}
          displayDurationMs={500}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  challengeHeader: {
    width: '100%',
    height: 112,
    justifyContent: 'center',
    marginBottom: 32,
    marginHorizontal: 0,
    marginTop: 0,
  },
  answersContainer: {
    flex: 1,
    width: '100%',
  },
  loader: {
    width: '100%',
    height: '100%',
  },
});

const getResolutionForQuestion = (
  questionId: string,
  resolutions: TCompResolvedQuestion[],
) => {
  const resolution = resolutions.find((r) => r.id === questionId);
  if (resolution !== undefined) {
    return resolution.resolved;
  } else {
    return undefined;
  }
};

export const useLoadAnswers = (
  userId: string,
  existingAnswers: TCompEntryAnswers | undefined,
  getUserAnswersStream: (userId: string) => Observable<TFirebaseCompEntry>,
) => {
  const [answers, setAnswers] = useState<TCompEntryAnswers | undefined>(
    existingAnswers,
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let sub: Subscription | undefined;
    if (answers === undefined) {
      setLoading(true);
      sub = getUserAnswersStream(userId).subscribe({
        next: (u) => {
          setAnswers(u.answers);
          setLoading(false);
        },
      });
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [userId, getUserAnswersStream, answers, setAnswers]);

  useEffect(() => {
    if (existingAnswers !== undefined) {
      setAnswers(existingAnswers);
      setLoading(false);
    }
  }, [existingAnswers, setAnswers]);

  return {
    loading,
    answers,
  };
};
