import React, {Fragment, useMemo} from 'react';
import {Pressable, ScrollView, StyleSheet, Text, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {
  LeaderboardType,
  TUserCompletedLeaderboard,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {Avatar} from '../../Avatar/Avatar';
import {
  COLOR_BACKGROUND,
  COLOR_GREY_4,
  COLOR_LIGHT,
  COLOR_YELLOW,
  FONT_TITLE,
} from '../../Styles/DesignSystem-chancer';
import {CompSummaryDetail} from './SummaryDetail';

interface IProps {
  safeAreaTopPadding?: number;
  compName: string;
  logoUrl: string | undefined;
  userImageUrl: string | undefined;
  color: string;
  leaderboards: TUserCompletedLeaderboard[];
  onClose: () => void;
}

export const CompSummaryContent: React.FC<IProps> = (props) => {
  const {safeAreaTopPadding = 0} = props;

  const styles = useMemo(
    () => getStyles(safeAreaTopPadding),
    [safeAreaTopPadding],
  );

  const inGameLeaderboards = props.leaderboards.filter(
    (l) => l.type === LeaderboardType.INGAME,
  );
  const seasonLeaderboards = props.leaderboards.filter(
    (l) => l.type !== LeaderboardType.INGAME,
  );

  return (
    <Pressable style={styles.pressableContainer} onPress={props.onClose}>
      <LinearGradient
        colors={[props.color, COLOR_BACKGROUND]}
        style={styles.container}>
        <ScrollView
          style={styles.scroll}
          contentContainerStyle={styles.scrollContainer}>
          <Avatar
            imageUrl={props.userImageUrl}
            size={96}
            highlightWidth={
              inGameLeaderboards.some((i) => i.winner === true) ? 3 : 0
            }
            highlightColor={COLOR_YELLOW}
          />
          <Text style={styles.title}>Game Report</Text>
          <View style={styles.divider} />
          <View style={styles.detailsContainer}>
            {inGameLeaderboards.map((l) => (
              <Fragment key={l.id}>
                <CompSummaryDetail
                  value={l.points}
                  title={`You scored ${l.points} points`}
                />
                <CompSummaryDetail
                  value={l.position}
                  title="Leaderboard position"
                  description={`You were ${formatPosition(l.position)} of ${
                    l.players
                  } fans`}
                />
              </Fragment>
            ))}
            {seasonLeaderboards.map((l) => (
              <CompSummaryDetail
                key={l.id}
                value={l.position}
                title="Season leaderboard"
                description={getMultiGameDescription(
                  l.points,
                  l.position,
                  l.players,
                )}
              />
            ))}
          </View>
        </ScrollView>
      </LinearGradient>
    </Pressable>
  );
};

const getMultiGameDescription = (
  points: number,
  position: number,
  total: number,
) => {
  const percentage = Math.ceil((position / total) * 100);
  if (percentage < 50) {
    return `In the top ${formatPercentage(percentage)} of fans`;
  } else {
    return `Your fan rank is ${points}`;
  }
};

const formatPosition = (position: number) => {
  const j = position % 10;
  const k = position % 100;
  if (j === 1 && k !== 11) {
    return position + 'st';
  }
  if (j === 2 && k !== 12) {
    return position + 'nd';
  }
  if (j === 3 && k !== 13) {
    return position + 'rd';
  }
  return position + 'th';
};

const formatPercentage = (percent: number) => {
  if (percent > 10) {
    percent = Math.round(percent / 10) * 10;
  }
  return `${percent}%`;
};

const getStyles = (safeAreaTopPadding: number) =>
  StyleSheet.create({
    pressableContainer: {
      flex: 1,
    },
    container: {
      minHeight: '100%',
    },
    scroll: {
      flex: 1,
      marginTop: 80 + safeAreaTopPadding,
    },
    scrollContainer: {
      flex: 1,
      paddingTop: 56,
      paddingHorizontal: 32,
      paddingBottom: 32,
      alignItems: 'center',
    },
    title: {
      fontFamily: FONT_TITLE,
      textAlign: 'center',
      fontSize: 24,
      color: COLOR_LIGHT,
      marginVertical: 16,
    },
    detailsContainer: {
      flex: 1,
      flexGrow: 1,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginTop: 24,
    },
    divider: {width: '100%', backgroundColor: COLOR_GREY_4, height: 1},
  });
