import React from 'react';

import {AchievementType} from '@chancer/common/lib/interfaces/achievements/Achievements';
import {
  AchievementGameWinnerIcon,
  AchievementOutrightIcon,
  AchievementPerfectIcon,
  AchievementWinnerIcon,
  AchievementZeroIcon,
} from '../Svg/SvgIcons';

interface IAchievementIconProps {
  achievement: AchievementType;
  size?: number;
  color?: string;
}

export const AchievementIcon: React.FC<IAchievementIconProps> = (props) => {
  switch (props.achievement) {
    case AchievementType.WINNER:
      return (
        <AchievementWinnerIcon
          width={props.size}
          height={props.size}
          color={props.color}
        />
      );
    case AchievementType.OUTRIGHT_WINNER:
      return (
        <AchievementOutrightIcon
          width={props.size}
          height={props.size}
          color={props.color}
        />
      );
    case AchievementType.ALL_CORRECT:
      return (
        <AchievementPerfectIcon
          width={props.size}
          height={props.size}
          color={props.color}
        />
      );
    case AchievementType.ZERO_CORRECT:
      return (
        <AchievementZeroIcon
          width={props.size}
          height={props.size}
          color={props.color}
        />
      );
    case AchievementType.PARTICIPANT:
      return (
        <AchievementZeroIcon
          width={props.size}
          height={props.size}
          color={props.color}
        />
      );
    case AchievementType.GAME_WINNER:
      return (
        <AchievementGameWinnerIcon
          width={props.size}
          height={props.size}
          color={props.color}
        />
      );
  }
};
