import {Epic, ofType} from 'redux-observable';
import {map, filter, tap} from 'rxjs/operators';

import {ILocalAnswersForComp} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  IAction,
  ActionType,
  noOpAction,
} from '@chancer/common/lib/core/actions/Actions';
import {IWebAppState} from '../../state/WebAppState';
import {ILocalAnswersState} from '@chancer/common/lib/core/state/AppState';
import {setLocalAnswers} from '@chancer/common/lib/core/actions/answers/AnswersActions';
import {getLocalAnswers} from '@chancer/common/lib/core/selectors/answers/AnswersSelectors';

export const readLocalAnswersEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.STARTUP),
    map(() => readLocalAnswers()),
    map((answers) => setLocalAnswers(answers)),
  );

const readLocalAnswers = () => {
  const l = localStorage.getItem('comps');
  if (l === null) {
    return {};
  } else {
    const localAnswers: {
      [key: string]: ILocalAnswersForComp;
    } = JSON.parse(l);

    return localAnswers as ILocalAnswersState;
  }
};

export const writeLocalAnswersEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(
      ActionType.QUESTION_ANSWERED,
      ActionType.QUESTION_LIKED,
      ActionType.TEMP_ENTRY_RESPONSE,
      ActionType.GET_TEMP_ENTRY_RESPONSE,
      ActionType.CLEAN_UP_LOCAL_ANSWERS,
    ),
    map(() => getLocalAnswers(state$.value)),
    tap((answers) => localStorage.setItem('comps', JSON.stringify(answers))),
    filter(() => false),
    map(() => noOpAction()),
  );
