import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {Avatar} from '../../Avatar/Avatar';
import {PrimaryButton} from '../../Button/PrimaryButton';
import {
  COLOR_BACKGROUND,
  COLOR_DARK,
  COLOR_LIGHT,
  COLOR_YELLOW,
  FONT_COPY_MEDIUM,
  FONT_SUB_TITLE,
} from '../../Styles/DesignSystem-chancer';
import {CompareIcon, FollowAddIcon, FollowRemoveIcon} from '../../Svg/SvgIcons';
import {PlayerProfileAchievements} from './PlayerProfileAchievements';

interface IProps {
  isCurrentUser: boolean;
  isCurrentUserLoggedIn: boolean;
  isCurrentUserEntered: boolean;
  vendor: string;
  user: TFirebaseUser;
  following: boolean;
  onFollow: (user: TFirebaseUser, follow: boolean) => void;
  onCompare: (user: TFirebaseUser) => void;
  onEdit: (user: TFirebaseUser) => void;
}

export const PlayerProfileContent: React.FC<IProps> = (props) => {
  const _onFollow = () => {
    props.onFollow(props.user, props.following);
  };

  const _onCompare = () => {
    props.onCompare(props.user);
  };

  const _onEdit = () => {
    props.onEdit(props.user);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.name}>{props.user.name}</Text>
      <Avatar
        style={styles.avatar}
        imageUrl={props.user.media?.image?.url}
        size={120}
        highlightWidth={4}
        highlightColor={props.following ? COLOR_YELLOW : COLOR_BACKGROUND}
      />
      {props.user.tagline && (
        <>
          <Text style={styles.fieldValue}>{props.user.tagline}</Text>
        </>
      )}
      {props.user.team && (
        <>
          <Text style={styles.fieldLabel}>Favourite Team</Text>
          <Text style={styles.fieldValue}>{props.user.team}</Text>
        </>
      )}
      {props.user.hometown && (
        <>
          <Text style={styles.fieldLabel}>Hometown</Text>
          <Text style={styles.fieldValue}>{props.user.hometown}</Text>
        </>
      )}
      {!props.isCurrentUser && props.isCurrentUserLoggedIn && (
        <PrimaryButton
          style={styles.buttonStyle}
          size="medium"
          icon={
            props.following ? (
              <FollowRemoveIcon color={COLOR_DARK} />
            ) : (
              <FollowAddIcon color={COLOR_DARK} />
            )
          }
          label={props.following ? 'Stop Following Fan' : 'Follow Fan'}
          onPress={_onFollow}
        />
      )}
      {!props.isCurrentUser && props.isCurrentUserEntered && (
        <PrimaryButton
          style={styles.button2Style}
          size="medium"
          icon={<CompareIcon color={COLOR_DARK} />}
          label="Compare Answers"
          onPress={_onCompare}
        />
      )}
      {props.isCurrentUser && (
        <PrimaryButton
          style={styles.buttonStyle}
          size="medium"
          label="Edit Your Profile"
          onPress={_onEdit}
        />
      )}
      {props.vendor && (
        <PlayerProfileAchievements
          vendorName={props.vendor}
          achievements={props.user.vendorAchievements?.[props.vendor] ?? []}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  name: {color: COLOR_LIGHT, fontFamily: FONT_SUB_TITLE, fontSize: 21},
  avatar: {
    marginTop: 32,
    marginBottom: 16,
  },
  fieldLabel: {
    marginTop: 32,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 18,
    letterSpacing: 0,
    textAlign: 'left',
    color: COLOR_YELLOW,
  },
  fieldValue: {
    marginTop: 8,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 18,
    letterSpacing: 0,
    textAlign: 'left',
    color: COLOR_LIGHT,
  },
  buttonStyle: {
    marginTop: 40,
    minWidth: 260,
  },
  button2Style: {
    marginTop: 24,
    minWidth: 260,
  },
});
