import {createSelector} from 'reselect';
import {IAppState} from '../../state/AppState';
import {LocalStorageKey} from '../../state/model/LocalStorage';
import {getCompetitionId} from '../competitions/CompetitionSelectors';

export const getLocalStorage = (state: IAppState) => state.localStorage;

export const getCompChatLastReadMs = createSelector(
  getLocalStorage,
  getCompetitionId,
  (localStorage, compId): number | null =>
    localStorage[LocalStorageKey.CHAT_READ_DATES]?.[compId ?? ''] ?? null,
);

export const getChatReadCounts = createSelector(
  getLocalStorage,
  (localStorage): {[chatId: string]: number} | null =>
    localStorage[LocalStorageKey.CHAT_READ_COUNTS] ?? null,
);

export const getIsMute = createSelector(
  getLocalStorage,
  (localStorage): boolean => localStorage[LocalStorageKey.MUTE] ?? true,
);
