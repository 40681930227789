import {Reducer} from 'redux';
import {undefinedInReducerError} from '../ReducerUtils';
import {IAction, ActionType} from '../../actions/Actions';
import {IAuth} from '../../state/AppState';

export const authReducer: Reducer<IAuth, IAction<any>> = (state, action) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.AUTH_LOGIN_START:
      return {...state, isLogginIn: true, user: action.payload};
    case ActionType.AUTH_LOGIN_COMPLETE:
      return {...state, isLogginIn: false, ...action.payload};
    case ActionType.AUTH_LOGGED_OUT:
      return { ...state, isLogginIn: false, isNewUser: false, user: null };
    case ActionType.AUTH_TEMPORARY_USER_SCORE:
      return { ...state, temporaryUserScore: action.payload };
  }
  return state;
};
