import {IAppState} from '../../state/AppState';
import {getAuthUser} from '../auth/AuthSelectors';
import {getCompetition} from '../competitions/CompetitionSelectors';
import {ChancerURLParams} from '../../state/model/ChancerUrlParams';
import {createSelector} from 'reselect';
import {getConfig} from '../config/ConfigSelectors';

export const getShareLongUrl = (state: IAppState) => {
  const comp = getCompetition(state);
  const authUser = getAuthUser(state);
  const config = getConfig(state);
  const shareId = authUser ? authUser.uid : 'anonymous';
  if (comp !== null) {
    return `${config.appUrl}/${comp.vendor}/${comp.key}/?${ChancerURLParams.ATTRIBUTION_PARAM}=${shareId}&utm_source=webapp&utm_medium=share&utm_campaign=share_screen`;
  } else {
    return `${config.appUrl}/?${ChancerURLParams.ATTRIBUTION_PARAM}=${shareId}&utm_source=webapp&utm_medium=share&utm_campaign=share_screen`;
  }
};

export const getShareTitle = createSelector(getCompetition, (comp) =>
  comp !== null ? comp.name : "I'm playing Chancer!",
);

export const getShareText = createSelector(
  getCompetition,
  (comp) => comp?.shareDetails?.shareText ?? '',
);
