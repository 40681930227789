import {createSelector} from 'reselect';

import {
  getAuthUser,
  getIsLoggedIn,
  getIsLoggingIn,
  getIsUserAnonymous,
} from '@chancer/common/lib/core/selectors/auth/AuthSelectors';

import {IWebAppState} from '../../state/WebAppState';
import {IWebConfig} from '../../config/WebConfig';
import {getConfig} from '../config/WebConfigSelectors';
import {
  getCurrentCompetitionsLocalAnswers,
  getLocalAnswersLoading,
} from '@chancer/common/lib/core/selectors/answers/AnswersSelectors';
import {getIsRequestingComp} from '@chancer/common/lib/core/selectors/competitions/CompetitionSelectors';
import {User, isSignInWithEmailLink} from 'firebase/auth';
import {auth} from '../../firebase/Firebase';

export const getWebAuthUser = createSelector(getAuthUser, (user) =>
  user ? (user as User) : null,
);

export const getAuthProvider = createSelector(getWebAuthUser, (authUser) => {
  if (!authUser) {
    return null;
  } else {
    if (authUser.providerData.length > 0) {
      if (authUser.providerData[0] !== null) {
        return authUser.providerData[0].providerId;
      }
    }
    return null;
  }
});

export const isPendingAuth = (state: IWebAppState) => {
  return (
    isSignInWithEmailLink(auth(), window.location.href) ||
    hasRedirectStatus(getConfig(state))
  );
};

export const getIsAccountScreenRequired = createSelector(
  getIsLoggedIn,
  getIsUserAnonymous,
  isPendingAuth,
  getIsLoggingIn,
  getIsRequestingComp,
  getLocalAnswersLoading,
  getCurrentCompetitionsLocalAnswers,
  (
    isLoggedIn,
    isUserAnonymous,
    pendingAuth,
    isLogginIn,
    isRequestingComp,
    isLocalAnswersLoading,
    currentLocalAnswers,
  ) => {
    // const isPlayingGame = currentLocalAnswers.answers.length > 0;
    return (
      (!isLoggedIn || isUserAnonymous) &&
      pendingAuth &&
      !isLogginIn &&
      !isRequestingComp &&
      !isLocalAnswersLoading
      // !isPlayingGame
    );
  },
);

const hasRedirectStatus = (config: IWebConfig) => {
  const key = `firebase:pendingRedirect:${config.firebaseConfig.apiKey}`;
  for (var i = 0; i < sessionStorage.length; i++) {
    const storageKey = sessionStorage.key(i);
    if (storageKey && storageKey.indexOf(key) > -1) {
      const value = sessionStorage.getItem(storageKey);
      return value !== null && value.search('true') > -1;
    }
  }
  const redirectStatus = sessionStorage.getItem('firebaseui::redirectStatus');
  return redirectStatus !== null;
};
