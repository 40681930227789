import { TFirebaseGroup } from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {ILocalStorage, LocalStorageKey} from '../../state/model/LocalStorage';
import {ActionType, IAction} from '../Actions';

export const initLocalStorage = (
  storage: ILocalStorage,
): IAction<ILocalStorage> => ({
  payload: storage,
  type: ActionType.INIT_LOCAL_STORAGE,
});

export const setLocalStorage = (
  key: LocalStorageKey,
  value: any,
): IAction<ILocalStorage> => ({
  payload: {[key]: value},
  type: ActionType.SET_LOCAL_STORAGE,
});

export const setChatRead = (
  chatId: string,
  date: Date,
): IAction<{chatId: string; date: Date}> => ({
  payload: {chatId, date},
  type: ActionType.SET_LOCAL_CHAT_READ,
});

// No other action perfored here other than storing the data.
export const setMute = (mute: boolean): IAction<ILocalStorage> =>
  setLocalStorage(LocalStorageKey.MUTE, mute);

export const setChatReadCount = (
  chatId: string,
  count: number,
): IAction<{chatId: string; count: number}> => ({
  payload: {chatId, count},
  type: ActionType.SET_LOCAL_CHAT_READ_COUNT,
});

export const expireLocalStorageUserGroups = (groups: TFirebaseGroup[]): IAction<TFirebaseGroup[]> => ({
  payload: groups,
  type: ActionType.EXPIRE_LOCAL_STORAGE_USER_GROUPS,
});
