import React, {useEffect, useMemo, useState} from 'react';

import {TOpenGraph} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {IShareUrlPayload} from '@chancer/common/lib/utils/Branch';
import {getOpenGraphStream} from '@chancer/common/lib/utils/OpenGraphHttpUtils';
import {assertType} from '@chancer/common/lib/utils/TypeUtils';
import {SecondaryButton} from '@chancer/components/lib/Button/SecondaryButton';
import {CompositedViewStyle} from '@chancer/components/lib/Styles/StyleTypes';
import {trackSelectContent} from '../../../analytics/FirebaseAnalytics';
import {IWebConfig} from '../../../config/WebConfig';
import {
  useBranchLaunchData,
  useCreateBranchLink,
} from '../../../utils/BranchUtils';
// import {ImageWithFallback} from '../ImageWithFallback';
import {PrimaryButton} from '@chancer/components/lib/Button/PrimaryButton';
import {PrimaryGroupsButton} from '@chancer/components/lib/Button/PrimaryGroupsButton';
import './AppDownload.scss';
// import {ImageWithFallback} from '../ImageWithFallback';

interface IProps {
  analyticsPrefix:
    | 'apps_page'
    | 'groups_page'
    | 'challenges_page'
    | 'apps_prompt'
    | 'leaderboards_prompt'
    | 'missed_question_prompt'
    | 'new_challenge_prompt';
  pageUrl: string;
  config: IWebConfig;
  title?: string;
  description?: string;
  imageUri?: string;
  closeLabel?: string;
  showCloseButton?: boolean;
  showImage?: boolean;
  useGroupsButton?: boolean;
  onDismiss: () => void;
}

export const AppDownload: React.FC<IProps> = (props) => {
  const {
    analyticsPrefix,
    pageUrl,
    config,
    title,
    description,
    imageUri,
    showCloseButton = true,
    showImage = true,
    useGroupsButton = false,
  } = props;

  const fallbackImage = `${props.config.cdnUrl}/vendors/_default-apps-prompt.jpg`;
  const ogStream = useMemo(() => getOpenGraphStream(config.gameUrl), [config]);

  const [openGraph, setOpenGraph] = useState<TOpenGraph>({
    domain: props.config.appUrl,
    url: props.pageUrl,
    description: '',
    title: '',
    image: undefined,
    siteName: 'Mixnpik',
  });

  const branchLaunchData = useBranchLaunchData();
  const linkDetails = useMemo(() => {
    return assertType<IShareUrlPayload>({
      identifier: pageUrl,
      webUrl: pageUrl,
      forceAppstore: true,
      customMetadata: {
        ...branchLaunchData?.data_parsed,
        ...{prompt: analyticsPrefix},
      },
    });
  }, [branchLaunchData, pageUrl, analyticsPrefix]);
  const link = useCreateBranchLink(linkDetails);

  useEffect(() => {
    const getOgData = async () => {
      ogStream(pageUrl).subscribe({
        next: (og) => {
          if (og.image?.endsWith('_default-open-graph.jpg')) {
            og.image = fallbackImage;
          }
          setOpenGraph((o) => ({
            ...o,
            ...{title: 'Welcome to Mixnpik!', image: fallbackImage},
            ...og,
          }));
        },
      });
    };
    // If we don't have a title or image, fetch the OG data
    if (title === undefined || imageUri === undefined) {
      getOgData();
    }
  }, [ogStream, pageUrl, fallbackImage, title, imageUri]);

  const _goToApp = () => {
    trackSelectContent(`${props.analyticsPrefix}__get_app`);
    window.open(link, '_blank');
  };

  const _onClose = () => {
    trackSelectContent(`${props.analyticsPrefix}__home`);
    props.onDismiss();
  };

  return (
    <div className="app-download__content-container">
      <div className="app-download__title">{title ?? 'Before you play…'}</div>
      {showImage && (
        <div
          className="app-download__screen-shot-container"
          style={{
            backgroundImage: `url('${imageUri ?? openGraph.image}')`,
          }}
        />
      )}
      {description ? (
        <div
          className="app-download__footer-copy"
          style={{textAlign: 'center', ...(showImage ? {} : {flex: 1})}}>
          {description}
        </div>
      ) : (
        <div className="app-download__footer-copy">
          🔸 Get notified about new questions <br />
          🔸 The fastest experience <br />
          🔸 Check the season leaderboards <br />
          🔸 Play anonymously
        </div>
      )}
      {useGroupsButton ? (
        <PrimaryGroupsButton
          style={buttonStyle}
          onPress={_goToApp}
          label="Get the App"
        />
      ) : (
        <PrimaryButton
          style={buttonStyle}
          onPress={_goToApp}
          label="Get the App"
        />
      )}
      {showCloseButton && (
        <SecondaryButton
          style={buttonStyle}
          onPress={_onClose}
          label={props.closeLabel ?? 'Continue to web'}
        />
      )}
    </div>
  );
};

const buttonStyle: CompositedViewStyle = {
  marginTop: 16,
};
