import {
  IGetNativeAppGroupOverlayProps,
  IOverlayViewProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {PrimaryGroupsButton} from '@chancer/components/lib/Button/PrimaryGroupsButton';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {trackSelectContent} from '../../../analytics/FirebaseAnalytics';
import {IWebConfig} from '../../../config/WebConfig';
import {getConfig} from '../../../selectors/config/WebConfigSelectors';
import {
  getIsMobileBrowser,
  isAndroid,
  isIOS,
} from '../../../selectors/host/HostSelectors';

import {fetchGroup} from '@chancer/common/lib/core/actions/firestore/FirestoreActions';
import {
  getGroup,
  getGroupError,
  getGroupRequest,
} from '@chancer/common/lib/core/selectors/groups/GroupsSelectors';
import {TFirebaseGroup} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  BranchCustomKey,
  IShareUrlPayload,
} from '@chancer/common/lib/utils/Branch';
import {getUserByIdStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {LazyAvatar} from '@chancer/components/lib/Avatar/LazyAvatar';
import {SecondaryButton} from '@chancer/components/lib/Button/SecondaryButton';
import {LazyName} from '@chancer/components/lib/Groups/LazyName';
import {CompositedViewStyle} from '@chancer/components/lib/Styles/StyleTypes';
import {
  useBranchLaunchData,
  useCreateBranchLink,
} from '../../../utils/BranchUtils';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';
import './GetNativeGroupOverlay.scss';
import {assertType} from '@chancer/common/lib/utils/TypeUtils';

interface IProps extends IGetNativeAppGroupOverlayProps, IOverlayViewProps {}

interface IStateProps {
  config: IWebConfig;
  isMobileBrowser: boolean;
  isIos: boolean;
  isAndroid: boolean;
  loadedGroup: TFirebaseGroup | null;
  loadGroupRequest: string | null;
  loadGroupError: string | null;
}
interface IDispatchProps {
  loadGroup: (groupId: string) => void;
}

export const GetNativeGroupOverlay: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <GetNativeGroupOverlayView
      {...props}
      config={useSelector(getConfig)}
      isMobileBrowser={useSelector(getIsMobileBrowser)}
      isAndroid={useSelector(isAndroid)}
      isIos={useSelector(isIOS)}
      loadGroup={useCallback(
        (groupId) => dispatch(fetchGroup(groupId)),
        [dispatch],
      )}
      loadedGroup={useSelector(getGroup)}
      loadGroupRequest={useSelector(getGroupRequest)}
      loadGroupError={useSelector(getGroupError)}
    />
  );
};

export const GetNativeGroupOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  const {
    groupId,
    loadedGroup,
    loadGroup,
    loadGroupError,
    loadGroupRequest,
    onClose,
    config,
  } = props;

  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );

  const branchLaunchData = useBranchLaunchData();
  const linkDetails = useMemo(() => {
    let title = loadedGroup?.name ?? 'Mixnpik Group';
    let description = 'Come join me in this Mixnpik Group';
    let imageUrl = loadedGroup?.media?.image?.url ?? '';
    let webUrl = `${config.appUrl}/groups/${groupId}`;
    let userId: string | undefined;
    if (branchLaunchData?.data_parsed) {
      title =
        branchLaunchData.data_parsed[
          '$og_title' as keyof typeof branchLaunchData.data_parsed
        ] ?? title;
      description =
        branchLaunchData.data_parsed[
          '$og_description' as keyof typeof branchLaunchData.data_parsed
        ] ?? description;
      imageUrl =
        branchLaunchData.data_parsed[
          '$og_image_url' as keyof typeof branchLaunchData.data_parsed
        ] ?? imageUrl;
      userId =
        branchLaunchData.data_parsed[
          BranchCustomKey.USER_ID as keyof typeof branchLaunchData.data_parsed
        ];
    }

    return assertType<IShareUrlPayload>({
      identifier: groupId,
      title: title,
      webUrl: webUrl,
      contentImageUrl: imageUrl,
      description: description,
      forceAppstore: true,
      customMetadata: {
        ...(userId ? {[BranchCustomKey.USER_ID]: userId} : {}),
        ...(groupId ? {[BranchCustomKey.GROUP_ID]: groupId} : {}),
      },
    });
  }, [branchLaunchData, groupId, loadedGroup, config]);
  const link = useCreateBranchLink(linkDetails);

  useEffect(() => {
    if (loadGroupRequest !== groupId) {
      loadGroup(groupId);
    }
  }, [groupId, loadGroup, loadGroupRequest]);

  useEffect(() => {
    if (loadGroupError !== null && loadGroupRequest === groupId) {
      onClose();
      return;
    }
  }, [loadGroupError, loadGroupRequest, groupId, onClose]);

  const _getUserByIdCallback = useMemo(
    () => getUserByIdStream(config.gameUrl),
    [config.gameUrl],
  );

  let closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;

  const _goToApp = () => {
    trackSelectContent('app_group_overlay_app');
    window.open(link, '_blank');
  };
  const _close = () => {
    trackSelectContent('app_group_overlay_close');
    props.onClose();
  };

  return (
    <div
      className="get-native-group__container"
      style={{
        animationName: closing === true ? 'hide-modal' : 'show-modal',
        animationDuration: `${props.transitionDuration}ms`,
      }}>
      <div className="get-native-group__header">
        <div className="get-native-group__title">
          You have been invited to a private game
          {loadedGroup ? ` called "${loadedGroup.name}"` : ''}
        </div>
        <div className="get-native-group__description">
          Friends games are only available in the Mixnpik app. You can still
          play our games without the app but you can't join your friend's group.
        </div>
      </div>
      <div className="get-native-group__footer">
        <div className="get-native-group__avatars">
          {loadedGroup &&
            loadedGroup.users.length > 0 &&
            loadedGroup.users.map((u) => (
              <LazyAvatar
                style={{marginRight: 4}}
                key={u}
                userId={u}
                currentUser={null}
                getUserById={_getUserByIdCallback}
              />
            ))}
        </div>
        <div className="get-native-group__names">
          {loadedGroup && loadedGroup.users.length > 0 && (
            <>
              {loadedGroup.users.slice(0, 3).map((u, index) => (
                <LazyName
                  key={u}
                  userId={u}
                  getPostfix={() =>
                    index === loadedGroup.users.slice(0, 3).length - 1
                      ? ' '
                      : index === loadedGroup.users.slice(0, 3).length - 2 &&
                          loadedGroup.users.length <= 3
                        ? ' and '
                        : ', '
                  }
                  getUserById={_getUserByIdCallback}
                />
              ))}
              {loadedGroup.users.length > 3
                ? `and ${loadedGroup.users.length - 3} other${
                    loadedGroup.users.length - 3 > 1 ? 's' : ''
                  }`
                : ''}
              {loadedGroup.users.length > 1 ? ' have ' : ' has '}
              already joined.
            </>
          )}
        </div>
        <PrimaryGroupsButton
          style={buttonStyle}
          onPress={_goToApp}
          label={'Open in the App'}
        />
        <SecondaryButton
          style={buttonStyle}
          onPress={_close}
          label="Not yet, show me more"
        />
      </div>
    </div>
  );
};

const buttonStyle: CompositedViewStyle = {
  marginTop: 24,
  width: '100%',
};
