import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setLocalStorage} from '@chancer/common/lib/core/actions/localStorage/LocalStorageActions';
import {LocalStorageKey} from '@chancer/common/lib/core/state/model/LocalStorage';
import {
  IGetUpgradePromptOverlayProps,
  IOverlayViewProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {openAccountScreen} from '../../../actions/webViewState/WebViewStateActions';
import {IWebConfig} from '../../../config/WebConfig';
import {getConfig} from '../../../selectors/config/WebConfigSelectors';
import {TempUpgrade} from '../../Core/TempPrompt/TempUpgrade';
import {AccountViewState} from '../../Page/Account/AccountViewState';
import {ModalScreen} from '../Containers/ModalScreen';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';

interface IProps extends IGetUpgradePromptOverlayProps, IOverlayViewProps {}

interface IStateProps {
  config: IWebConfig;
}
interface IDispatchProps {
  onUpgrade: () => void;
  onDismiss: () => void;
}

export const GetUpgradePromptOverlay: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const {onClose} = props;

  useEffect(() => {
    dispatch(setLocalStorage(LocalStorageKey.LAST_UPGRADE_PROMPT, new Date()));
  }, [dispatch]);
  const onDismissCallback = useCallback(() => {
    onClose();
  }, [onClose]);
  const onUpgradeCallback = useCallback(() => {
    dispatch(openAccountScreen(AccountViewState.HOME, true));
    onClose();
  }, [dispatch, onClose]);

  return (
    <GetUpgradePromptOverlayView
      {...props}
      config={useSelector(getConfig)}
      onUpgrade={onUpgradeCallback}
      onDismiss={onDismissCallback}
    />
  );
};

export const GetUpgradePromptOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );

  let closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;

  return (
    <ModalScreen
      closing={closing}
      transitionDuration={props.transitionDuration}>
      <TempUpgrade
        analyticsPrefix="upgrade_prompt"
        daysRemaining={props.daysRemaining}
        config={props.config}
        onUpgrade={props.onUpgrade}
        onDismiss={props.onDismiss}
      />
    </ModalScreen>
  );
};
