import {useCallback, useState, useRef, useEffect} from 'react';

export const useTimeout = (callback: () => void, delay?: number) => {
  const savedCallback = useRef<() => void>();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current !== undefined) {
        savedCallback.current();
      }
    }
    if (delay !== undefined) {
      let id = window.setTimeout(tick, delay);
      return () => {
        clearTimeout(id);
      };
    }
  }, [delay]);
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useGetBackgroundFillHeight = (
  windowHeight: number,
): [(node: HTMLDivElement | null) => void, number] => {
  const [height, setHeight] = useState(0);
  const ref = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        setHeight(Math.max(node.offsetHeight - windowHeight, 0));
      }
    },
    [windowHeight],
  );
  return [ref, height];
};

export const useGetElementDimensions = (): [
  (node: HTMLDivElement | null) => void,
  {w: number; h: number},
] => {
  const [dimensions, setDimensions] = useState<{w: number; h: number}>({
    w: 0,
    h: 0,
  });
  const ref = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setDimensions({w: node.offsetWidth, h: node.offsetHeight});
    }
  }, []);
  return [ref, dimensions];
};
