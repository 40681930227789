import React, {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {Avatar} from '../Avatar/Avatar';
import {
  COLOR_LIGHT,
  FONT_COPY,
  FONT_COPY_BOLD,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {platformValue} from '../PlatformUtils';

interface IProps {
  style?: CompositedViewStyle;
  safeAreaTopPadding: number;
  title: string;
  subtitle: string;
  imageUrl?: string;
  appPlatform: AppPlatform;
}

export const ChallengeSetupHeader: React.FC<IProps> = (props) => {
  const {style, safeAreaTopPadding} = props;

  const styles = useMemo(
    () => getStyles(props.appPlatform),
    [props.appPlatform],
  );

  return (
    <View
      style={[
        styles.container,
        style,
        {
          marginTop: safeAreaTopPadding + platformValue(3, {android: 8}),
        },
      ]}>
      <View style={styles.detailsContainer}>
        <Avatar
          size={40}
          imageUrl={props.imageUrl}
          radius={4}
          highlightWidth={0}
          highlightGap={0}
        />
        <View style={styles.detailsCopyContainer}>
          <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">
            {props.title}
          </Text>
          <Text style={styles.subtitle} numberOfLines={1} ellipsizeMode="tail">
            {props.subtitle}
          </Text>
        </View>
      </View>
    </View>
  );
};

const getStyles = (appPlatform: AppPlatform) =>
  StyleSheet.create({
    container: {
      overflow: 'hidden',
    },
    detailsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginLeft: appPlatform === AppPlatform.ANDROID ? 24 : 8,
      paddingRight: appPlatform === AppPlatform.ANDROID ? 24 : 8,
    },
    detailsCopyContainer: {
      flex: 1,
      marginLeft: 8,
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    title: {
      fontFamily: FONT_COPY_BOLD,
      fontSize: 18,
      color: COLOR_LIGHT,
    },
    subtitle: {
      fontFamily: FONT_COPY,
      fontSize: 14,
      paddingBottom: 2,
      color: COLOR_LIGHT,
    },
  });
