import React from 'react';
import {View, StyleSheet} from 'react-native';
import {Avatar} from '../Avatar/Avatar';
import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {VsIcon} from '../Svg/SvgIcons';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  challengingUser: TFirebaseUser | null;
  challengedUser: TFirebaseUser | null;
}

export const ChallengeVsAvatars: React.FC<IProps> = (props) => {
  return (
    <View style={[styles.container, props.style]}>
      <Avatar
        highlightWidth={0}
        size={96}
        imageUrl={props.challengingUser?.media?.image?.url}
      />
      <View style={styles.vsIconContainer}>
        <VsIcon />
      </View>
      <Avatar
        highlightWidth={0}
        size={96}
        imageUrl={props.challengedUser?.media?.image?.url}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 57,
  },
  vsIconContainer: {
    alignItems: 'center',
    marginHorizontal: 8,
  },
});
