import {createSelector} from 'reselect';
import {
  AppPlatform,
  IAppConfig,
} from '@chancer/common/lib/interfaces/client/ClientInterfaces';

import {IWebAppState} from '../../state/WebAppState';
import {IWebConfig} from '../../config/WebConfig';

export const getConfig = (state: IWebAppState) => state.config as IWebConfig;

export const getConfigForApp = createSelector(
  getConfig,
  (config): IAppConfig => {
    return {
      version: config.version,
      appUrl: config.appUrl,
      gameUrl: config.gameUrl,
      cdnUrl: config.cdnUrl,
      facebookAppId: config.facebookConfig.appId,
      topPadding: 0,
      isMobile: false,
      platform: AppPlatform.WEB,
    };
  },
);
