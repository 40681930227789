import {IAction, ActionType} from '../Actions';
import {IAuthUserInfo} from '../../../interfaces/auth/AuthInterfaces';

export interface IAuthUserPayload {
  user: IAuthUserInfo;
  isNewUser: boolean;
}

export const logOut = (): IAction<null> => ({
  payload: null,
  type: ActionType.AUTH_LOG_OUT,
});

export const anonymousLogin = (
  showLoaderOverlay = true,
): IAction<{showLoaderOverlay: boolean}> => ({
  payload: {showLoaderOverlay},
  type: ActionType.AUTH_ANONYMOUS_LOGIN,
});

export const loginStart = (user: IAuthUserInfo): IAction<IAuthUserInfo> => ({
  payload: user,
  type: ActionType.AUTH_LOGIN_START,
});

export const loginComplete = (
  user: IAuthUserInfo,
  isNewUser: boolean,
): IAction<IAuthUserPayload> => ({
  payload: {user, isNewUser},
  type: ActionType.AUTH_LOGIN_COMPLETE,
});

export const loggedOut = (): IAction<null> => ({
  payload: null,
  type: ActionType.AUTH_LOGGED_OUT,
});

export const onTemporaryUserScore = (score: number): IAction<number> => ({
  payload: score,
  type: ActionType.AUTH_TEMPORARY_USER_SCORE,
});
