import {createSelector} from 'reselect';
import {IAppState} from '../../state/AppState';
import {getFilteredCompSummariesAndEntries} from '../competitions/CompetitionSummarySelectors';
import {CompStatus} from '../../../interfaces/firestore/FirestoreInterfaces';
import {
  ICompSummaryAndEntry,
  IVendorDetails,
} from '../../state/model/CompetitionModel';
import {createCompetitionVendor} from '../../../utils/CompetitionUtils';
import {EXCLUDED_GROUPS_VENDORS} from '../../../interfaces/firestore/FirestoreEnums';

export const getVendors = createSelector(
  getFilteredCompSummariesAndEntries,
  (competitions) => {
    return competitions
      .filter((c) => c.summary.status !== CompStatus.DRAFT)
      .reduce((p: IVendorDetails[], v: ICompSummaryAndEntry, index: number) => {
        if (p.find((s) => s.vendor === v.summary.vendor) === undefined) {
          p.push(createCompetitionVendor(v.summary));
        }
        return p;
      }, []);
  },
);

export const getVendorsForGroups = createSelector(getVendors, (vendors) =>
  vendors.filter((v) => !EXCLUDED_GROUPS_VENDORS.includes(v.vendor)),
);

export const getVendorsForGroupsByIdMap = createSelector(
  getVendorsForGroups,
  (vendors) => {
    return vendors.reduce((p, v) => {
      p.set(v.vendor, v);
      return p;
    }, new Map<string, IVendorDetails>());
  },
);

export const getCompsForVendor = (
  comps: ICompSummaryAndEntry[],
  vendor: string | undefined,
): ICompSummaryAndEntry[] => {
  if (vendor === undefined) {
    return comps;
  }
  return comps.filter((comp) => comp.summary.vendor === vendor);
};

export const getVendorCompCounts = (state: IAppState) =>
  state.serverApis.firestore.vendorCompCounts.response;

export const getVendorQuestions = (state: IAppState) =>
  state.serverApis.firestore.vendorQuestions.response;

export const getVendorLiveLeaderboards = (state: IAppState) =>
  state.serverApis.firestore.vendorLiveLeaderboards.response;
