import {IAction, ActionType} from '../Actions';

export const startup = (): IAction<null> => ({
  type: ActionType.STARTUP,
  payload: null,
});

export const startupModuleLoaded = (name: string): IAction<string> => ({
  type: ActionType.STARTUP_MODULE_LOADED,
  payload: name,
});

export const startupModuleFailed = (name: string): IAction<string> => ({
  type: ActionType.STARTUP_MODULE_FAILED,
  payload: name,
});
