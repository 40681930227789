import {createSelector} from 'reselect';

import {EnterCompStatus} from '../../../interfaces/client/ClientInterfaces';
import {TFirebaseUserFollowList} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {GroupType} from '../../../interfaces/firestore/FirestoreInterfaces';
import {getIsImage} from '../../../utils/MediaUtils';
import {IAppState} from '../../state/AppState';
import {getAuthUser} from '../auth/AuthSelectors';
import {
  getCompetition,
  getCompetitionId,
  getEnterCurrentCompComplete,
  getEnterCurrentCompError,
  getEnterCurrentCompProcessing,
} from '../competitions/CompetitionSelectors';

export const getUserId = createSelector(getAuthUser, (authUser) => {
  if (authUser) {
    return authUser.uid;
  }
  return null;
});

export const getUserRequest = (state: IAppState) =>
  state.serverApis.firestore.user.request;

export const getUser = (state: IAppState) =>
  state.serverApis.firestore.user.response;

export const getUserError = (state: IAppState) =>
  state.serverApis.firestore.user.error;

export const getUserName = createSelector(
  getUser,
  (user) => user?.name ?? null,
);

export const getUserTagline = createSelector(
  getUser,
  (user) => user?.tagline ?? null,
);

export const getUserTeam = createSelector(
  getUser,
  (user) => user?.team ?? null,
);

export const getUserHometown = createSelector(
  getUser,
  (user) => user?.hometown ?? null,
);

export const getUsersMessages = (state: IAppState) =>
  state.serverApis.firestore.userMessages.response ?? [];

export const getUsersMessagesInboxEmpty = (state: IAppState) =>
  state.serverApis.firestore.userMessages.response
    ? state.serverApis.firestore.userMessages.response.length === 0
    : false;

export const getUsersUnopenedMessagesCount = createSelector(
  getUsersMessages,
  (messages) => messages.filter((m) => m.open === false).length,
);

export const getUserPrivateProfile = (state: IAppState) =>
  state.serverApis.firestore.userPrivate.response;

export const getUserIsVipForCurrentComp = createSelector(
  getUser,
  getCompetition,
  (user, comp) => (comp && user?.vipVendors?.includes(comp.vendor)) ?? false,
);

export const getUserIsFollowingResponse = (state: IAppState) =>
  state.serverApis.firestore.userFollowing.response;

export const getUserIsFollowing = (
  state: IAppState,
): TFirebaseUserFollowList => {
  if (state.serverApis.firestore.userFollowing.response) {
    return state.serverApis.firestore.userFollowing.response;
  }
  return {
    id: 'following',
    path: `users/${getUserId(state)}/user_profile/following`,
    userIds: [],
  };
};

export const getUserIsFollowingAsMap = createSelector(
  getUserIsFollowing,
  (following) =>
    following.userIds.reduce((a: {[key: string]: boolean}, b) => {
      a[b] = true;
      return a;
    }, {}),
);

export const getNeedsToRegisterForIsFollowing = (state: IAppState) => {
  if (state.serverApis.firestore.userFollowing.error !== null) {
    return true;
  }
  return false;
};

export const getUserImageUrl = createSelector(
  getAuthUser,
  getUser,
  getUserError,
  (authUser, user, userError) => {
    if (getIsImage(user?.media)) {
      return user?.media.image.url as string;
    }
    // This should only occur for a new user
    if (authUser && userError) {
      return authUser.photoURL;
    }
    return null;
  },
);

const getUserEntriesResponse = (state: IAppState) =>
  state.serverApis.firestore.userEntries.response;

export const getUserEntries = createSelector(
  getUserEntriesResponse,
  (entries) => new Map((entries ?? []).map((entry) => [entry.compId, entry])),
);

export const getUserGroupsResponse = (state: IAppState) =>
  state.serverApis.firestore.userGroups.response;

export const getUserGroups = createSelector(getUserGroupsResponse, (groups) => {
  return groups?.filter((g) => g.type === GroupType.DEFAULT) ?? null;
});

export const getUsersCurrentCompetitionEntry = createSelector(
  getCompetitionId,
  getUserEntries,
  (compId, usersEntries) => {
    if (compId) {
      return usersEntries.get(compId) || null;
    }
    return null;
  },
);

export const getUsersCurrentCompEntryStatus = createSelector(
  getEnterCurrentCompProcessing,
  getEnterCurrentCompComplete,
  getEnterCurrentCompError,
  getUsersCurrentCompetitionEntry,
  (processing, complete, error, entry) => {
    if (entry !== null) {
      return EnterCompStatus.COMPLETE;
    } else if (processing) {
      return EnterCompStatus.PROCESSING;
    } else if (error) {
      return EnterCompStatus.ERROR;
    } else {
      return EnterCompStatus.NOT_ENTERED;
    }
  },
);
