export const jsonDateReviver = (key: string, value: any) => {
  if (typeof value === 'string') {
    const a =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/.exec(
        value,
      );
    if (a) {
      return new Date(value);
    }
  }
  return value;
};
