import React, {useRef} from 'react';
import log from '@chancer/common/lib/utils/Log';
import {SecondaryTransparentButton} from '@chancer/components/lib/Button/SecondaryTransparentButton';
import {CompositedViewStyle} from '@chancer/components/lib/Styles/StyleTypes';

import {createResizedImageStream} from '../../../epics/mediaUploads/MediaUploadEpics';
import './UploadImageSelector.scss';

interface IProps {
  open: boolean;
  width: number;
  height: number;
  isMobileDevice: boolean;
  isCircleCrop?: boolean;
  deleteEnabled?: boolean;
  onSelectImage: (resized: {asString: string; asBlob: Blob}) => void;
  onDeleteImage?: () => void;
  onClose: () => void;
}

export const UploadImageSelector: React.FC<IProps> = React.memo((props) => {
  const fileRef = useRef<HTMLInputElement | null>(null);

  const _onSelectPic = async () => {
    if (fileRef.current) {
      fileRef.current.addEventListener('change', _handleSelectImage, false);
      fileRef.current.click();
    }
  };

  const _handleSelectImage = async (e: Event) => {
    if (fileRef.current) {
      fileRef.current.removeEventListener('change', _handleSelectImage, false);
      if (fileRef.current.files?.length === 1) {
        try {
          const resizedFile = await createResizedImageStream(
            props.width,
            props.height,
            fileRef.current.files[0],
          );
          props.onSelectImage(resizedFile);
        } catch (err) {
          log.warning('Failed to resize image', err);
          fileRef.current.addEventListener('change', _handleSelectImage, false);
        }
      }
    }
    props.onClose();
  };

  const _onRemovePic = () => {
    if (props.onDeleteImage) {
      props.onDeleteImage();
    }
    props.onClose();
  };

  return (
    <div className="upload-image-selector__container">
      <input
        ref={fileRef}
        type="file"
        id="fileElem"
        accept="image/*"
        capture="user"
        style={{display: 'none'}}
      />
      <SecondaryTransparentButton
        style={styles.chooseButton}
        onPress={_onSelectPic}
        label={props.deleteEnabled ? 'Take a Photo' : 'Choose a Photo'}
      />
      <SecondaryTransparentButton
        style={styles.removeButton}
        onPress={_onRemovePic}
        label="Remove Photo"
      />
    </div>
  );
});

const styles: {[key: string]: CompositedViewStyle} = {
  chooseButton: {
    marginTop: 24,
  },
  removeButton: {
    marginTop: 24,
    marginBottom: 24,
  },
};
