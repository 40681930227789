import {createSelector} from 'reselect';

import {IAppState} from '../../state/AppState';
import {IAuthUserInfo} from '../../../interfaces/auth/AuthInterfaces';

export const getIsLoggingIn = (state: IAppState) => state.auth.isLogginIn;

export const getAuthUser = (state: IAppState) => state.auth.user;

export const getAuthUserIsNew = (state: IAppState) => state.auth.isNewUser;

export const getIsLoggedIn = createSelector(
  getAuthUser,
  getIsLoggingIn,
  (user, isLoggingIn) => user !== null && !isLoggingIn,
);

export const getAuthUserForApp = createSelector(
  getAuthUser,
  getIsLoggedIn,
  (user, isLoggedin): IAuthUserInfo | null => {
    if (isLoggedin && user !== null) {
      // The default authUser has all the firebase auth properties.
      // We don't want to send all these to the app
      return {
        displayName: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        uid: user.uid,
        providerId: user.providerId,
        isAnonymous: user.isAnonymous,
      };
    } else {
      return null;
    }
  },
);

export const getAuthName = createSelector(
  getAuthUser,
  (authUser) => authUser?.displayName ?? null,
);

export const getAuthEmail = createSelector(
  getAuthUser,
  (authUser) => authUser?.email ?? null,
);

export const getAuthPhone = createSelector(
  getAuthUser,
  (authUser) => authUser?.phoneNumber ?? null,
);

export const getIsUserAnonymous = createSelector(
  getAuthUser,
  (authUser) => authUser?.isAnonymous ?? true,
);

export const getTemporaryUserScore = (state: IAppState) =>
  state.auth.temporaryUserScore;
