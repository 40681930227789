import {getFilteredCompSummariesAndEntriesMap} from '@chancer/common/lib/core/selectors/competitions/CompetitionSummarySelectors';
import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  IOverlayViewProps,
  IStartupOverlayProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {getUserByIdStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {AchievementIcon} from '@chancer/components/lib/Achievements/AchievementIcon';
import {StartupOverlayContent} from '@chancer/components/lib/Overlay/Startup/StartupOverlayContent';
import React from 'react';
import {useSelector} from 'react-redux';
import {getConfig, IWebConfig} from '../../../config/WebConfig';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';
import './StartupOverlay.scss';

interface IProps extends IStartupOverlayProps, IOverlayViewProps {}

interface IStateProps {
  compSummaries: {[compId: string]: ICompSummaryAndEntry};
  config: IWebConfig;
}

export const StartupOverlay: React.FC<IProps> = (props) => (
  <StartupOverlayView
    {...props}
    compSummaries={useSelector(getFilteredCompSummariesAndEntriesMap)}
    config={useSelector(getConfig)}
  />
);

export const StartupOverlayView: React.FC<IProps & IStateProps> = (props) => {
  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );

  let closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;

  return (
    <div
      className="startup-overlay__container"
      style={{
        animationName: closing === true ? 'hide-modal' : 'show-modal',
        animationDuration: `${props.transitionDuration}ms`,
      }}>
      <StartupOverlayContent
        {...props}
        safeAreaTopPadding={0}
        AchievementIconComponent={AchievementIcon}
        appPlatform={AppPlatform.WEB}
        getUserById={getUserByIdStream(props.config.gameUrl)}
      />
    </div>
  );
};
