import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import React, {useEffect, useState} from 'react';
import {Observable, Subscription} from 'rxjs';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {Avatar} from './Avatar';

interface IProps {
  style?: CompositedViewStyle;
  size?: number;
  radius?: number;
  userId: string;
  currentUser: TFirebaseUser | null;
  getUserById: (userId: string) => Observable<TFirebaseUser>;
}

export const LazyAvatar: React.FC<IProps> = (props) => {
  const size = props.size ?? 48;
  const visible = true; // TODO

  const {userId, getUserById} = props;
  const [user, setUser] = useState<TFirebaseUser | null>(props.currentUser);
  // Deal with other users
  useEffect(() => {
    let sub: Subscription | undefined;
    if (visible && user === null) {
      sub = getUserById(userId).subscribe({
        next: (u) => {
          setUser(u);
        },
      });
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [visible, user, userId, getUserById]);

  return (
    <Avatar
      style={props.style}
      size={size}
      highlightWidth={0}
      imageUrl={user?.media?.image?.url}
      radius={props.radius}
    />
  );
};
