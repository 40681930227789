import {IAction, ActionType} from '../Actions';
import {
  IMediaUploadError,
  IMediaUploadRequest,
  IMediaUploadResponse,
} from '../../state/AppState';

export interface IFetchMediaUpload {
  settings: IUploadMediaSettings;
  data?: any;
}

export interface IUploadMediaSettings {
  uploadId: string;
  remoteFolderPath: string;
  customMetadata?: {[key: string]: string};
  onSuccessAction?: (remoteUrl: string) => IAction<any>;
}

export const fetchUploadImage = (
  settings: IUploadMediaSettings,
  data: string | {asString: string; asBlob: Blob}, // string path to local file on native. File details on web
): IAction<IFetchMediaUpload> => ({
  type: ActionType.FETCH_UPLOAD_IMAGE,
  payload: {settings, data},
});

export const uploadImageRequest = (
  payload: IMediaUploadRequest,
): IAction<IMediaUploadRequest> => ({
  type: ActionType.UPLOAD_IMAGE_REQUEST,
  payload: payload,
});

export const uploadImageProgress = (
  uploadId: string,
  progress: number,
): IAction<{uploadId: string; progress: number}> => ({
  type: ActionType.UPLOAD_IMAGE_PROGRESS,
  payload: {uploadId, progress},
});

export const uploadImageResponse = (
  payload: IMediaUploadResponse,
): IAction<IMediaUploadResponse> => ({
  type: ActionType.UPLOAD_IMAGE_RESPONSE,
  payload: payload,
});

export const uploadImageError = (
  err: IMediaUploadError,
): IAction<IMediaUploadError> => ({
  type: ActionType.UPLOAD_IMAGE_ERROR,
  payload: err,
});

export const fetchUploadProfileImage = (): IAction<null> => ({
  type: ActionType.FETCH_UPLOAD_PROFILE_IMAGE,
  payload: null,
});
