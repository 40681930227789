export interface IShareUrlPayload {
  identifier: string;
  webUrl: string;
  title?: string;
  description?: string;
  contentImageUrl?: string;
  customMetadata?: {[key: string]: string};
  forceAppstore?: boolean;
  ignoreServerOpenGraph?: boolean;
}

export enum BranchCustomKey {
  USER_ID = 'userId',
  GROUP_ID = 'groupId',
  COMP_ID = 'compId',
}
