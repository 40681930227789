import {createSelector} from 'reselect';

import {IAppState} from '../../state/AppState';
import {
  getCompetitionCountsResponse,
  getCompetitionId,
} from '../competitions/CompetitionSelectors';
import {getIsLoggedIn} from '../auth/AuthSelectors';
import {ILocalAnswersForComp} from '../../../interfaces/client/ClientInterfaces';

export const getLocalAnswersLoading = (state: IAppState) =>
  state.localAnswers.loaded !== true;

export const getLocalAnswers = (state: IAppState) => state.localAnswers.values;

export const getCurrentCompetitionsLocalAnswers = createSelector(
  getLocalAnswers,
  (state: IAppState) => getCompetitionId(state),
  (answers, currentCompId): ILocalAnswersForComp => {
    if (currentCompId && answers[currentCompId] !== undefined) {
      return answers[currentCompId];
    } else {
      return {answers: {}, likes: {}, tempRemoteId: null};
    }
  },
);

const isRequestingTempId = (state: IAppState) =>
  state.serverApis.firestore.tempEntry.isUpdating;

export const shouldWriteTempCompDoc = createSelector(
  getIsLoggedIn,
  getCompetitionCountsResponse,
  getCurrentCompetitionsLocalAnswers,
  isRequestingTempId,
  (isLoggedIn, compCounts, localAnswers, isRequestingTempId) => {
    if (isLoggedIn) {
      return false;
    }
    if (!compCounts) {
      return false;
    }
    if (isRequestingTempId) {
      return false;
    }
    return (
      localAnswers.answers.length === compCounts.numQuestions &&
      localAnswers.tempRemoteId === null
    );
  },
);
