import {createSelector} from 'reselect';

import {CompStatus} from '../../../interfaces/firestore/FirestoreInterfaces';
import {MILLISECONDS_IN_WEEK} from '../../../utils/DateUtils';

import {getCompetitionClientStatus} from '../../../utils/CompetitionUtils';
import {IAppState} from '../../state/AppState';
import {ClientStatus} from '../../state/model/CompetitionModel';

export const OLDEST_COMPETITION_DATE_MOBILE = new Date(
  Date.now() - 5 * MILLISECONDS_IN_WEEK,
);

export const OLDEST_COMPETITION_DATE_WEB = new Date(
  Date.now() - 5 * MILLISECONDS_IN_WEEK,
);

export const getCompetitionRequest = (state: IAppState) =>
  state.serverApis.firestore.competition.request;

export const getCompetition = (state: IAppState) =>
  state.serverApis.firestore.competition.response;

export const getIsRequestingComp = (state: IAppState) => {
  return state.serverApis.firestore.competition.isUpdating;
};

export const getCompetitionId = (state: IAppState) =>
  state.serverApis.firestore.competition.response?.id ?? null;

export const getCompetitionError = (state: IAppState) =>
  state.serverApis.firestore.competition.error;

export const getQuestions = (state: IAppState) =>
  state.serverApis.firestore.questions.response;

export const getCompetitionStatusResponse = (state: IAppState) =>
  state.serverApis.firestore.competitionStatus.response;

export const getCompetitionStatus = (state: IAppState) =>
  state.serverApis.firestore.competitionStatus.response?.status ?? null;

export const getIsDraftMode = createSelector(
  getCompetitionStatus,
  (status) => status === CompStatus.DRAFT,
);

export const getCompetitionChatResponse = (state: IAppState) =>
  state.serverApis.firestore.competitionChat.response;

export const getCompetitionCountsResponse = (state: IAppState) =>
  state.serverApis.firestore.competitionCounts.response;

export const getCompetitionVipsResponse = (state: IAppState) =>
  state.serverApis.firestore.competitionVips.response;

export const getCompetitionCounts = createSelector(
  getCompetitionCountsResponse,
  getQuestions,
  (counts, questions) => {
    if (counts) {
      return counts;
    } else {
      if (questions !== null) {
        return {
          id: '',
          path: '',
          numQuestions: questions?.length || 0,
          entriesCount: 0,
          answersBreakdown: questions.map((question) => ({})),
          resolvedQuestions: [],
        };
      }
    }
    return {
      id: '',
      path: '',
      entriesCount: 0,
      answersBreakdown: [],
      resolvedQuestions: [],
    };
  },
);

export const getEnterCurrentCompError = (state: IAppState) => {
  const compId = getCompetitionId(state);
  return (
    state.serverApis.firestore.enterCompetition.error !== null &&
    compId !== null &&
    state.serverApis.firestore.enterCompetition.request === compId
  );
};

export const getEnterCurrentCompComplete = (state: IAppState) => {
  const compId = getCompetitionId(state);
  return (
    state.serverApis.firestore.enterCompetition.isUpdating === false &&
    state.serverApis.firestore.enterCompetition.response !== null &&
    compId !== null &&
    state.serverApis.firestore.enterCompetition.request === compId
  );
};

export const getEnterCompLastUpdated = (state: IAppState) => {
  return state.serverApis.firestore.enterCompetition.lastUpdated;
};

export const getEnterCurrentCompProcessing = (state: IAppState) => {
  const compId = getCompetitionId(state);
  return (
    state.serverApis.firestore.enterCompetition.isUpdating &&
    compId !== null &&
    state.serverApis.firestore.enterCompetition.request === compId
  );
};

export const getCurrentCompetitionClientStatus = createSelector(
  getCompetition,
  getCompetitionStatus,
  (comp, status) => {
    if (comp) {
      return getCompetitionClientStatus(comp.starts, status);
    }
    return ClientStatus.SUSPENDED;
  },
);
