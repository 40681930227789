import React from 'react';
import {useDispatch} from 'react-redux';

import {
  IOverlayViewProps,
  IUploadImageOverlayProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {IAction} from '@chancer/common/lib/core/actions/Actions';

import {useCloseOverlayAfterTransition} from '../OverlayContainer';
import {MessageOverlay} from '../Containers/MessageOverlay';
import {UploadImageSelector} from './UploadImageSelector';
import {fetchUploadImage} from '@chancer/common/lib/core/actions/mediaUploads/MediaUploadsActions';
import {getIsMobileBrowser} from '../../../selectors/host/HostSelectors';
import {useSelector} from 'react-redux';

interface IProps extends IUploadImageOverlayProps, IOverlayViewProps {}

interface IStateProps {
  isMobile: boolean;
}

interface IDispatchProps {
  onUploadImage: (details: {asString: string; asBlob: Blob}) => void;
  onDeleteImage: (action: IAction<any>) => void;
}

export const UploadImageOverlay: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <UploadProfileImageOverlayView
      {...props}
      isMobile={useSelector(getIsMobileBrowser)}
      onUploadImage={(details: {asString: string; asBlob: Blob}) =>
        dispatch(
          fetchUploadImage(
            {
              uploadId: props.uploadId,
              remoteFolderPath: props.remoteFolderPath,
              onSuccessAction: props.onSuccessAction,
            },
            details,
          ),
        )
      }
      onDeleteImage={(action: IAction<any>) => dispatch(action)}
    />
  );
};

export const UploadProfileImageOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = React.memo((props) => {
  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );

  const _onSelectImage = async (details: {asString: string; asBlob: Blob}) => {
    props.onUploadImage(details);
    props.onClose();
  };

  const _onDeleteImage = () => {
    if (props.onDeleteAction) {
      props.onDeleteImage(props.onDeleteAction());
    }
    props.onClose();
  };

  return (
    <MessageOverlay
      closing={props.displayState === OverlayDisplayState.TRANSITION_OUT}
      transitionDuration={props.transitionDuration}>
      <UploadImageSelector
        open={true}
        width={props.targetDimensions.width}
        height={props.targetDimensions.height}
        isCircleCrop={!(props.targetDimensions.isCircleCrop === false)}
        isMobileDevice={props.isMobile}
        deleteEnabled={true}
        onClose={props.onClose}
        onSelectImage={_onSelectImage}
        onDeleteImage={_onDeleteImage}
      />
    </MessageOverlay>
  );
});
