import {
  IEnterCompOverlayProps,
  IOverlayViewProps,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {fetchEnterCompetition} from '@chancer/common/lib/core/actions/firestore/FirestoreActions';
import {IRemoteEnterCompOverlayConfig} from '@chancer/common/lib/core/config/RemoteConfig';
import {
  getCompetition,
  getQuestions,
} from '@chancer/common/lib/core/selectors/competitions/CompetitionSelectors';
import {getUpcomingCompetitionsExcludingEntered} from '@chancer/common/lib/core/selectors/competitions/CompetitionSummarySelectors';
import {getRemoteEnterCompOverlayConfig} from '@chancer/common/lib/core/selectors/remoteConfig/RemoteConfigSelectors';
import {
  getUser,
  getUsersCurrentCompEntryStatus,
} from '@chancer/common/lib/core/selectors/user/UserSelectors';
import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {AchievementType} from '@chancer/common/lib/interfaces/achievements/Achievements';
import {
  AppPlatform,
  EnterCompContentType,
  EnterCompStatus,
} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseComp,
  TFirebaseQuestion,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {useSetOnce} from '@chancer/components/lib/Hooks/CommonHooks';
import {EnterCompContent} from '@chancer/components/lib/Overlay/EnterComp/EnterCompContent';
import {
  trackEnterCompOverlay,
  trackScreenView,
  trackSelectContent,
} from '../../../analytics/FirebaseAnalytics';
import {IWebConfig, getConfig} from '../../../config/WebConfig';
import {MediaView} from '../../Core/Media/MediaView';
import {BottomSheet} from '../Containers/BottomSheet';
import './EnterCompOverlay.scss';

interface IProps extends IEnterCompOverlayProps, IOverlayViewProps {}

interface IStateProps {
  user: TFirebaseUser | null;
  comp: TFirebaseComp | null;
  remoteEnterCompOverlayConfig: IRemoteEnterCompOverlayConfig;
  questions: TFirebaseQuestion[] | null;
  compEntryStatus: EnterCompStatus;
  upcomingComps: ICompSummaryAndEntry[];
  config: IWebConfig;
}

interface IDispatchProps {
  onEnterCompetition: () => void;
}

export const EnterCompOverlay: React.FC<IProps> = (props) => {
  const {entry} = props;
  const dispatch = useDispatch();
  const fetchEnterCompetitionCallback = useCallback(
    () => dispatch(fetchEnterCompetition(entry)),
    [dispatch, entry],
  );

  return (
    <EnterCompOverlayView
      {...props}
      user={useSelector(getUser)}
      comp={useSelector(getCompetition)}
      remoteEnterCompOverlayConfig={useSelector(
        getRemoteEnterCompOverlayConfig,
      )}
      questions={useSelector(getQuestions)}
      compEntryStatus={useSelector(getUsersCurrentCompEntryStatus)}
      upcomingComps={useSelector(getUpcomingCompetitionsExcludingEntered)}
      config={useSelector(getConfig)}
      onEnterCompetition={fetchEnterCompetitionCallback}
    />
  );
};

export const EnterCompOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  const {entry, user, comp, onClose} = props;
  const navigate = useNavigate();

  const _onCompetitionSelected = useCallback(
    (comp: ICompSummaryAndEntry) => {
      trackSelectContent('enter_comp_overlay__competition_selected');
      navigate(
        {
          pathname: `/${comp.summary.vendor}/${comp.summary.key}`,
          search: window.location.search,
        },
        {
          state: {
            canGoBack: true,
          },
        },
      );
      onClose();
    },
    [navigate, onClose],
  );

  const _getMediaComponent = () =>
    comp?.signUpCompleteView?.media ? (
      <MediaView
        className="enter-game__media-container"
        media={comp.signUpCompleteView?.media}
      />
    ) : undefined;

  let description = comp?.signUpCompleteView?.webDescription;
  if (description === undefined || description === '') {
    description = comp?.signUpCompleteView?.description;
  }
  const compsPlayed = user?.achievements
    ? user.achievements[AchievementType.PARTICIPANT] ?? 0
    : 0;
  const vendorCompsPlayed =
    comp?.vendor &&
    user?.vendorAchievements &&
    user.vendorAchievements[comp.vendor]
      ? user.vendorAchievements[comp.vendor][AchievementType.PARTICIPANT] ?? 0
      : 0;
  const isEntryWithMissedQuestions =
    entry.isEntry &&
    props.questions !== null &&
    props.questions.length !== Object.keys(entry.answers.answers).length;

  const enterCompContentType = useSetOnce(
    useMemo(() => {
      if (!entry.isEntry) {
        return EnterCompContentType.ANSWERED_OUTSTANDING_QUESTIONS;
      }
      // if (compsPlayed < 4) {
      //   return EnterCompContentType.FIRST_N_GAMES;
      // }
      if (compsPlayed < 1 || vendorCompsPlayed < 1) {
        return EnterCompContentType.FIRST_N_VENDOR_GAMES;
      }
      return EnterCompContentType.DEFAULT;
    }, [compsPlayed, entry.isEntry, vendorCompsPlayed]),
  );

  useEffect(() => {
    trackScreenView(`EnterCompOverlay_${enterCompContentType}`);
  }, [enterCompContentType]);

  return (
    <BottomSheet
      displayState={props.displayState}
      backgroundColor={comp?.primaryColor}
      transitionDuration={props.transitionDuration}
      onClose={props.onClose}
      onTransitionOutComplete={props.onTransitionOutComplete}>
      <EnterCompContent
        type={enterCompContentType}
        remoteConfig={props.remoteEnterCompOverlayConfig}
        appPlatform={AppPlatform.WEB}
        title={comp?.signUpCompleteView?.media?.caption}
        backgroundColor={comp?.primaryColor}
        description={description}
        questions={props.questions}
        compEntryStatus={props.compEntryStatus}
        upcomingComps={props.upcomingComps}
        challenge={null}
        mediaComponent={_getMediaComponent()}
        currentUser={user}
        gameUrl={props.config.gameUrl}
        cdnUrl={props.config.cdnUrl}
        vendorImageUrl={comp?.promo.image?.url}
        compsPlayed={compsPlayed}
        isEntryWithMissedQuestions={isEntryWithMissedQuestions}
        vendorName={comp?.promo.caption}
        onScreenAnalytics={trackEnterCompOverlay}
        onRetryEntry={props.onEnterCompetition}
        onCompetitionSelected={_onCompetitionSelected}
        onChallengeSelected={null}
        onNewChallengeSelected={null}
      />
    </BottomSheet>
  );
};
