import React, {useCallback} from 'react';
import {Image, ImageProps} from 'react-native';

interface IProps extends Omit<ImageProps, 'source'> {
  uris: string[];
}

export const ImageWithFallback: React.FC<IProps> = (props) => {
  const {uris, ...rest} = props;
  const [index, setIndex] = React.useState(0);

  const onLoadError = useCallback(() => {
    if (index < uris.length - 1) {
      setIndex(index + 1);
    }
  }, [index, uris]);

  const uri = uris[index];

  return (
    <Image
      {...rest}
      source={{
        uri,
      }}
      onError={onLoadError}
    />
  );
};
