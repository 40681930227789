import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {IEnterCompPayload} from '@chancer/common/lib/app/AppMessage';
import {IAction} from '@chancer/common/lib/core/actions/Actions';
import {fetchEnterCompetition} from '@chancer/common/lib/core/actions/firestore/FirestoreActions';
import {
  getResultedCompetitions,
  getUpcomingCompetitions,
} from '@chancer/common/lib/core/selectors/competitions/CompetitionSummarySelectors';
import {
  getUser,
  getUserIsFollowingAsMap,
} from '@chancer/common/lib/core/selectors/user/UserSelectors';
import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  ICompareOverlayProps,
  IOverlayViewProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {getCompetitionClientStatus} from '@chancer/common/lib/utils/CompetitionUtils';
import {getUserEntryByIdStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {ComparisonContent} from '@chancer/components/lib/Overlay/Comparison/ComparisonContent';
import {CloseIcon} from '@chancer/components/lib/Svg/SvgIcons';
import {useNavigate} from 'react-router-dom';
import {trackScreenView} from '../../../analytics/FirebaseAnalytics';
import {IWebConfig, getConfig} from '../../../config/WebConfig';
import {ModalScreen} from '../Containers/ModalScreen';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';
import './PlayerProfileOverlay.scss';

interface IProps extends ICompareOverlayProps, IOverlayViewProps {
  onClose: () => void;
}

interface IStateProps {
  config: IWebConfig;
  currentUser: TFirebaseUser | null;
  comps: ICompSummaryAndEntry[];
  following: {[userId: string]: boolean};
}

interface IDispatchProps {
  onDispatchAction: (action: IAction<any>) => void;
}

export const CompareOverlay: React.FC<IProps> = (props) => {
  useEffect(() => {
    trackScreenView('CompareChallengeOverlay');
  }, []);
  const dispatch = useDispatch();
  const comps = useSelector(getUpcomingCompetitions).concat(
    useSelector(getResultedCompetitions),
  );
  return (
    <CompareOverlayView
      {...props}
      config={useSelector(getConfig)}
      currentUser={useSelector(getUser)}
      comps={comps}
      following={useSelector(getUserIsFollowingAsMap)}
      onDispatchAction={useCallback(
        (action: IAction<any>) => dispatch(action),
        [dispatch],
      )}
    />
  );
};
export const CompareOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );
  const navigate = useNavigate();

  let closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;
  const gameUrl = props.config.gameUrl;
  const {compId, questions, counts, onDispatchAction} = props;

  const compAndEntry = props.comps.find((c) => c.summary.id === compId);
  const comp = compAndEntry?.summary;

  const _getUserEntryByIdCallback = useMemo(
    () => getUserEntryByIdStream(gameUrl, compId ?? '', 'invalidate'),
    [gameUrl, compId],
  );

  const _onClose = () => {
    props.onClose();
  };

  const _onPlayComp = () => {
    if (comp) {
      navigate(
        {
          pathname: `/${comp.vendor}/${comp.key}`,
          search: window.location.search,
        },
        {
          state: {
            canGoBack: true,
          },
        },
      );
      props.onClose();
    }
  };

  const _onChangeAnswer = useCallback(
    (questionId: string, answer: number) => {
      if (comp) {
        const entry: IEnterCompPayload = {
          competitionId: comp.id,
          answers: {
            answers: {[questionId]: answer},
            likes: {},
            tempRemoteId: null,
          },
          isEntry: false,
        };
        onDispatchAction(fetchEnterCompetition(entry));
      }
    },
    [comp, onDispatchAction],
  );

  const readyToDisplay = comp && questions && counts;
  const isCurrentUser = props.currentUser?.id === props.userA.id;

  return (
    <ModalScreen
      closing={closing}
      transitionDuration={props.transitionDuration}
      actionIcon={<CloseIcon />}
      onActionIconClick={_onClose}>
      {readyToDisplay && (
        <ComparisonContent
          comp={comp}
          questions={questions}
          counts={counts}
          compStatus={getCompetitionClientStatus(comp.starts, comp.status)}
          getUserAnswersStream={_getUserEntryByIdCallback}
          compareUser={props.userB}
          isCurrentUser={props.currentUser?.id === props.userA.id}
          user={props.userA}
          userAnswers={
            isCurrentUser && compAndEntry.entry
              ? compAndEntry.entry.answers
              : undefined
          }
          onPlayComp={_onPlayComp}
          onChangeAnswer={isCurrentUser ? _onChangeAnswer : undefined}
        />
      )}
    </ModalScreen>
  );
};
