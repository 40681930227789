import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {
  IOverlay,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {
  getCurrentOverlay,
  getCurrentOverlayState,
} from '@chancer/common/lib/core/selectors/overlay/OverlaySelectors';
import {
  removeCurrentOverlay,
  removeOverlayAfterTransition,
} from '@chancer/common/lib/core/actions/overlay/OverlayActions';
import {useTimeout} from '../Core/Hooks';
import {buildOverlay} from './OverlayViewFactory';
import './OverlayContainer.scss';

interface IProps {}

interface IStateProps {
  currentOverlay: IOverlay | null;
  currentOverlayState: OverlayDisplayState;
}
interface IDispatchProps {
  closeOverlay: () => void;
  closeOverlayAfterTranstion: () => void;
}

export const OverlayContainer: React.FC<IProps> = React.memo((props) => {
  const dispatch = useDispatch();
  return (
    <OverlayContainerView
      {...{
        ...props,
        currentOverlay: useSelector(getCurrentOverlay),
        currentOverlayState: useSelector(getCurrentOverlayState),
        closeOverlay: useCallback(
          () => dispatch(removeCurrentOverlay()),
          [dispatch],
        ),
        closeOverlayAfterTranstion: useCallback(
          () => dispatch(removeOverlayAfterTransition()),
          [dispatch],
        ),
      }}
    />
  );
});

export const OverlayContainerView: React.FC<
  IProps & IStateProps & IDispatchProps
> = React.memo((props) => {
  let currentOverlay: IOverlay | null = props.currentOverlay;
  let overlayView: JSX.Element | undefined;

  if (currentOverlay) {
    overlayView = buildOverlay(
      currentOverlay,
      props.currentOverlayState,
      props.closeOverlay,
      props.closeOverlayAfterTranstion,
    );

    return (
      <div className="overlay-container__container">
        <div
          className="overlay-container__glass"
          onClick={props.closeOverlay}
          style={{
            animationName:
              props.currentOverlayState === OverlayDisplayState.TRANSITION_OUT
                ? 'hide-glass'
                : 'show-glass',
            animationDuration: `${currentOverlay.transitionDuration}ms`,
          }}
        />
        {overlayView}
      </div>
    );
  } else {
    return null;
  }
});

export const useCloseOverlayAfterTransition = (
  displayState: OverlayDisplayState,
  onTransitionComplete: () => void,
  duration: number,
) => {
  let timeout: number | undefined;
  if (duration > 0 && displayState === OverlayDisplayState.TRANSITION_OUT) {
    timeout = duration;
  }
  useTimeout(onTransitionComplete, timeout);
};
