import {Epic, ofType} from 'redux-observable';
import {
  RemoteConfig,
  Value,
  fetchAndActivate,
  getAll,
} from 'firebase/remote-config';
import {remoteConfig} from '../../firebase/Firebase';

import {from, of, iif, timer, throwError} from 'rxjs';
import {
  tap,
  catchError,
  retryWhen,
  concatMap,
  map,
  switchMap,
} from 'rxjs/operators';

import log from '@chancer/common/lib/utils/Log';
import {MILLISECONDS_IN_HOUR} from '@chancer/common/lib/utils/DateUtils';

import {ActionType, IAction} from '@chancer/common/lib/core/actions/Actions';

import {IWebAppState} from '../../state/WebAppState';
import {setRemoteConfig} from '@chancer/common/lib/core/actions/remoteConfig/RemoteConfigActions';
import {getRemoteConfig} from '@chancer/common/lib/core/selectors/remoteConfig/RemoteConfigSelectors';
import {IRemoteConfig} from '@chancer/common/lib/core/config/RemoteConfig';

// import {MILLISECONDS_IN_HOUR} from '@chancer/common/lib/utils/DateUtils';

export const loadRemoteConfigEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.STARTUP),
    switchMap(() =>
      loadRemoteConfig()(getRemoteConfig(state$.value)).pipe(
        map((config) => setRemoteConfig(config)),
      ),
    ),
  );

export const loadRemoteConfig = () => (defaultRemoteConfig: IRemoteConfig) => {
  const rc = remoteConfig();
  rc.settings = {
    fetchTimeoutMillis: 60000,
    minimumFetchIntervalMillis: MILLISECONDS_IN_HOUR,
  };
  rc.defaultConfig = defaultRemoteConfig;

  return loadAndRetrieveConfigValues(rc);
};

const loadAndRetrieveConfigValues = (rc: RemoteConfig) =>
  from(fetchAndActivate(rc)).pipe(
    retryWhen((errors) =>
      errors.pipe(
        concatMap((err, i) =>
          iif(() => i < 3, timer(500 * i), throwError(err)),
        ),
      ),
    ),
    tap((activated) =>
      log.info('Remote config fetched. New activation ', activated),
    ),
    map(() => getAll(rc)),
    map((values) => convertToPlainObject(values)),
    catchError((err) =>
      of(err).pipe(
        tap((err) => log.error('Unable to load remote config', err)),
        map(() => getAll(rc)),
        map((values) => convertToPlainObject(values)),
      ),
    ),
  );

const convertToPlainObject = (configValues: {
  [key: string]: Value;
}): IRemoteConfig => {
  const v: {[key: string]: string | number | boolean} = {};
  for (let key in configValues) {
    v[key] = configValues[key].asString();
  }
  return v as IRemoteConfig;
};
