import {Reducer} from 'redux';

import {IAction, ActionType} from '@chancer/common/lib/core/actions/Actions';
import {undefinedInReducerError} from '@chancer/common/lib/core/reducers/ReducerUtils';

import {IWebViewState} from '../../state/WebAppState';

export const webViewStateReducer: Reducer<IWebViewState, IAction<any>> = (
  state: IWebViewState | undefined,
  action: IAction<any>,
): IWebViewState => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.SET_WEB_VIEW_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
