export const getLocaleCountry = () => {
  let localeCode = 'en-US';
  if (navigator.languages !== undefined) {
    localeCode = navigator.languages[0];
  } else {
    localeCode = navigator.language;
  }
  let countryCode = 'US';
  try {
    countryCode = localeCode.split('-')[1];
  } catch (err) {}
  return countryCode ?? 'UNKNOWN';
};
