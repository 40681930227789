import {Reducer} from 'redux';
import {ActionType, IAction} from '../../actions/Actions';
import {undefinedInReducerError} from '../ReducerUtils';
import {ILocalAnswers} from '../../state/AppState';
import {TFirebaseCompEntry} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {
  IQuestionAnswer,
  IQuestionLike,
} from '../../actions/competitions/CompetitionActions';
import {ILocalAnswersForComp} from '../../../interfaces/client/ClientInterfaces';

const getEmptyCompetitionAnswers = (): ILocalAnswersForComp => ({
  answers: {},
  likes: {},
  tempRemoteId: null,
});

export const localAnswersReducer: Reducer<ILocalAnswers, IAction<any>> = (
  state,
  action,
) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.SET_LOCAL_ANSWERS:
      return {loaded: true, values: action.payload};
    case ActionType.QUESTION_ANSWERED:
      return onQuestionAnswered(state, action);
    case ActionType.QUESTION_LIKED:
      return onQuestionLiked(state, action);
    case ActionType.TEMP_ENTRY_RESPONSE:
      if (state.values[action.payload.competitionId]) {
        return {
          ...state,
          values: {
            ...state.values,
            [action.payload.competitionId]: {
              answers: {...state.values[action.payload.competitionId].answers},
              likes: {...state.values[action.payload.competitionId].likes},
              tempRemoteId: action.payload.tempId,
            },
          },
        };
      }
      return state;
    case ActionType.GET_TEMP_ENTRY_RESPONSE:
      if (action.payload.competitionId) {
        return {
          ...state,
          values: {
            ...state.values,
            [action.payload.competitionId]: {
              answers: {...action.payload.answers},
              likes: {...(action.payload.likes ?? {})},
              tempRemoteId: action.payload.id,
            },
          },
        };
      }
      return state;
    case ActionType.CLEAN_UP_LOCAL_ANSWERS:
      return processEntryStatusResponse(state, action.payload);
  }
  return state;
};

const onQuestionAnswered = (
  state: ILocalAnswers,
  action: IAction<IQuestionAnswer>,
): ILocalAnswers => {
  const currentValue: ILocalAnswersForComp =
    state.values[action.payload.competitionId] ?? getEmptyCompetitionAnswers();
  currentValue.answers = {
    ...currentValue.answers,
    [action.payload.questionId]: action.payload.answerIndex,
  };
  return {
    ...state,
    values: {
      ...state.values,
      [action.payload.competitionId]: {
        ...currentValue,
      },
    },
  };
};

const onQuestionLiked = (
  state: ILocalAnswers,
  action: IAction<IQuestionLike>,
): ILocalAnswers => {
  const currentValue: ILocalAnswersForComp = {
    ...(state.values[action.payload.competitionId] ??
      getEmptyCompetitionAnswers()),
  };
  if (action.payload.liked === true) {
    currentValue.likes = {
      ...currentValue.likes,
      [action.payload.questionId]: action.payload.liked,
    };
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {[action.payload.questionId]: value, ...returnValue} =
      currentValue.likes;
    currentValue.likes = returnValue;
  }
  return {
    ...state,
    values: {
      ...state.values,
      [action.payload.competitionId]: {
        ...currentValue,
      },
    },
  };
};

const removeCompId = (state: ILocalAnswers, compId: string) => {
  if (state.values[compId]) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {[compId]: value, ...returnValue} = state.values;
    return {...state, values: returnValue};
  }
  return state;
};

const processEntryStatusResponse = (
  state: ILocalAnswers,
  entryStatus: TFirebaseCompEntry[],
): ILocalAnswers => {
  entryStatus.forEach((entry) => {
    state = removeCompId(state, entry.compId);
  });
  return state;
};
