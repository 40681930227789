import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {createFallbackUserIcon} from '@chancer/common/lib/utils/UserUtils';
import React, {ReactNode} from 'react';
import {StyleSheet, View} from 'react-native';
import {Avatar} from '../Avatar/Avatar';
import {Score} from '../Leaderboard/Score';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {VsIcon} from '../Svg/SvgIcons';
import {COLOR_DARK_2, COLOR_YELLOW} from '../Styles/DesignSystem-chancer';

interface IProps {
  style?: CompositedViewStyle;
  challengingHighlight?: ReactNode;
  challengingUser: TFirebaseUser | null;
  challengingUserScore: number;
  challengedHighlight?: ReactNode;
  challengedUser: TFirebaseUser | null;
  challengedUserScore: number;
  createFallbackUserIcons?: boolean;
}

const AVATAR_BORDER_WIDTH = 2;
const AVATAR_SIZE = 96;
const WINNER_HIGHLIGHT_PADDING_X = 14;
const WINNER_HIGHLIGHT_PADDING_Y = -74;

export const ChallengeHeaderLarge: React.FC<IProps> = (props) => {
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.userContainer}>
        <View style={styles.avatarContainer}>
          <Avatar
            highlightWidth={AVATAR_BORDER_WIDTH}
            highlightColor={
              props.challengedHighlight ? COLOR_YELLOW : COLOR_DARK_2
            }
            size={AVATAR_SIZE}
            imageUrl={
              props.challengedUser
                ? props.challengedUser.media?.image?.url ??
                  (props.createFallbackUserIcons
                    ? createFallbackUserIcon(
                        props.challengedUser.id,
                        props.challengedUser.name,
                      )
                    : undefined)
                : undefined
            }
          />
          {props.challengedHighlight && (
            <View style={styles.highlightContainer}>
              {props.challengedHighlight}
            </View>
          )}
        </View>
        <Score style={styles.userScore} score={props.challengedUserScore} />
      </View>
      <View style={styles.vsIconContainer}>
        <VsIcon />
      </View>
      <View style={styles.userContainer}>
        <View style={styles.avatarContainer}>
          <Avatar
            highlightWidth={AVATAR_BORDER_WIDTH}
            highlightColor={
              props.challengingHighlight ? COLOR_YELLOW : COLOR_DARK_2
            }
            size={AVATAR_SIZE}
            imageUrl={
              props.challengingUser
                ? props.challengingUser.media?.image?.url ??
                  (props.createFallbackUserIcons === true
                    ? createFallbackUserIcon(
                        props.challengingUser.id,
                        props.challengingUser.name,
                      )
                    : undefined)
                : undefined
            }
          />
          {props.challengingHighlight && (
            <View style={styles.highlightContainer}>
              {props.challengingHighlight}
            </View>
          )}
        </View>
        <Score style={styles.userScore} score={props.challengingUserScore} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginBottom: 8,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userContainer: {
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: AVATAR_SIZE,
  },
  avatarContainer: {
    position: 'relative',
    marginLeft: -AVATAR_BORDER_WIDTH,
    marginRight: AVATAR_BORDER_WIDTH,
    marginTop: -AVATAR_BORDER_WIDTH,
    marginBottom: AVATAR_BORDER_WIDTH,
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
  },
  userScore: {
    marginTop: -20,
    backgroundColor: '#424242',
  },
  vsIconContainer: {
    alignItems: 'center',
    marginHorizontal: 8,
  },
  highlightContainer: {
    position: 'absolute',
    top: WINNER_HIGHLIGHT_PADDING_Y,
    left: -WINNER_HIGHLIGHT_PADDING_X,
    width:
      AVATAR_SIZE + AVATAR_BORDER_WIDTH * 2 + WINNER_HIGHLIGHT_PADDING_X * 2,
    height:
      AVATAR_SIZE + AVATAR_BORDER_WIDTH * 2 + WINNER_HIGHLIGHT_PADDING_X * 2,
    alignItems: 'center',
  },
});
