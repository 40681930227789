import React, {ComponentType, useCallback, useMemo, useState} from 'react';

import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {AchievementType} from '@chancer/common/lib/interfaces/achievements/Achievements';
import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {TFirebaseChallengeGroup} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {IStartupOverlayProps} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {createCompetitionVendor} from '@chancer/common/lib/utils/CompetitionUtils';
import {getUserByIdStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {StyleSheet, View} from 'react-native';
import {ChallengeSetupHeader} from '../../Challenges/ChallengeSetupHeader';
import {platformValue} from '../../PlatformUtils';
import {AchievementContent} from './AchievementContent';
import {ChallengeSummaryContent} from './ChallengeSummaryContent';
import {CompSummaryContent} from './CompSummaryContent';
import {StoryProgressBar} from './StoryProgressBar';

interface IProps extends IStartupOverlayProps {
  safeAreaTopPadding: number;
  AchievementIconComponent: ComponentType<{achievement: AchievementType}>;
  ChallengeHightlight?: ComponentType<{}>;
  appPlatform: AppPlatform;
  compSummaries: {[compId: string]: ICompSummaryAndEntry};
  userChallenges?: TFirebaseChallengeGroup[];
  getUserById: ReturnType<typeof getUserByIdStream>;
  onChallengeSelected?: (challenge: TFirebaseChallengeGroup) => void;
  onClose: () => void;
}

export const StartupOverlayContent: React.FC<IProps> = (props) => {
  const {onClose} = props;

  const [screenIndex, setScreenIndex] = useState(0);

  // Add const for each type to assist with typesafe access
  const screens = [
    ...props.achievements.flatMap((c) =>
      c.achievements
        .map((a, idx) =>
          a > 0
            ? {
                type: 'achievement' as const,
                data: c,
                achievement: idx,
                comp: props.compSummaries[c.compId],
              }
            : undefined,
        )
        .filter((a) => a !== undefined),
    ),
    ...props.comps.map((c) => ({
      type: 'competition' as const,
      data: c,
      comp: props.compSummaries[c.compId],
    })),
    ...props.challenges.map((c) => ({
      type: 'challenge' as const,
      data: c,
      comp: props.compSummaries[c.compId],
    })),
  ].filter((s) => s.comp !== undefined);

  const styles = useMemo(
    () => getStyles(props.safeAreaTopPadding),
    [props.safeAreaTopPadding],
  );
  const nextScreen = useCallback(() => {
    if (screenIndex === screens.length - 1) {
      onClose();
    } else {
      setScreenIndex(screenIndex + 1);
    }
  }, [screenIndex, screens.length, onClose]);

  const onNextScreen = useCallback(() => {
    nextScreen();
  }, [nextScreen]);

  const getChallenge = useCallback(
    (challengeId: string) =>
      props.userChallenges?.find((c) => c.id === challengeId),
    [props.userChallenges],
  );

  const screenDetails = screens[screenIndex];
  const vendor = createCompetitionVendor(screenDetails.comp.summary);
  const compName = screenDetails.comp.summary.shortName;
  const compSubTitle = screenDetails.comp.summary.name;
  const compImage = screenDetails.comp.summary.logo.image?.url ?? '';
  let screenComponent = null;
  if (screenDetails.type === 'achievement') {
    screenComponent = (
      <AchievementContent
        key={`achievement_${screenDetails.comp.summary.id}`}
        customIcon={
          <props.AchievementIconComponent
            achievement={screenDetails.achievement}
          />
        }
        safeAreaTopPadding={props.safeAreaTopPadding}
        color={screenDetails.comp.summary.primaryColor}
        score={screenDetails.data.score}
        achievement={screenDetails.achievement}
        compName={vendor.name}
        vendor={vendor.vendor}
        onClose={onNextScreen}
      />
    );
  } else if (screenDetails.type === 'competition') {
    screenComponent = (
      <CompSummaryContent
        key={`competition_${screenDetails.comp.summary.id}`}
        safeAreaTopPadding={props.safeAreaTopPadding}
        color={screenDetails.comp.summary.primaryColor}
        compName={screenDetails.comp.summary.shortName}
        logoUrl={screenDetails.comp.summary.logo.image?.url}
        userImageUrl={props.user.media?.image?.url}
        leaderboards={screenDetails.data.leaderboards}
        onClose={onNextScreen}
      />
    );
  } else if (screenDetails.type === 'challenge') {
    screenComponent = (
      <ChallengeSummaryContent
        key={screenDetails.data.challengeId}
        ChallengeHightlight={props.ChallengeHightlight}
        safeAreaTopPadding={props.safeAreaTopPadding}
        color={screenDetails.comp.summary.primaryColor}
        currentUser={props.user}
        challengeSummary={screenDetails.data}
        challenge={getChallenge(screenDetails.data.challengeId)}
        getUserById={props.getUserById}
        onClose={onNextScreen}
        onChallengeSelected={props.onChallengeSelected}
      />
    );
  }

  return (
    <View style={styles.container}>
      {screenComponent}
      <View style={styles.header}>
        <StoryProgressBar
          style={styles.progressBar}
          backgroundColor={screenDetails.comp.summary.primaryColor}
          screenIndex={screenIndex}
          totalScreens={screens.length}
          onNextScreen={onNextScreen}
        />
        <ChallengeSetupHeader
          imageUrl={compImage}
          title={compName}
          subtitle={compSubTitle}
          appPlatform={props.appPlatform}
          safeAreaTopPadding={0}
        />
      </View>
    </View>
  );
};

const getStyles = (safeAreaTopPadding: number) =>
  StyleSheet.create({
    container: {flex: 1, ...platformValue({}, {web: {height: '100%'}})},
    header: {
      position: 'absolute',
      top: 16 + safeAreaTopPadding,
      left: 16,
      right: 16,
    },
    progressBar: {
      marginBottom: 8,
    },
  });
