import React, {ComponentType, useEffect, useMemo, useState} from 'react';
import {Pressable, ScrollView, StyleSheet, Text, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {
  TFirebaseChallengeGroup,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {TUserCompletedChallenge} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getUserByIdStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {SecondaryButton} from '../../Button/SecondaryButton';
import {ChallengeHeaderLarge} from '../../Challenges/ChallengeHeaderLarge';
import {
  COLOR_BACKGROUND,
  COLOR_GREY_4,
  COLOR_LIGHT,
  COLOR_PURPLE,
  FONT_TITLE,
} from '../../Styles/DesignSystem-chancer';
import {CompSummaryDetail} from './SummaryDetail';

interface IProps {
  safeAreaTopPadding?: number;
  ChallengeHightlight?: ComponentType<{}>;
  color: string;
  currentUser: TFirebaseUser;
  challengeSummary: TUserCompletedChallenge;
  challenge?: TFirebaseChallengeGroup;
  getUserById: ReturnType<typeof getUserByIdStream>;
  onClose: () => void;
  onChallengeSelected?: (challenge: TFirebaseChallengeGroup) => void;
}

enum Result {
  WIN,
  LOSE,
  DRAW,
}

enum StreakResult {
  DRAW,
  YOURS,
  THEIRS,
}

export const ChallengeSummaryContent: React.FC<IProps> = (props) => {
  const {
    safeAreaTopPadding = 0,
    challengeSummary,
    currentUser,
    getUserById,
  } = props;

  const styles = useMemo(
    () => getStyles(safeAreaTopPadding),
    [safeAreaTopPadding],
  );
  const [opponent, setOpponent] = useState<TFirebaseUser | null>(null);

  useEffect(() => {
    if (opponent === null) {
      getUserById(
        challengeSummary.users.find((u) => u !== currentUser.id)!,
      ).subscribe({
        next: (u) => {
          setOpponent(u);
        },
      });
    }
  }, [opponent, challengeSummary.users, currentUser.id, getUserById]);

  const yourIndex = challengeSummary.users.indexOf(currentUser.id);
  const opponentIndex = yourIndex === 0 ? 1 : 0;
  const streakResult =
    challengeSummary.streak === 0
      ? StreakResult.DRAW
      : yourIndex === 0 && challengeSummary.streak > 0
        ? StreakResult.YOURS
        : StreakResult.THEIRS;
  const result =
    challengeSummary.result[yourIndex] > challengeSummary.result[opponentIndex]
      ? Result.WIN
      : challengeSummary.result[yourIndex] <
          challengeSummary.result[opponentIndex]
        ? Result.LOSE
        : Result.DRAW;

  const challenger = yourIndex === 0 ? currentUser : opponent;
  const challenged = yourIndex === 1 ? currentUser : opponent;

  return (
    <Pressable style={styles.pressableContainer} onPress={props.onClose}>
      <LinearGradient
        colors={[props.color, COLOR_BACKGROUND]}
        style={styles.container}>
        <ScrollView
          style={styles.scroll}
          contentContainerStyle={styles.scrollContainer}>
          <ChallengeHeaderLarge
            challengingHighlight={
              props.ChallengeHightlight &&
              (challengeSummary.result[0] > challengeSummary.result[1] ||
                (challengeSummary.result[0] === challengeSummary.result[1] &&
                  challengeSummary.streak > 0)) ? (
                <props.ChallengeHightlight />
              ) : undefined
            }
            challengingUser={challenger}
            challengingUserScore={challengeSummary.result[0]}
            challengedHighlight={
              props.ChallengeHightlight &&
              (challengeSummary.result[0] < challengeSummary.result[1] ||
                (challengeSummary.result[0] === challengeSummary.result[1] &&
                  challengeSummary.streak < 0)) ? (
                <props.ChallengeHightlight />
              ) : undefined
            }
            challengedUser={challenged}
            challengedUserScore={challengeSummary.result[1]}
            createFallbackUserIcons={true}
          />
          <Text style={styles.title}>
            {opponent !== null
              ? result === Result.WIN
                ? `You beat ${opponent.name}!`
                : result === Result.LOSE
                  ? `${opponent.name} beat you!`
                  : "It's a draw!"
              : ''}
          </Text>
          <View style={styles.divider} />
          <View style={styles.detailsContainer}>
            <CompSummaryDetail
              value={challengeSummary.result[yourIndex]}
              title={`You scored ${challengeSummary.result[yourIndex]} points`}
              description={`${opponent?.name ?? 'Opponent'} scored ${
                challengeSummary.result[opponentIndex]
              }`}
            />
            <CompSummaryDetail
              value={Math.abs(challengeSummary.streak)}
              title="Win Streak"
              description={
                challengeSummary.totalPlayed === 1
                  ? `This is your first challenge with ${
                      opponent?.name ?? 'this opponent'
                    }`
                  : result === Result.WIN
                    ? `You've held the crown for ${Math.abs(
                        challengeSummary.streak,
                      )} game${
                        Math.abs(challengeSummary.streak) === 1 ? '' : 's'
                      }`
                    : result === Result.LOSE
                      ? `${
                          opponent?.name ?? 'Opponent'
                        } has held the crown for ${Math.abs(
                          challengeSummary.streak,
                        )} game${
                          Math.abs(challengeSummary.streak) === 1 ? '' : 's'
                        }`
                      : streakResult === StreakResult.DRAW
                        ? 'The crown is still open for the taking'
                        : streakResult === StreakResult.YOURS
                          ? 'It was a draw so you keep the crown'
                          : `It was a draw so ${
                              opponent?.name ?? 'Opponent'
                            } retains the crown`
              }
            />
            <CompSummaryDetail
              value={Math.round(
                (challengeSummary.wins[yourIndex] /
                  challengeSummary.totalPlayed) *
                  100,
              )}
              valueSuffix="%"
              title="Win rate"
              description={
                result === Result.LOSE && challengeSummary.wins[yourIndex] === 0
                  ? `${
                      opponent?.name ?? 'Opponent'
                    } has won all of the challenges so far!`
                  : result === Result.DRAW && streakResult === StreakResult.DRAW
                    ? 'You have drawn all challenges so far'
                    : `You've won ${challengeSummary.wins[yourIndex]} of ${
                        props.challengeSummary.totalPlayed
                      } challenges against ${opponent?.name ?? 'opponent'}`
              }
            />
            {props.challenge !== undefined &&
              props.onChallengeSelected !== undefined && (
                <SecondaryButton
                  label="Send a message"
                  onPress={() => props.onChallengeSelected!(props.challenge!)}
                  style={styles.button}
                />
              )}
          </View>
        </ScrollView>
      </LinearGradient>
    </Pressable>
  );
};

const getStyles = (safeAreaTopPadding: number) =>
  StyleSheet.create({
    pressableContainer: {
      flex: 1,
    },
    container: {
      minHeight: '100%',
    },
    scroll: {
      flex: 1,
      marginTop: 80 + safeAreaTopPadding,
    },
    scrollContainer: {
      flex: 1,
      paddingTop: 57,
      paddingHorizontal: 32,
      paddingBottom: 32,
      alignItems: 'center',
    },
    title: {
      fontFamily: FONT_TITLE,
      textAlign: 'center',
      fontSize: 24,
      color: COLOR_LIGHT,
      marginVertical: 16,
    },
    detailsContainer: {
      flex: 1,
      flexGrow: 1,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginTop: 24,
    },
    divider: {width: '100%', backgroundColor: COLOR_GREY_4, height: 1},
    button: {
      width: '100%',
      backgroundColor: COLOR_PURPLE,
    },
  });
