import {Reducer} from 'redux';
import {undefinedInReducerError} from '../ReducerUtils';
import {ActionType, IAction} from '../../actions/Actions';
import {
  IOverlay,
  OverlayDisplayState,
} from '../../../interfaces/overlay/OverlayInterfaces';
import {IOverlays} from '../../state/AppState';

export const overlaysReducer: Reducer<IOverlays, IAction<any>> = (
  state,
  action,
): IOverlays => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.OVERLAY_ADD:
      return addOverlay(state, action);
    case ActionType.OVERLAY_ADD_AT:
      return addOverlayAt(state, action);
    case ActionType.OVERLAY_REMOVE_CURRENT:
      return removeCurrentOverlay(state, action);
    case ActionType.OVERLAY_REMOVE_AT:
      return removeOverlayAt(state, action);
    case ActionType.OVERLAY_TRANSITION_OUT_COMPLETE:
      return removeOverlayAfterTransition(state, action);
    default:
      return state;
  }
};

const addOverlay = (state: IOverlays, action: IAction<IOverlay>): IOverlays => {
  let newState: any;
  if (state.current == null) {
    newState = {
      current: action.payload,
      currentOverlayState: OverlayDisplayState.DISPLAYED,
      queue: state.queue,
    };
  } else {
    newState = {
      current: state.current,
      queue: [...state.queue, action.payload],
    };
  }

  return Object.assign({}, state, newState);
};

const addOverlayAt = (
  state: IOverlays,
  action: IAction<[number, IOverlay]>,
): IOverlays => {
  let newState: any;
  const index: number = action.payload[0];
  const newOverlay: IOverlay = action.payload[1];
  if (state.current == null || state.queue.length <= index) {
    // No current overlay, or queue is too short. This gives us no way to add at a specific.
    // index, so we'll just add the overlay.
    return addOverlay(state, {
      payload: newOverlay,
      type: ActionType.OVERLAY_ADD,
    });
  } else {
    newState = {
      current: state.current,
      queue: [
        ...state.queue.slice(0, index),
        newOverlay,
        ...state.queue.slice(index),
      ],
    };
  }

  return Object.assign({}, state, newState);
};

const removeCurrentOverlay = (
  state: IOverlays,
  action: IAction<null>,
): IOverlays => {
  let newState: any;
  if (state.current == null) {
    // Nothing to do here
    return state;
  } else if (
    !state.current.transitionDuration ||
    state.current.transitionDuration === 0 ||
    state.currentOverlayState === OverlayDisplayState.TRANSITION_OUT
  ) {
    // The current overlay does not require a transition out
    return immediatelyRemoveOverlay(state);
  } else {
    // Set the Overlay display state to allow the glass and overlay to transition out.
    newState = {
      current: state.current,
      currentOverlayState: OverlayDisplayState.TRANSITION_OUT,
      queue: state.queue,
    };
  }

  return Object.assign({}, state, newState);
};

const removeOverlayAt = (
  state: IOverlays,
  action: IAction<number>,
): IOverlays => {
  if (state.queue.length > action.payload) {
    const newState: any = {
      current: state.current,
      queue: [
        ...state.queue.slice(0, action.payload),
        ...state.queue.slice(action.payload + 1),
      ],
    };
    return Object.assign({}, state, newState);
  } else {
    return state;
  }
};

const removeOverlayAfterTransition = (
  state: IOverlays,
  action: IAction<null>,
): IOverlays => {
  if (state.current == null) {
    // Nothing to do here
    return state;
  } else {
    return immediatelyRemoveOverlay(state);
  }
};

const immediatelyRemoveOverlay = (state: IOverlays): IOverlays => {
  if (state.queue.length > 0) {
    // Grab the latest from the queue and set it as current
    return {
      current: state.queue[0],
      currentOverlayState: OverlayDisplayState.DISPLAYED,
      queue: [...state.queue.slice(1)],
    };
  } else {
    // Set the current to null
    return {
      current: null,
      currentOverlayState: OverlayDisplayState.NONE,
      queue: state.queue,
    };
  }
};
