import {Epic, ofType} from 'redux-observable';
import {map} from 'rxjs/operators';

import {ActionType, IAction} from '../../actions/Actions';
import {setLocalStorage} from '../../actions/localStorage/LocalStorageActions';
import {getLocalStorage} from '../../selectors/localStorage/LocalStorageSelectors';
import {IAppState} from '../../state/AppState';
import {LocalStorageKey} from '../../state/model/LocalStorage';

export const setLocalChatReadDateEpic: Epic<
  IAction<{chatId: string; date: Date}>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SET_LOCAL_CHAT_READ),
    map((action) =>
      convertToLocalChatDates(
        action.payload,
        getLocalStorage(state$.value)[LocalStorageKey.CHAT_READ_DATES] ?? {},
      ),
    ),
    map((readDates: {[compId: string]: number}) =>
      setLocalStorage(LocalStorageKey.CHAT_READ_DATES, readDates),
    ),
  );

const convertToLocalChatDates = (
  newReadDate: {chatId: string; date: Date},
  storedDates: {[chatId: string]: number},
): {[compId: string]: number} => ({
  ...storedDates,
  ...{[newReadDate.chatId]: newReadDate.date.valueOf()},
});

export const setLocalChatReadCountEpic: Epic<
  IAction<{chatId: string; count: number}>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SET_LOCAL_CHAT_READ_COUNT),
    map((action) =>
      convertToLocalChatCounts(
        action.payload,
        getLocalStorage(state$.value)[LocalStorageKey.CHAT_READ_COUNTS] ?? {},
      ),
    ),
    map((readCount: {[chatId: string]: number}) =>
      setLocalStorage(LocalStorageKey.CHAT_READ_COUNTS, readCount),
    ),
  );

const convertToLocalChatCounts = (
  newGroupCount: {chatId: string; count: number},
  storedGroupCounts: {[groupId: string]: number},
): {[groupId: string]: number} => ({
  ...storedGroupCounts,
  ...{[newGroupCount.chatId]: newGroupCount.count},
});
