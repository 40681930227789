import {Reducer} from 'redux';
import {IAction} from '@chancer/common/lib/core/actions/Actions';
import {IWebAppState} from '../state/WebAppState';

import {undefinedInReducerError} from '@chancer/common/lib/core/reducers/ReducerUtils';
import {authReducer} from '@chancer/common/lib/core/reducers/auth/AuthReducer';
import {localAnswersReducer} from '@chancer/common/lib/core/reducers/answers/LocalAnswersReducer';
import {localStorageReducer} from '@chancer/common/lib/core/reducers/localStorage/LocalStorageReducer';
import {firestoreReducer} from '@chancer/common/lib/core/reducers/firestore/FirestoreReducer';
import {overlaysReducer} from '@chancer/common/lib/core/reducers/overlay/OverlaysReducer';
import {remoteConfigReducer} from '@chancer/common/lib/core/reducers/remoteConfig/RemoteConfigReducers';
import {mediaUploadsReducer} from '@chancer/common/lib/core/reducers/mediaUploads/MediaUploadsReducer';
import {webViewStateReducer} from './webViewState/WebViewStateReducer';

export const rootReducer: Reducer<IWebAppState, IAction<any>> = (
  state: IWebAppState | undefined,
  action: IAction<any>,
): IWebAppState => {
  if (!state) {
    throw undefinedInReducerError;
  }
  return {
    auth: authReducer(state.auth, action),
    config: state.config,
    localAnswers: localAnswersReducer(state.localAnswers, action),
    localStorage: localStorageReducer(state.localStorage, action),
    overlays: overlaysReducer(state.overlays, action),
    remoteConfig: remoteConfigReducer(state.remoteConfig, action),
    serverApis: {
      firestore: firestoreReducer(state.serverApis.firestore, action),
      mediaUploads: mediaUploadsReducer(state.serverApis.mediaUploads, action),
    },
    webViewState: webViewStateReducer(state.webViewState, action),
  };
};
