import React, {useCallback} from 'react';
import {
  IOverlayViewProps,
  ISimpleMessageOverlayProps,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {SecondaryTransparentButton} from '@chancer/components/lib/Button/SecondaryTransparentButton';

import {BottomSheet} from '../Containers/BottomSheet';
import './SimpleMessageOverlay.scss';

interface IProps extends ISimpleMessageOverlayProps, IOverlayViewProps {}

export const SimpleMessageOverlay: React.FC<IProps> = (props) => {
  const {onCloseRoutine, onActionRoutine, onClose} = props;

  const _onClose = useCallback(() => {
    if (onCloseRoutine !== undefined) {
      onCloseRoutine();
    }
    onClose();
  }, [onCloseRoutine, onClose]);

  const _onAction = useCallback(() => {
    if (onActionRoutine !== undefined) {
      onActionRoutine();
    }
    onClose();
  }, [onActionRoutine, onClose]);

  return (
    <BottomSheet
      displayState={props.displayState}
      transitionDuration={props.transitionDuration}
      onClose={_onClose}
      onTransitionOutComplete={props.onTransitionOutComplete}>
      <div className="simple-message__title">{props.title}</div>
      <div className="simple-message__copy">{props.message}</div>
      <div className="simple-message__buttons-container">
        <SecondaryTransparentButton
          onPress={_onClose}
          label={props.closeButtonText ? props.closeButtonText : 'Ok'}
        />
        {props.onActionRoutine !== undefined && (
          <SecondaryTransparentButton
            onPress={_onAction}
            label={props.actionButtonText ? props.actionButtonText : 'Action'}
          />
        )}
      </div>
    </BottomSheet>
  );
};
