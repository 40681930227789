import {Epic, ofType} from 'redux-observable';
import {map, filter, mergeMap} from 'rxjs/operators';
import {concat, of} from 'rxjs';

import {TFirebaseUserPrivateProfile} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {fetchUpdateUserPrivate} from '../../actions/firestore/FirestoreActions';
import {IAction, ActionType} from '../../actions/Actions';
import {setLocalStorage} from '../../actions/localStorage/LocalStorageActions';
import {
  getLocallyStoredDisabledContentBundle,
  getUserDisabledContentBundle,
} from '../../selectors/bundle/BundleSelectors';
import {IAppState} from '../../state/AppState';
import {LocalStorageKey} from '../../state/model/LocalStorage';

export const writeInitialDisabledContentBundleEpic: Epic<
  IAction<TFirebaseUserPrivateProfile>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.USER_PRIVATE_RESPONSE),
    map((action) => action.payload),
    mergeMap((userPrivate) =>
      concat(
        // Existing user that has never set their leagues before. Set on their profile
        of('').pipe(
          filter(
            () =>
              userPrivate.disabledLeagues === undefined ||
              userPrivate.disabledLeagues === null,
          ),
          map(() => getUserDisabledContentBundle(state$.value)),
          map((bundle) => fetchUpdateUserPrivate({disabledLeagues: bundle})),
        ),
        // Sync any updates to the users enabledLeagues with the locally stored value
        of('').pipe(
          filter(
            () =>
              userPrivate.disabledLeagues !== undefined &&
              userPrivate.disabledLeagues !== null,
          ),
          map(() => userPrivate.disabledLeagues?.sort().join(',') as string),
          filter(
            (disabledLeagues) =>
              disabledLeagues !==
              getLocallyStoredDisabledContentBundle(state$.value),
          ),
          map((disabledLeagues) =>
            setLocalStorage(LocalStorageKey.DISABLED_LEAGUES, disabledLeagues),
          ),
        ),
      ),
    ),
  );
