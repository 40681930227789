// Polyfills
import 'intersection-observer';
import 'weakmap-polyfill';
import 'core-js/features/string/pad-start';

import React from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import {createEpicMiddleware, EpicMiddleware} from 'redux-observable';
import {HelmetProvider} from 'react-helmet-async';

import {rootEpic} from './epics/WebEpics';
import {rootReducer} from './reducers/WebReducers';

import {getDefaultRemoteConfig} from '@chancer/common/lib/core/config/RemoteConfig';
import {setLocaleCountryCode} from '@chancer/common/lib/core/selectors/bundle/BundleSelectors';
import {IAction} from '@chancer/common/lib/core/actions/Actions';
import {startup} from '@chancer/common/lib/core/actions/startup/StartupActions';
import {defaultWebAppState, IWebAppState} from './state/WebAppState';
import WebApplication from './components/WebApplication';

import {getConfig} from './config/WebConfig';
import {trackException} from './analytics/FirebaseAnalytics';
import './App.scss';
import {getLocaleCountry} from './selectors/bundle/WebBundleSelectors';
import {intialiseFirebase} from './firebase/Firebase';
import {initBranch} from './utils/BranchUtils';

const config = getConfig();

intialiseFirebase(config);
setLocaleCountryCode(getLocaleCountry());
initBranch(config);

window.addEventListener('error', (event) => {
  trackException(
    `[GlobalErrorHander] [message: ${event.message}] [filename: ${event.filename}] [lineno: ${event.lineno}] [colno: ${event.colno}] [error: ${event.error}]`,
  );
});

const epicMiddleware: EpicMiddleware<
  IAction<any>,
  IAction<any>,
  IWebAppState,
  any
> = createEpicMiddleware();

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  defaultWebAppState(config, getDefaultRemoteConfig(config.environment)),
  composeEnhancers(applyMiddleware(epicMiddleware)),
);

epicMiddleware.run(rootEpic);

store.dispatch(startup());

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <WebApplication />
      </HelmetProvider>
    </Provider>
  );
};

export default App;
