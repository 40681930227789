import {AchievementType} from './Achievements';

export const getAchievementName = (achievement: AchievementType): string => {
  return [
    'High Scorer',
    'Solo Win',
    'Perfect',
    'Zero Spoon',
    'Winner',
    'Participant',
  ][achievement];
};

export const getAchievementTitle = (
  achievement: AchievementType,
  prize: string = 'prize',
): string => {
  return achievement === AchievementType.GAME_WINNER
    ? `You won the ${prize}!`
    : `High Score!`;
};
