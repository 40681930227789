import {ActionType, IAction} from '../../actions/Actions';
import {undefinedInReducerError} from '../ReducerUtils';
import {ILocalStorage} from '../../state/model/LocalStorage';
import {Reducer} from 'redux';

export const localStorageReducer: Reducer<ILocalStorage, IAction<any>> = (
  state,
  action,
) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.INIT_LOCAL_STORAGE:
      return {...action.payload};
    case ActionType.SET_LOCAL_STORAGE:
      return {...state, ...action.payload};
  }
  return state;
};
