import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {createFallbackUserIcon} from '@chancer/common/lib/utils/UserUtils';
import React, {ReactNode} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Avatar} from '../Avatar/Avatar';
import {Score} from '../Leaderboard/Score';
import {
  COLOR_BACKGROUND,
  COLOR_DARK_2,
  COLOR_GREY_6,
  COLOR_YELLOW,
  FONT_COPY,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {VsIcon} from '../Svg/SvgIcons';

interface IProps {
  style?: CompositedViewStyle;
  challengingHighlight?: ReactNode;
  challengingUser: TFirebaseUser | null;
  challengingUserScore: number;
  challengedHighlight?: ReactNode;
  challengedUser: TFirebaseUser | null;
  challengedUserScore: number;
  createFallbackUserIcons?: boolean;
}

const AVATAR_BORDER_WIDTH = 2;
const AVATAR_SIZE = 48;
const WINNER_HIGHLIGHT_PADDING_X = 14;
const WINNER_HIGHLIGHT_PADDING_Y = -46;
export const ChallengeHeader: React.FC<IProps> = (props) => {
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.userContainer}>
        <View style={styles.avatarAndScoreContainer}>
          <Score style={styles.userScore} score={props.challengedUserScore} />
          <View style={styles.avatarContainer}>
            <Avatar
              highlightWidth={AVATAR_BORDER_WIDTH}
              highlightColor={
                props.challengedHighlight ? COLOR_YELLOW : COLOR_DARK_2
              }
              size={AVATAR_SIZE}
              imageUrl={
                props.challengedUser
                  ? props.challengedUser.media?.image?.url ??
                    (props.createFallbackUserIcons
                      ? createFallbackUserIcon(
                          props.challengedUser.id,
                          props.challengedUser.name,
                        )
                      : undefined)
                  : undefined
              }
            />
            {props.challengedHighlight && (
              <View style={styles.highlightContainer}>
                {props.challengedHighlight}
              </View>
            )}
          </View>
        </View>
        <Text style={styles.userName} numberOfLines={1} ellipsizeMode="tail">
          {props.challengedUser?.name}
        </Text>
      </View>
      <View style={styles.vsIconContainer}>
        <VsIcon />
      </View>
      <View style={styles.userContainer}>
        <View style={styles.avatarAndScoreContainer}>
          <View style={styles.avatarContainer}>
            <Avatar
              highlightWidth={AVATAR_BORDER_WIDTH}
              highlightColor={
                props.challengingHighlight ? COLOR_YELLOW : COLOR_DARK_2
              }
              size={AVATAR_SIZE}
              imageUrl={
                props.challengingUser
                  ? props.challengingUser.media?.image?.url ??
                    (props.createFallbackUserIcons === true
                      ? createFallbackUserIcon(
                          props.challengingUser.id,
                          props.challengingUser.name,
                        )
                      : undefined)
                  : undefined
              }
            />
            {props.challengingHighlight && (
              <View style={styles.highlightContainer}>
                {props.challengingHighlight}
              </View>
            )}
          </View>
          <Score style={styles.userScore} score={props.challengingUserScore} />
        </View>
        <Text style={styles.userName} numberOfLines={1} ellipsizeMode="tail">
          {props.challengingUser?.name}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 57,
  },
  userContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: -4,
  },
  avatarAndScoreContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  avatarContainer: {
    position: 'relative',
    marginLeft: -AVATAR_BORDER_WIDTH,
    marginRight: AVATAR_BORDER_WIDTH,
    marginTop: -AVATAR_BORDER_WIDTH,
    marginBottom: AVATAR_BORDER_WIDTH,
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
  },
  userScore: {
    backgroundColor: COLOR_BACKGROUND,
  },
  userName: {
    color: COLOR_GREY_6,
    fontFamily: FONT_COPY,
    fontSize: 14,
    marginTop: 8,
  },
  vsIconContainer: {
    alignItems: 'center',
    marginHorizontal: 4,
    marginBottom: 16,
  },
  highlightContainer: {
    position: 'absolute',
    top: WINNER_HIGHLIGHT_PADDING_Y,
    left: -WINNER_HIGHLIGHT_PADDING_X,
    width:
      AVATAR_SIZE + AVATAR_BORDER_WIDTH * 2 + WINNER_HIGHLIGHT_PADDING_X * 2,
    height:
      AVATAR_SIZE + AVATAR_BORDER_WIDTH * 2 + WINNER_HIGHLIGHT_PADDING_X * 2,
    alignItems: 'center',
  },
});
