import {Epic, ofType} from 'redux-observable';
import {map, filter, tap} from 'rxjs/operators';

import log from '@chancer/common/lib/utils/Log';
import {jsonDateReviver} from '@chancer/common/lib/utils/JsonUtils';
import {
  IAction,
  ActionType,
  noOpAction,
} from '@chancer/common/lib/core/actions/Actions';
import {initLocalStorage} from '@chancer/common/lib/core/actions/localStorage/LocalStorageActions';
import {
  ILocalStorage,
  LocalStorageKey,
} from '@chancer/common/lib/core/state/model/LocalStorage';

import {IWebAppState} from '../../state/WebAppState';

export const initLocalStorageEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.STARTUP),
    map(() => readLocalStorage()),
    map((storage) => initLocalStorage(storage)),
  );

const readLocalStorage = () => {
  let keys: string[] | undefined;
  try {
    keys = Object.keys(localStorage);
  } catch (e) {
    log.warning('Unable to read local storage keys');
  }
  if (!keys) {
    return {};
  } else {
    const storage: ILocalStorage = {};
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i] as LocalStorageKey;
      // Check if this key exists in the LocalStorageKey enum
      if (Object.values(LocalStorageKey).includes(key)) {
        let jsonValue: string | null = null;
        try {
          jsonValue = localStorage.getItem(key);
        } catch (e) {
          log.warning(`Unable to read local value for '${key}'`);
        }

        if (jsonValue !== null) {
          const value = JSON.parse(jsonValue, jsonDateReviver);
          storage[key] = value;
        }
      }
    }
    return storage;
  }
};

export const writeLocalStorageEpic: Epic<
  IAction<ILocalStorage>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SET_LOCAL_STORAGE),
    map((action) => action.payload),
    tap((value) => storeLocalValue(value)),
    filter(() => false),
    map(() => noOpAction()),
  );

const storeLocalValue = (value: ILocalStorage) => {
  let keys: string[] | undefined;
  try {
    keys = Object.keys(value);
  } catch (e) {
    log.warning('Unable to read local storage keys');
  }
  if (keys && keys.length === 1) {
    let key = keys[0] as LocalStorageKey;
    // Check if this key exists in the LocalStorageKey enum
    if (Object.values(LocalStorageKey).includes(key)) {
      try {
        const jsonString = JSON.stringify(value[key]);
        localStorage.setItem(key, jsonString);
      } catch (e) {
        log.warning(`Unable to write local value for '${key}'`);
      }
    }
  }
};
