import {IOpenDetails} from '@chancer/common/lib/core/state/model/ChancerUrlParams';
import log from '@chancer/common/lib/utils/Log';
import {
  Analytics,
  logEvent as analyticsLogEvent,
  setUserId as analyticsSetUserId,
  isSupported,
} from 'firebase/analytics';
import {analytics} from '../firebase/Firebase';

let _analytics: Analytics | undefined;
let userId: string | null = null;
let appName: string | null = null;

const dedupe: {
  [key: string]: boolean;
} = {};

export const initializeAnalytics = async (applicationName: string) => {
  appName = applicationName;
  try {
    const supported = await isSupported();
    if (supported) {
      _analytics = analytics();
    }
  } catch {}
};

export const setUserId = (id: string | null) => {
  userId = id;
  if (!_analytics) {
    return;
  }
  if (id) {
    analyticsSetUserId(_analytics, id);
  }
};

export const trackException = (description?: string, fatal?: boolean) => {
  if (!_analytics) {
    return;
  }
  analyticsLogEvent(
    _analytics,
    'exception',
    addCoreParams({description: description, fatal: fatal}),
  );
};

export const trackLogin = (method: string | undefined) => {
  if (!_analytics) {
    return;
  }
  analyticsLogEvent(_analytics, 'login', addCoreParams({method: method}));
};

export const trackSelectContent = (
  content_id: string,
  content_type: 'button' | 'link' = 'button',
  props?: {[key: string]: any},
) => {
  if (!_analytics) {
    return;
  }
  analyticsLogEvent(
    _analytics,
    'select_content',
    addCoreParams({content_id, content_type, ...props}),
  );
};

export const trackScreenView = (screenName: string) => {
  if (!_analytics) {
    return;
  }
  analyticsLogEvent(
    _analytics,
    'screen_view',
    addCoreParams({
      screen_name: screenName,
      firebase_screen: screenName,
    }),
  );
};

export const trackAppExecutionEvent = (
  name: string,
  props?: {[key: string]: any},
) => {
  if (!_analytics) {
    return;
  }
  const propsWithName = props === undefined ? {name} : {name, ...props};
  log.info('[ANALYTICS]', 'app_execution', addCoreParams(propsWithName));
  analyticsLogEvent(_analytics, 'app_execution', addCoreParams(propsWithName));
};

export const trackOpenDetails = (details: IOpenDetails) => {
  trackCustomEvent('open_details', details);
};

export const trackCustomEvent = (
  name: string,
  props?: {[key: string]: any},
) => {
  if (!_analytics) {
    return;
  }
  log.info('[ANALYTICS]', name, addCoreParams(props));
  analyticsLogEvent(_analytics, name, addCoreParams(props));
};

export const trackShare = (
  method?: string,
  contentType?: string,
  contentId?: string,
) => {
  if (!_analytics) {
    return;
  }
  analyticsLogEvent(
    _analytics,
    'share',
    addCoreParams({
      method: method,
      content_type: contentType,
      content_id: contentId,
    }),
  );
};

export const trackSignUp = (method: string | undefined) => {
  if (!_analytics) {
    return;
  }
  if (!dedupe.sign_up) {
    analyticsLogEvent(_analytics, 'sign_up', addCoreParams({method: method}));
    dedupe.sign_up = true;
  }
};

export const trackGroupInviteOpened = (groupId: string) => {
  if (!_analytics) {
    return;
  }

  analyticsLogEvent(
    _analytics,
    'group_invite_opened',
    addCoreParams({group_id: groupId}),
  );
};

export const trackEnterCompOverlay = (
  type: string,
  promo: string,
  isTempEntry: boolean,
  missedQuestions: boolean,
) => {
  if (!_analytics) {
    return;
  }

  analyticsLogEvent(
    _analytics,
    'enter_comp_overlay',
    addCoreParams({
      type,
      promo,
      is_temp_entry: isTempEntry,
      missed_questions: missedQuestions,
    }),
  );
};

export const trackAccountDeleteCancelled = () => {
  if (!_analytics) {
    return;
  }

  analyticsLogEvent(_analytics, 'account_delete_cancelled');
};

export const trackAccountDeleted = () => {
  if (!_analytics) {
    return;
  }

  analyticsLogEvent(_analytics, 'account_deleted');
};

const addCoreParams = (
  eventParams:
    | {
        [key: string]: any;
      }
    | undefined,
): any => {
  if (userId !== null) {
    return {
      ...eventParams,
      user_id: userId,
      app_name: appName,
    };
  }
  return eventParams;
};
