import React from 'react';
import {useSelector} from 'react-redux';

import {
  IOverlay,
  IOverlayViewProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {IWebConfig} from '../../../config/WebConfig';
import {getConfig} from '../../../selectors/config/WebConfigSelectors';
import {AppDownload} from '../../Core/AppPrompt/AppDownload';
import {ModalScreen} from '../Containers/ModalScreen';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';

interface IProps extends IOverlay, IOverlayViewProps {}

interface IStateProps {
  config: IWebConfig;
}
interface IDispatchProps {}

export const GetNativeMissedQuestionOverlay: React.FC<IProps> = (props) => {
  return (
    <GetNativeMissedQuestionOverlayView
      {...props}
      config={useSelector(getConfig)}
    />
  );
};

export const GetNativeMissedQuestionOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );

  let closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;

  return (
    <ModalScreen
      closing={closing}
      transitionDuration={props.transitionDuration}>
      <AppDownload
        analyticsPrefix="missed_question_prompt"
        config={props.config}
        pageUrl={window.location.href}
        title="Never miss a question..."
        imageUri={`${props.config.cdnUrl}/vendors/_default-missed-question-apps-prompt.png`}
        closeLabel="Back to game"
        onDismiss={props.onClose}
      />
    </ModalScreen>
  );
};
