import {Reducer} from 'redux';
import {undefinedInReducerError} from '../ReducerUtils';
import {IAction, ActionType} from '../../actions/Actions';
import {IRemoteConfig} from '../../config/RemoteConfig';

export const remoteConfigReducer: Reducer<
  IRemoteConfig,
  IAction<IRemoteConfig>
> = (state, action) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.SET_REMOTE_CONFIG:
      return action.payload;
  }
  return state;
};
