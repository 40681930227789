import React from 'react';

import './MessageOverlay.scss';

interface IProps {
  closing?: boolean;
  transitionDuration?: number;
  children: React.ReactNode;
}

export const MessageOverlay: React.FC<IProps> = (props) => {
  return (
    <div
      className="message__container"
      style={{
        animationName: props.closing === true ? 'hide-modal' : 'show-modal',
        animationDuration: `${props.transitionDuration}ms`,
      }}>
      {props.children}
    </div>
  );
};
