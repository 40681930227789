import React from 'react';
import {useSelector} from 'react-redux';

import {getCompetition} from '@chancer/common/lib/core/selectors/competitions/CompetitionSelectors';
import {getUser} from '@chancer/common/lib/core/selectors/user/UserSelectors';
import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseComp,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  IOverlay,
  IOverlayViewProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {ChallengeSetupHeader} from '@chancer/components/lib/Challenges/ChallengeSetupHeader';
import {ChallengeVsAvatars} from '@chancer/components/lib/Challenges/ChallengeVsAvatars';
import {COLOR_BACKGROUND} from '@chancer/components/lib/Styles/DesignSystem-chancer';
import {IWebConfig} from '../../../config/WebConfig';
import {getConfig} from '../../../selectors/config/WebConfigSelectors';
import {AppDownload} from '../../Core/AppPrompt/AppDownload';
import {ModalScreen} from '../Containers/ModalScreen';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';
import './GetNativeChallengeOverlay.scss';

interface IProps extends IOverlay, IOverlayViewProps {}

interface IStateProps {
  config: IWebConfig;
  comp: TFirebaseComp | null;
  currentUser: TFirebaseUser | null;
}
interface IDispatchProps {}

export const GetNativeChallengeOverlay: React.FC<IProps> = (props) => {
  return (
    <GetNativeChallengeOverlayView
      comp={useSelector(getCompetition)}
      currentUser={useSelector(getUser)}
      {...props}
      config={useSelector(getConfig)}
    />
  );
};

export const GetNativeChallengeOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  const {comp, currentUser} = props;

  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );

  let closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;

  const appDownload = (
    <AppDownload
      analyticsPrefix="new_challenge_prompt"
      config={props.config}
      pageUrl={window.location.href}
      title="Head to head challenges are only available in the App"
      description={
        'Challenges are a fun way to take on your friends or family and keep track of your picks. This feature is only available in the Mixnpik App.\nChallenges are just for fun, but the winner does get to wear the crown - until they lose it!'
      }
      showImage={false}
      closeLabel="Back to game"
      useGroupsButton={true}
      onDismiss={props.onClose}
    />
  );

  return comp ? (
    <ModalScreen
      closing={closing}
      transitionDuration={props.transitionDuration}>
      <div
        className="get-native-challenges__gradient"
        style={{
          backgroundImage: `linear-gradient(to bottom, ${comp.primaryColor} 0%, ${COLOR_BACKGROUND} 30%)`,
        }}>
        <div className="get-native-challenges__top-icons-container">
          <ChallengeSetupHeader
            title={comp.shortName}
            subtitle="Head to Head"
            imageUrl={comp.logo?.image?.url}
            safeAreaTopPadding={0}
            appPlatform={AppPlatform.WEB}
          />
        </div>
        <ChallengeVsAvatars
          challengingUser={currentUser}
          challengedUser={null}
        />
        {appDownload}
      </div>
    </ModalScreen>
  ) : (
    <ModalScreen
      closing={closing}
      transitionDuration={props.transitionDuration}>
      {appDownload}
    </ModalScreen>
  );
};
