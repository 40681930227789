import {IRemoteConfig} from '@chancer/common/lib/core/config/RemoteConfig';
import {
  IAppState,
  defaultAppState,
} from '@chancer/common/lib/core/state/AppState';
import {AccountViewState} from '../components/Page/Account/AccountViewState';
import {IWebConfig} from '../config/WebConfig';

export const defaultWebAppState = (
  config: IWebConfig,
  remoteConfig: IRemoteConfig,
): IWebAppState => ({
  ...defaultAppState(config, remoteConfig),
  webViewState: {
    isAccountLoginFlow: false,
    isAccountScreenOpen: false,
  },
});

export interface IWebAppState extends IAppState {
  webViewState: IWebViewState;
}

export interface IWebViewState {
  isAccountLoginFlow: boolean;
  isAccountScreenOpen: boolean;
  accountInitScreen?: AccountViewState;
  showUpgrade?: boolean;
}
