import {createSelector} from 'reselect';
import {getUserPrivateProfile} from '../user/UserSelectors';
import {getAuthUserIsNew} from '../auth/AuthSelectors';
import {getLocalStorage} from '../localStorage/LocalStorageSelectors';
import {LocalStorageKey} from '../../state/model/LocalStorage';
import {TFirebaseComp} from '../../../interfaces/firestore/FirestoreClientInterfaces';

let countryCode: string | undefined;

export const setLocaleCountryCode = (localeCountryCode: string) =>
  (countryCode = localeCountryCode);

export const getDefaultDisabledContentBundle = (comp: TFirebaseComp | null) => {
  if (!countryCode) {
    throw new Error('You must call setLocaleCountryCode');
  }

  // Don't show AUSTRALASIA leagues if user is in USA or Canada.
  // let leagues =
  //   countryCode === 'US' || countryCode === 'CA'
  //     ? LEAGUES_BY_BUNDLE[BUNDLES.AUSTRALASIA]
  //     : [];
  let leagues: string[] = []; //JK 2023-05-17 Just show all the leagues to every region for now

  if (comp && leagues.includes(comp.league)) {
    leagues = leagues.filter((l) => l !== comp.league);
  }

  return leagues;
};

export const getLocallyStoredDisabledContentBundle = createSelector(
  getLocalStorage,
  (localStorage) =>
    localStorage[LocalStorageKey.DISABLED_LEAGUES] as string | undefined,
);

export const getUserDisabledContentBundle = createSelector(
  getUserPrivateProfile,
  getLocallyStoredDisabledContentBundle,
  getAuthUserIsNew,
  (userPrivateProfile, locallyStoredDisabledContentBundle, isNew) => {
    if (locallyStoredDisabledContentBundle) {
      return locallyStoredDisabledContentBundle.split(',');
    }
    if (userPrivateProfile) {
      if (
        userPrivateProfile.disabledLeagues !== undefined &&
        userPrivateProfile.disabledLeagues !== null
      ) {
        return userPrivateProfile.disabledLeagues;
      } else if (!isNew) {
        // Has played before introducing content filters.
        return [];
      }
    }
    return getDefaultDisabledContentBundle(null);
  },
);
