import {IMediaUploadsApi} from '../../state/AppState';
import {IAction, ActionType} from '../../actions/Actions';
import {Reducer} from 'redux';
import {
  handleServerRequest,
  handleServerResponse,
  undefinedInReducerError,
  handleServerError,
} from '../ReducerUtils';

export const mediaUploadsReducer: Reducer<IMediaUploadsApi, IAction<any>> = (
  state: IMediaUploadsApi | undefined,
  action: IAction<any>,
): IMediaUploadsApi => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.UPLOAD_IMAGE_REQUEST: {
      const uploadId = getUploadIdFromAction(action);
      return {
        ...state,
        [uploadId]: {
          ...handleServerRequest(state[uploadId], action),
          progress: 0,
        },
      };
    }
    case ActionType.UPLOAD_IMAGE_RESPONSE: {
      const uploadId = getUploadIdFromAction(action);
      return {
        ...state,
        [uploadId]: {
          ...handleServerResponse(state[uploadId], action),
          progress: 1,
        },
      };
    }
    case ActionType.UPLOAD_IMAGE_PROGRESS: {
      const uploadId = getUploadIdFromAction(action);
      return {
        ...state,
        [uploadId]: {
          ...state[uploadId],
          progress: action.payload.progress,
        },
      };
    }
    case ActionType.UPLOAD_IMAGE_ERROR: {
      const uploadId = getUploadIdFromAction(action);

      return {
        ...state,
        [uploadId]: {
          ...handleServerError(state[uploadId], action),
          progress: 0,
        },
      };
    }

    default:
      return state;
  }
};

const getUploadIdFromAction = (action: IAction<any>): string => {
  const uploadId = action.payload.uploadId;

  if (!uploadId) {
    throw new Error('uploadId is required on a media upload');
  }
  return uploadId;
};
