import React, {useCallback, useEffect, useRef, useState} from 'react';

import {OverlayDisplayState} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {BottomSheetBackground} from '@chancer/components/lib/Overlay/BottomSheetBackground';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';
import './BottomSheet.scss';

const TRANSITION_OUT_MULTIPLIER = 1.5;
const DRAGGING_TRANSITION_DURATION = 0;
const SNAP_TRANSITION_DURATION = 75;

interface IProps {
  displayState: OverlayDisplayState;
  transitionDuration: number;
  children: React.ReactNode;
  backgroundColor?: string;
  onClose: () => void;
  onTransitionOutComplete: () => void;
}

export const BottomSheet: React.FC<IProps> = (props) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const topOffsetRef = useRef<number>(0);
  const maxTopRef = useRef<number>(0);

  const contentRefCallback = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      contentRef.current = node;

      new ResizeObserver(() => {
        maxTopRef.current =
          (node.parentElement?.offsetHeight ?? 0) - node.offsetHeight;
        setTop(maxTopRef.current);
      }).observe(node);
    }
  }, []);

  const [doClose, setDoClose] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [top, setTop] = useState<string | number>('100%');

  const closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;

  useCloseOverlayAfterTransition(
    props.displayState,
    props.onTransitionOutComplete,
    props.transitionDuration * TRANSITION_OUT_MULTIPLIER, // Bottom sheet transition off looks too fast, slow it down here
  );

  useEffect(() => {
    if (closing) {
      setTop('100%');
    }
  }, [closing]);

  const _handleDownOnbackdrop = useCallback(() => {
    setDoClose(true);
  }, []);

  const _handleDown = useCallback(() => {
    if (contentRef.current) {
      setTransitionDurationMs(contentRef.current, DRAGGING_TRANSITION_DURATION);
      setDragging(true);
    }
  }, []);

  const _handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    handleMove(e.clientY);
  };
  const _handleMouseTouchMove: React.TouchEventHandler<HTMLDivElement> = (
    e,
  ) => {
    handleMove(e.touches[0].clientY);
  };

  const handleMove = (clientY: number) => {
    if (!contentRef.current) {
      return;
    }
    if (dragging) {
      const newTop = Math.max(
        clientY - topOffsetRef.current,
        maxTopRef.current,
      );
      contentRef.current.style.top = `${newTop}px`;
    }
    if (!dragging) {
      topOffsetRef.current = clientY - (contentRef.current.offsetTop ?? 0);
    }
  };

  const _handleMouseUp = () => {
    if (doClose) {
      props.onClose();
    }
    setDoClose(false);

    if (contentRef.current) {
      if (dragging) {
        if (
          contentRef.current.offsetTop - maxTopRef.current >
          contentRef.current.offsetHeight / 3
        ) {
          setTransitionDurationMs(
            contentRef.current,
            props.transitionDuration * TRANSITION_OUT_MULTIPLIER,
          );
          props.onClose();
        } else {
          setTransitionDurationMs(contentRef.current, SNAP_TRANSITION_DURATION);
          contentRef.current.style.top = `${maxTopRef.current}px`;
        }
      }
    }
    topOffsetRef.current = 0;
    setDragging(false);
  };

  return (
    <div
      onMouseDown={_handleDownOnbackdrop}
      onMouseMove={_handleMouseMove}
      onTouchMove={_handleMouseTouchMove}
      onMouseUp={_handleMouseUp}
      onTouchEnd={_handleMouseUp}
      className="bottom-sheet__container">
      <div
        ref={contentRefCallback}
        className="bottom-sheet__dialog"
        onMouseDown={(e) => e.stopPropagation()}
        style={{
          transitionProperty: 'top',
          top: top,
          transitionDuration: `${props.transitionDuration}ms`,
        }}>
        <BottomSheetBackground
          backgroundColor={props.backgroundColor}
          onContainerDown={_handleDown}>
          <div className="bottom-sheet__content">{props.children}</div>
        </BottomSheetBackground>
      </div>
    </div>
  );
};

const setTransitionDurationMs = (node: HTMLDivElement, duration: number) => {
  node.style.transitionDuration = `${duration}ms`;
};
