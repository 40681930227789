import React, {useMemo} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {
  COLOR_GREY_6,
  COLOR_OVERLAY_BACKGROUND,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  children?: React.ReactNode;
  style?: CompositedViewStyle;
  showHandle?: boolean;
  backgroundColor?: string;
  onHandleDown?: () => void;
  onContainerDown?: () => void;
}

export const BottomSheetBackground: React.FC<IProps> = (props) => {
  const {
    style,
    showHandle = true,
    backgroundColor = COLOR_OVERLAY_BACKGROUND,
  } = props;

  const containerStyle: CompositedViewStyle = useMemo(
    () => [styles.container, style, {backgroundColor: backgroundColor}],
    [style, backgroundColor],
  );

  return (
    <View style={containerStyle}>
      {props.onContainerDown && (
        <Pressable
          style={styles.pressableBackground}
          onPointerDown={props.onContainerDown}
        />
      )}
      {showHandle ? (
        <Pressable onPointerDown={props.onHandleDown ?? props.onContainerDown}>
          <View style={[styles.handleLayout, styles.handle]} />
        </Pressable>
      ) : (
        <View style={styles.handleLayout} />
      )}
      <View style={styles.contentContainer}>{props.children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingHorizontal: 32,
    paddingBottom: 32,
    minHeight: 100,
  },
  pressableBackground: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
  handleLayout: {
    width: 26,
    marginTop: 8,
    marginBottom: 40,
    marginHorizontal: 32,
    height: 3,
    borderRadius: 1,
  },
  handle: {
    backgroundColor: COLOR_GREY_6,
  },
  contentContainer: {
    position: 'relative',
    flex: 1,
  },
});
