import {Action} from 'redux';

export enum ActionType {
  STARTUP = 'STARTUP',
  SET_REMOTE_CONFIG = 'SET_REMOTE_CONFIG',

  RESET_INITIAL_VIEW_STATE = 'RESET_INITIAL_VIEW_STATE',

  STARTUP_MODULE_LOADED = 'STARTUP_MODULE_LOADED',
  STARTUP_MODULE_FAILED = 'STARTUP_MODULE_FAILED',

  NO_OP = 'NO_OP',

  EXECUTE_NOTIFICATION_PERMISSION_REQUEST = 'EXECUTE_NOTIFICATION_PERMISSION_REQUEST',

  OVERLAY_ADD = 'OVERLAY_ADD',
  OVERLAY_ADD_AT = 'OVERLAY_ADD_AT',
  OVERLAY_REMOVE_CURRENT = 'OVERLAY_REMOVE_CURRENT',
  OVERLAY_REMOVE_AT = 'OVERLAY_REMOVE_AT',
  OVERLAY_TRANSITION_OUT_COMPLETE = 'OVERLAY_TRANSITION_OUT_COMPLETE',

  AUTH_ANONYMOUS_LOGIN = 'AUTH_ANONYMOUS_LOGIN',
  AUTH_LOG_OUT = 'AUTH_LOG_OUT',
  AUTH_LOGIN_START = 'AUTH_LOGIN_START',
  AUTH_LOGIN_COMPLETE = 'AUTH_LOGIN_COMPLETE',
  AUTH_LOGGED_OUT = 'AUTH_LOGGED_OUT',
  AUTH_TEMPORARY_USER_SCORE = 'AUTH_TEMPORARY_USER_SCORE',

  COMPETITIONS_REQUEST = 'COMPETITIONS_REQUEST',
  COMPETITIONS_RESPONSE = 'COMPETITIONS_RESPONSE',
  COMPETITIONS_ERROR = 'COMPETITIONS_ERROR',

  RESET_COMPETITION = 'RESET_COMPETITION',

  FETCH_COMPETITION = 'FETCH_COMPETITION',
  COMPETITION_REQUEST = 'COMPETITION_REQUEST',
  COMPETITION_RESPONSE = 'COMPETITION_RESPONSE',
  COMPETITION_ERROR = 'COMPETITION_ERROR',

  QUESTIONS_REQUEST = 'QUESTIONS_REQUEST',
  QUESTIONS_RESPONSE = 'QUESTIONS_RESPONSE',
  QUESTIONS_ERROR = 'QUESTIONS_ERROR',

  FETCH_USER = 'FETCH_USER',
  USER_REQUEST = 'USER_REQUEST',
  USER_RESPONSE = 'USER_RESPONSE',
  USER_ERROR = 'USER_ERROR',

  FETCH_DELETE_USER = 'FETCH_DELETE_USER',
  FETCH_UPDATE_USER = 'FETCH_UPDATE_USER',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',

  FETCH_USER_PRIVATE = 'FETCH_USER_PRIVATE',
  USER_PRIVATE_REQUEST = 'USER_PRIVATE_REQUEST',
  USER_PRIVATE_RESPONSE = 'USER_PRIVATE_RESPONSE',
  USER_PRIVATE_ERROR = 'USER_PRIVATE_ERROR',

  FETCH_UPDATE_USER_PRIVATE = 'FETCH_UPDATE_USER_PRIVATE',
  UPDATE_USER_PRIVATE_REQUEST = 'UPDATE_USER_PRIVATE_REQUEST',
  UPDATE_USER_PRIVATE_RESPONSE = 'UPDATE_USER_PRIVATE_RESPONSE',
  UPDATE_USER_PRIVATE_ERROR = 'UPDATE_USER_PRIVATE_ERROR',

  FETCH_USER_FOLLOWING = 'FETCH_USER_FOLLOWING',
  USER_FOLLOWING_REQUEST = 'USER_FOLLOWING_REQUEST',
  USER_FOLLOWING_RESPONSE = 'USER_FOLLOWING_RESPONSE',
  USER_FOLLOWING_ERROR = 'USER_FOLLOWING_ERROR',

  USER_ENTRIES_REQUEST = 'USER_ENTRIES_REQUEST',
  USER_ENTRIES_RESPONSE = 'USER_ENTRIES_RESPONSE',
  USER_ENTRIES_ERROR = 'USER_ENTRIES_ERROR',

  FETCH_USER_GROUPS = 'FETCH_USER_GROUPS',
  USER_GROUPS_REQUEST = 'USER_GROUPS_REQUEST',
  USER_GROUPS_RESPONSE = 'USER_GROUPS_RESPONSE',
  USER_GROUPS_ERROR = 'USER_GROUPS_ERROR',

  INBOX_MESSAGES_REQUEST = 'INBOX_MESSAGES_REQUEST',
  INBOX_MESSAGES_RESPONSE = 'INBOX_MESSAGES_RESPONSE',
  INBOX_MESSAGES_ERROR = 'INBOX_MESSAGES_ERROR',

  FETCH_INBOX_MESSAGE_OPEN = 'FETCH_INBOX_MESSAGE_OPEN',

  FETCH_FOLLOW_USER = 'FETCH_FOLLOW_USER',
  FETCH_UNFOLLOW_USER = 'FETCH_UNFOLLOW_USER',

  FETCH_ENTER_COMPETITION = 'FETCH_ENTER_COMPETITION',
  ENTER_COMPETITION_REQUEST = 'ENTER_COMPETITION_REQUEST',
  ENTER_COMPETITION_RESPONSE = 'ENTER_COMPETITION_RESPONSE',
  ENTER_COMPETITION_ERROR = 'ENTER_COMPETITION_ERROR',

  FETCH_LEADERBOARD_CONFIG = 'FETCH_LEADERBOARD_CONFIG',
  LEADERBOARD_CONFIG_REQUEST = 'LEADERBOARD_CONFIG_REQUEST',
  LEADERBOARD_CONFIG_RESPONSE = 'LEADERBOARD_CONFIG_RESPONSE',
  LEADERBOARD_CONFIG_ERROR = 'LEADERBOARD_CONFIG_ERROR',

  FETCH_LEADERBOARD = 'FETCH_LEADERBOARD',
  LEADERBOARD_REQUEST = 'LEADERBOARD_REQUEST',
  LEADERBOARD_RESPONSE = 'LEADERBOARD_RESPONSE',
  LEADERBOARD_ERROR = 'LEADERBOARD_ERROR',

  COMPETITION_COUNTS_REQUEST = 'COMPETITION_COUNTS_REQUEST',
  COMPETITION_COUNTS_RESPONSE = 'COMPETITION_COUNTS_RESPONSE',
  COMPETITION_COUNTS_ERROR = 'COMPETITION_COUNTS_ERROR',

  COMPETITION_VIPS_REQUEST = 'COMPETITION_VIPS_REQUEST',
  COMPETITION_VIPS_RESPONSE = 'COMPETITION_VIPS_RESPONSE',
  COMPETITION_VIPS_ERROR = 'COMPETITION_VIPS_ERROR',

  COMPETITION_STATUS_REQUEST = 'COMPETITION_STATUS_REQUEST',
  COMPETITION_STATUS_RESPONSE = 'COMPETITION_STATUS_RESPONSE',
  COMPETITION_STATUS_ERROR = 'COMPETITION_STATUS_ERROR',

  COMPETITION_CHAT_REQUEST = 'COMPETITION_CHAT_REQUEST',
  COMPETITION_CHAT_RESPONSE = 'COMPETITION_CHAT_RESPONSE',
  COMPETITION_CHAT_ERROR = 'COMPETITION_CHAT_ERROR',

  FETCH_SEND_CHAT = 'FETCH_SEND_CHAT',
  SEND_CHAT_REQUEST = 'SEND_CHAT_REQUEST',
  SEND_CHAT_RESPONSE = 'SEND_CHAT_RESPONSE',
  SEND_CHAT_ERROR = 'SEND_CHAT_ERROR',

  TEMP_ENTRY_REQUEST = 'TEMP_ENTRY_REQUEST',
  TEMP_ENTRY_RESPONSE = 'TEMP_ENTRY_RESPONSE',
  TEMP_ENTRY_ERROR = 'TEMP_ENTRY_ERROR',

  FETCH_GET_TEMP_ENTRY = 'FETCH_GET_TEMP_ENTRY',
  GET_TEMP_ENTRY_REQUEST = 'GET_TEMP_ENTRY_REQUEST',
  GET_TEMP_ENTRY_RESPONSE = 'GET_TEMP_ENTRY_RESPONSE',
  GET_TEMP_ENTRY_ERROR = 'GET_TEMP_ENTRY_ERROR',

  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  QUESTION_LIKED = 'QUESTION_LIKED',

  SET_LOCAL_USER = 'SET_LOCAL_USER',
  SET_LOCAL_ANSWERS = 'SET_LOCAL_ANSWERS',
  CLEAN_UP_LOCAL_ANSWERS = 'CLEAN_UP_LOCAL_ANSWERS',

  SET_LOCAL_CHAT_READ = 'SET_LOCAL_CHAT_READ',
  SET_LOCAL_CHAT_READ_COUNT = 'SET_LOCAL_CHAT_READ_COUNT',
  SET_MUTE = 'SET_MUTE',

  INIT_LOCAL_STORAGE = 'INIT_LOCAL_STORAGE',
  SET_LOCAL_STORAGE = 'SET_LOCAL_STORAGE',
  EXPIRE_LOCAL_STORAGE_USER_GROUPS = 'EXPIRE_LOCAL_STORAGE_USER_GROUPS',

  FETCH_SHARE_URL = 'FETCH_SHARE_URL',
  SHARE_URL_REQUEST = 'SHARE_URL_REQUEST',
  SHARE_URL_RESPONSE = 'SHARE_URL_RESPONSE',
  SHARE_URL_ERROR = 'SHARE_URL_ERROR',

  FETCH_UPLOAD_PROFILE_IMAGE = 'FETCH_UPLOAD_PROFILE_IMAGE',

  FETCH_UPLOAD_IMAGE = 'FETCH_UPLOAD_IMAGE',
  UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_PROGRESS = 'UPLOAD_IMAGE_PROGRESS',
  UPLOAD_IMAGE_RESPONSE = 'UPLOAD_IMAGE_RESPONSE',
  UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR',

  FETCH_PICK_GROUP_IMAGE = 'FETCH_PICK_GROUP_IMAGE',

  SET_DEEP_LINK = 'SET_DEEP_LINK',
  DEEP_LINK_PROCESSED = 'DEEP_LINK_PROCESSED',
  OPEN_CHALLENGE_FROM_DEEPLINK = 'OPEN_CHALLENGE_FROM_DEEPLINK',
  REQUEST_NOTIFICATION_PERMISSION = 'REQUEST_NOTIFICATION_PERMISSION',

  SET_WEB_VIEW_STATE = 'SET_WEB_VIEW_STATE',

  FETCH_GROUP = 'FETCH_GROUP',
  GROUP_REQUEST = 'GROUP_REQUEST',
  GROUP_RESPONSE = 'GROUP_RESPONSE',
  GROUP_ERROR = 'GROUP_ERROR',

  FETCH_ADD_GROUP = 'FETCH_ADD_GROUP',
  ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST',
  ADD_GROUP_RESPONSE = 'ADD_GROUP_RESPONSE',
  ADD_GROUP_ERROR = 'ADD_GROUP_ERROR',
  ADD_GROUP_RESET = 'ADD_GROUP_RESET',

  FETCH_ADD_CHALLENGE = 'FETCH_ADD_CHALLENGE',
  // ADD_CHALLENGE_REQUEST = 'ADD_CHALLENGE_REQUEST',
  // ADD_CHALLENGE_RESPONSE = 'ADD_CHALLENGE_RESPONSE',
  // ADD_CHALLENGE_ERROR = 'ADD_CHALLENGE_ERROR',

  FETCH_EDIT_GROUP = 'FETCH_EDIT_GROUP',
  EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST',
  EDIT_GROUP_RESPONSE = 'EDIT_GROUP_RESPONSE',
  EDIT_GROUP_ERROR = 'EDIT_GROUP_ERROR',

  FETCH_DELETE_GROUP = 'FETCH_DELETE_GROUP',
  DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST',
  DELETE_GROUP_RESPONSE = 'DELETE_GROUP_RESPONSE',
  DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR',

  FETCH_CLOSE_GROUP = 'FETCH_CLOSE_GROUP',
  FETCH_GROUP_DETAILS = 'FETCH_GROUP_DETAILS',

  FETCH_GROUP_COUNTS = 'FETCH_GROUP_COUNTS',
  GROUP_COUNTS_REQUEST = 'GROUP_COUNTS_REQUEST',
  GROUP_COUNTS_RESPONSE = 'GROUP_COUNTS_RESPONSE',
  GROUP_COUNTS_ERROR = 'GROUP_COUNTS_ERROR',

  GROUP_PRIVATES_REQUEST = 'GROUP_PRIVATES_REQUEST',
  GROUP_PRIVATES_RESPONSE = 'GROUP_PRIVATES_RESPONSE',
  GROUP_PRIVATES_ERROR = 'GROUP_PRIVATES_ERROR',

  GROUP_CHAT_REQUEST = 'GROUP_CHAT_REQUEST',
  GROUP_CHAT_RESPONSE = 'GROUP_CHAT_RESPONSE',
  GROUP_CHAT_ERROR = 'GROUP_CHAT_ERROR',

  FETCH_SEND_GROUP_CHAT = 'FETCH_SEND_GROUP_CHAT',
  SEND_GROUP_CHAT_REQUEST = 'SEND_GROUP_CHAT_REQUEST',
  SEND_GROUP_CHAT_RESPONSE = 'SEND_GROUP_CHAT_RESPONSE',
  SEND_GROUP_CHAT_ERROR = 'SEND_GROUP_CHAT_ERROR',

  GROUP_QUESTIONS_REQUEST = 'GROUP_QUESTIONS_REQUEST',
  GROUP_QUESTIONS_RESPONSE = 'GROUP_QUESTIONS_RESPONSE',
  GROUP_QUESTIONS_ERROR = 'GROUP_QUESTIONS_ERROR',

  GROUP_COMPETITION_COUNTS_REQUEST = 'GROUP_COMPETITION_COUNTS_REQUEST',
  GROUP_COMPETITION_COUNTS_RESPONSE = 'GROUP_COMPETITION__COUNTS_RESPONSE',
  GROUP_COMPETITION_COUNTS_ERROR = 'GROUP_COMPETITION__COUNTS_ERROR',

  GROUP_LIVE_LEADERBOARDS_REQUEST = 'GROUP_LIVE_LEADERBOARDS_REQUEST',
  GROUP_LIVE_LEADERBOARDS_RESPONSE = 'GROUP_LIVE_LEADERBOARDS_RESPONSE',
  GROUP_LIVE_LEADERBOARDS_ERROR = 'GROUP_LIVE_LEADERBOARDS_ERROR',

  FETCH_VENDOR_COMP_DETAILS = 'FETCH_VENDOR_COMP_DETAILS',
  FETCH_CLOSE_VENDOR = 'FETCH_CLOSE_VENDOR',

  VENDOR_QUESTIONS_REQUEST = 'VENDOR_QUESTIONS_REQUEST',
  VENDOR_QUESTIONS_RESPONSE = 'VENDOR_QUESTIONS_RESPONSE',
  VENDOR_QUESTIONS_ERROR = 'VENDOR_QUESTIONS_ERROR',

  VENDOR_COMPETITION_COUNTS_REQUEST = 'VENDOR_COMPETITION_COUNTS_REQUEST',
  VENDOR_COMPETITION_COUNTS_RESPONSE = 'VENDOR_COMPETITION__COUNTS_RESPONSE',
  VENDOR_COMPETITION_COUNTS_ERROR = 'VENDOR_COMPETITION__COUNTS_ERROR',

  VENDOR_LIVE_LEADERBOARDS_REQUEST = 'VENDOR_LIVE_LEADERBOARDS_REQUEST',
  VENDOR_LIVE_LEADERBOARDS_RESPONSE = 'VENDOR_LIVE_LEADERBOARDS_RESPONSE',
  VENDOR_LIVE_LEADERBOARDS_ERROR = 'VENDOR_LIVE_LEADERBOARDS_ERROR',
}

export interface IAction<P> extends Action<ActionType> {
  type: ActionType;
  payload: P;
}

export const noOpAction = (): IAction<null> => ({
  payload: null,
  type: ActionType.NO_OP,
});
