import {IConfig} from '@chancer/common/lib/core/config/Config';
import configJson from './WebConfig-chancer-development.json';

export interface IWebConfig extends IConfig {
  firebaseConfig: IWebFirebaseConfig;
  facebookPixelId: string;
  branchKey: string;
}

interface IWebFirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL?: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
}

const config = {
  ...configJson,
  version: process.env.BUILD_VERSION ?? '0.0.0',
} as IWebConfig;

export const getConfig = (): IWebConfig => config;
