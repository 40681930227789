import React, {useEffect, useState} from 'react';
import {View, StyleSheet, Animated, Easing} from 'react-native';
import {COLOR_GREY_2, COLOR_GREY_4} from '../../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../../Styles/StyleTypes';
import {platformValue} from '../../PlatformUtils';

interface IProps {
  style?: CompositedViewStyle;
  backgroundColor?: string;
  screenIndex: number;
  totalScreens: number;
  onNextScreen: () => void;
}

const SCREEN_DURATION = 10 * 1000;

export const StoryProgressBar: React.FC<IProps> = (props) => {
  const {screenIndex, totalScreens, onNextScreen} = props;
  const [moveAnim] = useState(new Animated.Value(0));

  // Start animation when screen index changes
  useEffect(() => {
    moveAnim.setValue(screenIndex / totalScreens);
    Animated.timing(moveAnim, {
      toValue: (screenIndex + 1) / totalScreens,
      duration: SCREEN_DURATION,
      useNativeDriver: platformValue(true, {web: false}),
      easing: Easing.linear,
    }).start((result) => {
      result.finished && onNextScreen();
    });
  }, [moveAnim, screenIndex, totalScreens, onNextScreen]);

  return (
    <View style={[styles.container, props.style]}>
      <Animated.View
        style={[
          styles.progress,
          {
            transform: [{scaleX: moveAnim}],
          },
        ]}
      />
      {Array.from({length: totalScreens}).map((_, i) => (
        <View
          key={i}
          style={[
            styles.headerBreaks,
            {
              left: `${(i / totalScreens) * 100}%`,
              backgroundColor: props.backgroundColor,
            },
          ]}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: '100%',
    backgroundColor: COLOR_GREY_2,
    height: 4,
  },
  headerBreaks: {
    position: 'absolute',
    height: 4,
    width: 2,
  },
  progress: {
    transformOrigin: 'left',
    height: 4,
    backgroundColor: COLOR_GREY_4,
  },
});
