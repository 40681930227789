import {Epic, ofType} from 'redux-observable';

import {from, of, concat} from 'rxjs';
import {
  tap,
  catchError,
  map,
  switchMap,
  filter,
  delay,
  take,
} from 'rxjs/operators';

import log from '@chancer/common/lib/utils/Log';

import {
  ActionType,
  IAction,
  noOpAction,
} from '@chancer/common/lib/core/actions/Actions';

import {
  startupModuleFailed,
  startupModuleLoaded,
} from '@chancer/common/lib/core/actions/startup/StartupActions';
import {getIsLoggedIn} from '@chancer/common/lib/core/selectors/auth/AuthSelectors';

import {
  loadAccountScreen,
  loadAuthComponent,
} from '../../components/LazyLoader';
import {getIsAccountScreenOpen} from '../../selectors/webViewState/WebViewStateSelectors';
import {IWebAppState} from '../../state/WebAppState';
import {getAllQuerystringParams} from '../../selectors/host/HostSelectors';
import {
  extractOpenDetails,
  IOpenDetails,
} from '@chancer/common/lib/core/state/model/ChancerUrlParams';
import {trackOpenDetails} from '../../analytics/FirebaseAnalytics';

export const trackOpenDetailsEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.AUTH_LOGGED_OUT, ActionType.AUTH_LOGIN_COMPLETE),
    map(() => getAllQuerystringParams()),
    map((params) => extractOpenDetails(params)),
    filter((details): details is IOpenDetails => details !== undefined),
    tap((details) => trackOpenDetails(details)),
    filter(() => false),
    map(() => noOpAction()),
  );

export const preloadLazyViewsEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.AUTH_LOGGED_OUT, ActionType.AUTH_LOGIN_COMPLETE),
    delay(1000),
    filter(() => !getIsAccountScreenOpen(state$.value)),
    filter(() => !getIsLoggedIn(state$.value)),
    switchMap(() =>
      concat(
        loadModuleStream(loadAccountScreen),
        loadModuleStream(loadAuthComponent),
      ),
    ),
    take(1),
  );

const loadModuleStream = (bundleLoaderFunction: () => Promise<any>) => {
  return from(bundleLoaderFunction()).pipe(
    map(() => startupModuleLoaded(bundleLoaderFunction.name)),
    catchError((err) =>
      of(err).pipe(
        tap(() => log.warning(bundleLoaderFunction.name, err)),
        map(() => startupModuleFailed(bundleLoaderFunction.name)),
      ),
    ),
  );
};
