import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {IAction} from '@chancer/common/lib/core/actions/Actions';
import {
  fetchEnterCompetition,
  fetchFollowUser,
  fetchUnfollowUser,
} from '@chancer/common/lib/core/actions/firestore/FirestoreActions';
import {
  getResultedCompetitions,
  getUpcomingCompetitions,
} from '@chancer/common/lib/core/selectors/competitions/CompetitionSummarySelectors';
import {
  getUser,
  getUserIsFollowingAsMap,
} from '@chancer/common/lib/core/selectors/user/UserSelectors';
import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  TFirebaseCompEntry,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  IOverlayViewProps,
  IPlayerProfileOverlayProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {getUserEntryByIdStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {ComparisonContent} from '@chancer/components/lib/Overlay/Comparison/ComparisonContent';
import {PlayerProfileContent} from '@chancer/components/lib/Overlay/PlayerProfile/PlayerProfileContent';

import {IEnterCompPayload} from '@chancer/common/lib/app/AppMessage';
import {getCompetitionClientStatus} from '@chancer/common/lib/utils/CompetitionUtils';
import {ArrowBackIos, CloseIcon} from '@chancer/components/lib/Svg/SvgIcons';
import {useNavigate} from 'react-router-dom';
import {openAccountScreen} from '../../../actions/webViewState/WebViewStateActions';
import {
  trackScreenView,
  trackSelectContent,
} from '../../../analytics/FirebaseAnalytics';
import {IWebConfig, getConfig} from '../../../config/WebConfig';
import {AccountViewState} from '../../Page/Account/AccountViewState';
import {ModalScreen} from '../Containers/ModalScreen';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';
import './PlayerProfileOverlay.scss';

interface IProps extends IPlayerProfileOverlayProps, IOverlayViewProps {
  onClose: () => void;
}

interface IStateProps {
  config: IWebConfig;
  currentUser: TFirebaseUser | null;
  comps: ICompSummaryAndEntry[];
  following: {[userId: string]: boolean};
}

interface IDispatchProps {
  onDispatchAction: (action: IAction<any>) => void;
}

export const PlayerProfileOverlay: React.FC<IProps> = (props) => {
  useEffect(() => {
    trackScreenView('PlayerProfileOverlay');
  }, []);
  const dispatch = useDispatch();
  const comps = useSelector(getUpcomingCompetitions).concat(
    useSelector(getResultedCompetitions),
  );
  return (
    <PlayerProfileOverlayView
      {...props}
      config={useSelector(getConfig)}
      currentUser={useSelector(getUser)}
      comps={comps}
      following={useSelector(getUserIsFollowingAsMap)}
      onDispatchAction={useCallback(
        (action: IAction<any>) => dispatch(action),
        [dispatch],
      )}
    />
  );
};
export const PlayerProfileOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );
  const navigate = useNavigate();

  let closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;

  const [compare, setCompare] = useState(false);
  const gameUrl = props.config.gameUrl;
  const {compId, questions, counts, currentUser, onDispatchAction} = props;

  const compAndEntry = props.comps.find((c) => c.summary.id === compId);
  const comp = compAndEntry?.summary;
  const currentEntry = compAndEntry?.entry;

  const [entry, setEntry] = useState<TFirebaseCompEntry | null>(
    currentEntry ?? null,
  );

  useEffect(() => {
    currentEntry && setEntry(currentEntry);
  }, [currentEntry]);

  const isCurrentUserLoggedIn = currentUser !== undefined;
  const isCurrentUser = currentUser?.id === props.user.id;
  const isCurrentUserEntered = entry !== undefined;

  const isFollowing = props.following[props.user.id];

  const _getUserEntryByIdCallback = useMemo(
    () => getUserEntryByIdStream(gameUrl, compId ?? '', 'invalidate'),
    [gameUrl, compId],
  );

  const _onCompare = () => {
    trackSelectContent('player_profile_overlay__compare-button');
    setCompare((c) => !c);
  };

  const _onClose = () => {
    if (compare) {
      setCompare(false);
    } else {
      props.onClose();
    }
  };

  const _onFollow = () => {
    trackSelectContent('player_profile_overlay__follow-button');
    const action: IAction<string> = isFollowing
      ? fetchUnfollowUser(props.user.id)
      : fetchFollowUser(props.user.id);
    onDispatchAction(action);
  };

  const _onEdit = () => {
    trackSelectContent('player_profile_overlay__edit-button');
    onDispatchAction(openAccountScreen(AccountViewState.PROFILE));
    props.onClose();
  };

  const _onPlayComp = () => {
    if (comp) {
      navigate(
        {
          pathname: `/${comp.vendor}/${comp.key}`,
          search: window.location.search,
        },
        {
          state: {
            canGoBack: true,
          },
        },
      );
      props.onClose();
    }
  };

  const _onChangeAnswer = useCallback(
    (questionId: string, answer: number) => {
      if (comp) {
        const compEntry: IEnterCompPayload = {
          competitionId: comp.id,
          answers: {
            answers: {[questionId]: answer},
            likes: {},
            tempRemoteId: null,
          },
          isEntry: false,
        };
        onDispatchAction(fetchEnterCompetition(compEntry));
      }
    },
    [comp, onDispatchAction],
  );

  return (
    <ModalScreen
      closing={closing}
      transitionDuration={props.transitionDuration}
      actionIcon={compare ? <ArrowBackIos /> : <CloseIcon />}
      onActionIconClick={_onClose}>
      {compare && comp && questions && counts && currentUser ? (
        <ComparisonContent
          comp={comp}
          questions={questions}
          counts={counts}
          compStatus={getCompetitionClientStatus(comp.starts, comp.status)}
          getUserAnswersStream={_getUserEntryByIdCallback}
          compareUser={props.user}
          isCurrentUser={true}
          user={currentUser}
          userAnswers={entry?.answers ?? {}}
          onPlayComp={_onPlayComp}
          onChangeAnswer={_onChangeAnswer}
        />
      ) : (
        <PlayerProfileContent
          user={props.user}
          vendor={props.vendor}
          isCurrentUser={isCurrentUser}
          isCurrentUserLoggedIn={isCurrentUserLoggedIn}
          isCurrentUserEntered={isCurrentUserEntered}
          following={isFollowing}
          onCompare={_onCompare}
          onFollow={_onFollow}
          onEdit={_onEdit}
        />
      )}
    </ModalScreen>
  );
};
