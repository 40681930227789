import {
  TFirebaseChallengeGroup,
  TFirebaseCompSummary,
  TFirebaseGroup,
} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {
  CompStatus,
  GroupType,
} from '../../../interfaces/firestore/FirestoreInterfaces';
import {getChallengeCompId} from '../../../utils/ChallengeUtils';
import {IAppState} from '../../state/AppState';

export const getGroup = (state: IAppState) =>
  state.serverApis.firestore.group.response;

export const getGroupRequest = (state: IAppState) =>
  state.serverApis.firestore.group.request;

export const getGroupError = (state: IAppState) =>
  state.serverApis.firestore.group.error;

export const getAddGroupId = (state: IAppState) =>
  state.serverApis.firestore.addGroup.response?.id || null;

export const getAddedGroup = (state: IAppState) =>
  state.serverApis.firestore.addGroup.response;

export const getAddGroupError = (state: IAppState) =>
  state.serverApis.firestore.addGroup.error;

export const getEditGroupResponse = (state: IAppState) =>
  state.serverApis.firestore.editGroup.response;

export const getGroupCompCounts = (state: IAppState) =>
  state.serverApis.firestore.groupCompCounts.response;

export const getGroupQuestions = (state: IAppState) =>
  state.serverApis.firestore.groupQuestions.response;

export const getGroupLiveLeaderboards = (state: IAppState) =>
  state.serverApis.firestore.groupLiveLeaderboards.response;

export const getCompsInGroups = (
  groups: TFirebaseGroup[],
  competitions: TFirebaseCompSummary[],
  limitPerVendor: number = 0,
) => {
  const challenges = groups.filter(
    (group): group is TFirebaseChallengeGroup =>
      group.type === GroupType.CHALLENGE,
  );
  const challengeCompIds = competitions
    .filter((comp) =>
      challenges.some((challenge) => getChallengeCompId(challenge) === comp.id),
    )
    .map((comp) => comp.id);

  const vendors = groups
    .filter((group) => group.type !== GroupType.CHALLENGE)
    .flatMap((group) => group.vendors);
  const filteredComps = competitions
    .filter(
      (comp) =>
        (vendors.includes(comp.vendor) && comp.status !== CompStatus.DRAFT) ||
        challengeCompIds.includes(comp.id),
    )
    .sort((a, b) => b.starts.seconds - a.starts.seconds);

  if (limitPerVendor > 0) {
    const countsPerVendor: {[key: string]: number} = {};
    return filteredComps.filter((comp) => {
      if (!countsPerVendor[comp.vendor]) {
        countsPerVendor[comp.vendor] = 0;
      }
      countsPerVendor[comp.vendor]++;
      return (
        countsPerVendor[comp.vendor] <= limitPerVendor ||
        challengeCompIds.includes(comp.id)
      );
    });
  }
  return filteredComps;
};

export const getGroupChatRequest = (state: IAppState) =>
  state.serverApis.firestore.groupChat.request;

export const getGroupChat = (state: IAppState) =>
  state.serverApis.firestore.groupChat.response;

export const getGroupCounts = (state: IAppState) =>
  state.serverApis.firestore.groupCounts.response;

export const getGroupPrivates = (state: IAppState) =>
  state.serverApis.firestore.groupPrivates.response;
