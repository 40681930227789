import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import React, {useEffect, useState} from 'react';
import {Observable, Subscription} from 'rxjs';

interface IProps {
  userId: string;
  getPostfix: () => string;
  getUserById: (userId: string) => Observable<TFirebaseUser>;
}

export const LazyName: React.FC<IProps> = (props) => {
  const visible = true; // TODO

  const {userId, getUserById} = props;
  const [user, setUser] = useState<TFirebaseUser | null>(null);

  useEffect(() => {
    let sub: Subscription | undefined;
    if (visible && user === null) {
      sub = getUserById(userId).subscribe({
        next: (u) => {
          setUser(u);
        },
      });
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [visible, user, userId, getUserById]);

  return user !== null ? `${user.name}${props.getPostfix()}` : '';
};
