import {IOverlay} from '../../../interfaces/overlay/OverlayInterfaces';
import {ActionType, IAction} from '../Actions';

export function addOverlay(overlay: IOverlay): IAction<IOverlay> {
  return {
    payload: overlay,
    type: ActionType.OVERLAY_ADD,
  };
}

export function addOverlayAt(
  index: number,
  overlay: IOverlay,
): IAction<[number, IOverlay]> {
  return {
    payload: [index, overlay],
    type: ActionType.OVERLAY_ADD_AT,
  };
}

export function removeCurrentOverlay(): IAction<null> {
  return {
    payload: null,
    type: ActionType.OVERLAY_REMOVE_CURRENT,
  };
}

export function removeOverlayAt(index: number): IAction<number> {
  return {
    payload: index,
    type: ActionType.OVERLAY_REMOVE_AT,
  };
}

export function removeOverlayAfterTransition(): IAction<null> {
  return {
    payload: null,
    type: ActionType.OVERLAY_TRANSITION_OUT_COMPLETE,
  };
}
