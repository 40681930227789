import * as Sentry from '@sentry/react';
import {signInAnonymously, User} from 'firebase/auth';
import {Epic, ofType, StateObservable} from 'redux-observable';
import {concat, from, of, Subject} from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  ActionType,
  IAction,
  noOpAction,
} from '@chancer/common/lib/core/actions/Actions';
import {
  anonymousLogin,
  loggedOut,
  loginComplete,
  loginStart,
} from '@chancer/common/lib/core/actions/auth/AuthActions';
import {
  fetchGetTempEntry,
  fetchUser,
  userResponse,
} from '@chancer/common/lib/core/actions/firestore/FirestoreActions';
import {getCurrentCompetitionsLocalAnswers} from '@chancer/common/lib/core/selectors/answers/AnswersSelectors';
import {log} from '@chancer/common/lib/utils/Log';
import {capitalise} from '@chancer/common/lib/utils/StringUtils';

import {auth} from '../../firebase/Firebase';
import {getIsAccountScreenOpen} from '../../selectors/webViewState/WebViewStateSelectors';

import {
  addOverlayAt,
  removeCurrentOverlay,
} from '@chancer/common/lib/core/actions/overlay/OverlayActions';
import {getIsLoggedIn} from '@chancer/common/lib/core/selectors/auth/AuthSelectors';
import {
  getCompetition,
  getCompetitionId,
} from '@chancer/common/lib/core/selectors/competitions/CompetitionSelectors';
import {getCurrentOverlay} from '@chancer/common/lib/core/selectors/overlay/OverlaySelectors';
import {getLoadingOverlay} from '@chancer/common/lib/interfaces/overlay/OverlayCreator';
import {OverlayType} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {
  setUserId,
  trackAppExecutionEvent,
  trackLogin,
  trackSignUp,
} from '../../analytics/FirebaseAnalytics';
import {getTempRemotIdFromUrl} from '../../selectors/host/HostSelectors';
import {IWebAppState} from '../../state/WebAppState';
import {getFirestoreUserAfterAuth} from '../firestore/WebFirestoreEpics';

export const logOutEpic: Epic<IAction<null>, IAction<any>, IWebAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.AUTH_LOG_OUT),
    tap(() => auth().signOut()),
    filter(() => false),
    map(() => noOpAction()),
  );

export const doAnonymousLoginEpic: Epic<
  ReturnType<typeof anonymousLogin>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.AUTH_ANONYMOUS_LOGIN),
    filter(() => getIsLoggedIn(state$.value) === false),
    switchMap((action) =>
      concat(
        of(action).pipe(
          filter(() => action.payload.showLoaderOverlay),
          map(() => addOverlayAt(0, getLoadingOverlay())),
        ),
        from(signInAnonymously(auth())).pipe(
          tap(() => log.debug('AnonymousLoginComplete')),
          map(() => noOpAction()),
          catchError(() => of(noOpAction())),
        ),
      ),
    ),
  );

export const startupLoginEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.STARTUP),
    switchMap(() =>
      getAuthStateObservable().pipe(
        tap((user) =>
          log.info('[AuthStateObservable] User change event', user),
        ),
        mergeMap((user) => {
          if (user !== null) {
            return loggedInStream()(user, state$);
          } else {
            return loggedOutStream()(state$);
          }
        }),
      ),
    ),
  );

const loggedInStream =
  () => (authUser: User, state$: StateObservable<IWebAppState>) =>
    concat(
      of(loginStart(authUser)),
      from(
        getFirestoreUserAfterAuth(authUser, getCompetition(state$.value)),
      ).pipe(
        mergeMap((userDetails) =>
          concat(
            of(loginComplete(authUser, userDetails.isNew)),
            of(userResponse(userDetails.user)),
            removeLoadingOverlayStream(state$),
            of('').pipe(
              tap(() => setUserId(authUser.uid)),
              tap(() => trackLogin(getMethod(authUser))),
              tap(() => Sentry.setUser({id: authUser.uid})),
              filter(() => userDetails.isNew),
              tap(() => trackSignUp(getMethod(authUser))),
              map(() => noOpAction()),
            ),
            // Check if we have a tempEntryId, and no local values for the comp. Load the temp entry
            getTempEntryIfRequiredStream(state$),
            of(fetchUser()),
          ),
        ),
        catchError((err) =>
          of('').pipe(
            tap(() =>
              Sentry.addBreadcrumb({
                category: 'login',
                message: 'Authenticated users login proceedure failed',
              }),
            ),
            tap(() => Sentry.captureException(err)),
            mergeMap(() => loggedOutStream()(state$)),
          ),
        ),
      ),
    );

const loggedOutStream = () => (state$: StateObservable<IWebAppState>) =>
  concat(
    of(loggedOut()),
    // User logged out using the account screen
    of('').pipe(
      filter(() => getIsAccountScreenOpen(state$.value)),
      tap(() => setUserId(null)),
      tap(() => window.location.reload()),
      map(() => noOpAction()),
    ),
  );

const getAuthStateObservable = () => {
  const authState = new Subject<User | null>();
  // Stream to fall back to if onAuthStateChanged does not respond
  of('')
    .pipe(
      delay(3000),
      map(() => auth().currentUser),
      tap((_) =>
        log.debug(
          '[onAuthStateChanged] Falling back to retrieving user manually',
        ),
      ),
      tap((_) => trackAppExecutionEvent('sign_in_fallback')),
      tap((user) => authState.next(user)),
      takeUntil(authState),
    )
    .subscribe();
  log.debug('[onAuthStateChanged] register');
  auth().onAuthStateChanged(authState);

  return authState;
};

const getMethod = (user: User): string | undefined => {
  if (user.providerData && user.providerData[0]) {
    if (user.providerData[0].providerId.toLocaleLowerCase() === 'password') {
      return 'Email';
    } else {
      return capitalise(user.providerData[0].providerId.replace('.com', ''));
    }
  }

  return undefined;
};

const getTempEntryIfRequiredStream = (state$: StateObservable<IWebAppState>) =>
  of('').pipe(
    map(() => getTempRemotIdFromUrl(state$.value)),
    filter((tempEntryId): tempEntryId is string => tempEntryId !== null),
    filter((_) => getCompetitionId(state$.value) !== null),
    filter(
      (_) =>
        getCurrentCompetitionsLocalAnswers(state$.value).answers.length === 0,
    ),
    map((tempEntryId) => fetchGetTempEntry(tempEntryId)),
  );

const removeLoadingOverlayStream = (state$: StateObservable<IWebAppState>) =>
  of(getCurrentOverlay(state$.value)).pipe(
    filter((o) => o?.type === OverlayType.LOADING),
    map(() => removeCurrentOverlay()),
  );
