import {Epic, ofType} from 'redux-observable';
import {map} from 'rxjs/operators';

import {getUploadImageOverlay} from '../../../interfaces/overlay/OverlayCreator';
import {ActionType, IAction} from '../../actions/Actions';
import {fetchUpdateUser} from '../../actions/firestore/FirestoreActions';
import {IFetchMediaUpload} from '../../actions/mediaUploads/MediaUploadsActions';
import {addOverlay} from '../../actions/overlay/OverlayActions';
import {getUserId} from '../../selectors/user/UserSelectors';
import {IAppState} from '../../state/AppState';

export const uploadProfileImageEpic: Epic<
  IAction<IFetchMediaUpload>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_UPLOAD_PROFILE_IMAGE),
    map((action) =>
      addOverlay(
        getUploadImageOverlay(
          'profileImage',
          {width: 300, height: 300, crop: true, isCircleCrop: true},
          `users/${getUserId(state$.value)}`,
          () => fetchUpdateUser({media: {}}),
          (remoteUrl: string) =>
            fetchUpdateUser({
              media: {image: {url: remoteUrl}},
            }),
        ),
      ),
    ),
  );
