export enum CampaignURLParams {
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CONTENT = 'utm_content',
  UTM_TERM = 'utm_term',
}

export enum ChancerURLParams {
  COMP_PARAM = 'comp',
  ATTRIBUTION_PARAM = 'a_id',
  TEMP_REMOTE_ID_PARAM = 'tr_id',
}

export interface IOpenDetails {
  campaign?: string;
  medium?: string;
  source?: string;
  attributionId?: string;
}
export const extractOpenDetails = (urlParameters: {
  [key: string]: string;
}): IOpenDetails | undefined => {
  const campaign = urlParameters[CampaignURLParams.UTM_CAMPAIGN];
  const medium = urlParameters[CampaignURLParams.UTM_MEDIUM];
  const source = urlParameters[CampaignURLParams.UTM_SOURCE];
  const attributionId = urlParameters[ChancerURLParams.ATTRIBUTION_PARAM];
  if (campaign || medium || source || attributionId) {
    return {
      campaign: campaign,
      medium: medium,
      source: source,
      attributionId: attributionId,
    };
  } else {
    return undefined;
  }
};
