import React, {useCallback, useMemo} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {IColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {Avatar} from '../Avatar/Avatar';
import {
  COLOR_GREY_6,
  COLOR_LIGHT,
  FONT_COPY,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

export interface IMultiGameEntrantItemProps {
  comp: ICompSummaryAndEntry;
  colorConfig: IColorConfiguration;
  noColorBackground?: boolean;
  promoMode?: boolean;
  children?: React.ReactNode;
  onPress?: (user: ICompSummaryAndEntry) => void;
}

export const ITEM_HEIGHT = 64;

export const CompDetailsListItem: React.FC<IMultiGameEntrantItemProps> = (
  props,
) => {
  const {comp, colorConfig, onPress} = props;

  const contextualContainer: CompositedViewStyle = useMemo(
    () => [
      styles.container,
      props.noColorBackground !== true && {
        backgroundColor: colorConfig.leaderboardItemsBackground,
      },
    ],
    [colorConfig.leaderboardItemsBackground, props.noColorBackground],
  );

  const _onPress = useCallback(() => {
    if (onPress) {
      onPress(comp);
    }
  }, [comp, onPress]);

  return (
    <Pressable
      style={contextualContainer}
      disabled={onPress === undefined}
      onPress={_onPress}>
      <>
        <Avatar
          imageUrl={comp.summary.promo.image?.url}
          highlightWidth={0}
          highlightGap={0}
          size={48}
          radius={8}
        />
        <View style={styles.copyContainer}>
          <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
            {!props.promoMode
              ? `${comp.summary.shortName} ${comp.summary.roundName}`
              : comp.summary.shortName}
          </Text>
          <Text style={styles.time} numberOfLines={1} ellipsizeMode="tail">
            {!props.promoMode
              ? `${formatDate(comp.summary.starts.toDate())} ∙ ${
                  comp.summary.entriesCount
                } fans`
              : `${formatDateWithTime(comp.summary.starts.toDate())} ∙ ${
                  comp.summary.name
                }`}
          </Text>
        </View>
        {props.children}
      </>
    </Pressable>
  );
};

// Today, Yesterday or Apr 1
const formatDate = (date: Date) => {
  const now = new Date();
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  ) {
    return 'Today';
  }

  now.setDate(now.getDate() - 1);
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  ) {
    return 'Yesterday';
  }

  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
};

const formatDateWithTime = (date: Date) => {
  return `${formatDate(date)} ${date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  })}`;
};

const styles = StyleSheet.create({
  container: {
    userSelect: 'none',
    position: 'relative',
    height: ITEM_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginTop: 2,
  },
  copyContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    marginLeft: 12,
    overflow: 'hidden',
  },
  name: {
    color: COLOR_LIGHT,
    fontFamily: FONT_SUB_TITLE,
    fontSize: 16,
  },
  time: {
    color: COLOR_GREY_6,
    fontFamily: FONT_COPY,
    fontSize: 13,
  },
});
