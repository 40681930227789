import {ActionType, IAction} from '@chancer/common/lib/core/actions/Actions';
import {AccountViewState} from '../../components/Page/Account/AccountViewState';
import {IWebViewState} from '../../state/WebAppState';

export const openAccountScreen = (
  initialScreen?: AccountViewState,
  showUpgrade?: boolean,
): IAction<IWebViewState> => ({
  type: ActionType.SET_WEB_VIEW_STATE,
  payload: {
    accountInitScreen: initialScreen,
    isAccountLoginFlow: false,
    isAccountScreenOpen: true,
    showUpgrade,
  },
});

export const openAccountScreenForLogin = (): IAction<IWebViewState> => ({
  type: ActionType.SET_WEB_VIEW_STATE,
  payload: {
    isAccountLoginFlow: true,
    isAccountScreenOpen: true,
    accountInitScreen: undefined,
  },
});

export const closeAccountScreen = (): IAction<IWebViewState> => ({
  type: ActionType.SET_WEB_VIEW_STATE,
  payload: {
    isAccountLoginFlow: false,
    isAccountScreenOpen: false,
    accountInitScreen: undefined,
  },
});
