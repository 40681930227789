import React from 'react';
import {StyleSheet} from 'react-native';
import {COLOR_YELLOW} from '../Styles/DesignSystem-chancer';

import {IButtonProps, PrimaryButton} from './PrimaryButton';

interface IProps extends Omit<IButtonProps, 'labelStyle' | 'disabledStyle'> {}

export const SecondaryTransparentButton: React.FC<IProps> = (props) => {
  return (
    <PrimaryButton
      style={[styles.container, props.style]}
      size={props.size}
      labelStyle={styles.label}
      disabled={props.disabled}
      label={props.label}
      onPress={props.onPress}>
      {props.children}
    </PrimaryButton>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
  },
  label: {
    color: COLOR_YELLOW,
  },
});
