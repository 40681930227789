const getFbq = () => (window as any).fbq ?? {};

export const trackFbPixel = (
  type: 'track' | 'trackCustom',
  event: string,
  params?: {[key: string]: any},
): void => {
  const fbPixel = getFbq();
  fbPixel(type, event, params);
};
