import branch, {SessionData} from 'branch-sdk';
import {useEffect, useState} from 'react';

import {IShareUrlPayload} from '@chancer/common/lib/utils/Branch';
import log from '@chancer/common/lib/utils/Log';
import {IWebConfig} from '../config/WebConfig';
export const initBranch = (config: IWebConfig) =>
  new Promise((resolve, reject) => {
    branch.init(config.branchKey, {}, (err, data) => {
      log.debug('[Branch][initBranch]');
      if (err) {
        reject(err);
      } else {
        log.debug('[Branch][initBranch] Complete', data);
        resolve(data);
      }
    });
  });

export const getBranchLink = (payload: IShareUrlPayload) =>
  new Promise<string | null>((resolve, reject) => {
    branch.link(
      {
        channel: 'web',
        feature: 'share',
        data: {
          $canonical_identifier: payload.identifier,
          $deeplink_path: payload.webUrl,
          $og_title: payload.title,
          $og_description: payload.description,
          $og_image_url: payload.contentImageUrl,
          ...(payload.customMetadata ?? {}),
          $canonical_url: payload.webUrl,
          ...(payload.forceAppstore !== true
            ? {
                $desktop_url: payload.webUrl,
                $ios_url: payload.webUrl,
                $ipad_url: payload.webUrl,
                $android_url: payload.webUrl,
              }
            : {}),
        },
      },
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          log.debug('[Branch][getBranchLink]', data);
          resolve(data);
        }
      },
    );
  });

const getBranchLaunchData = () =>
  new Promise<SessionData | null>((resolve, reject) => {
    branch.data((err, data) => {
      if (err) {
        reject(err);
      } else {
        log.debug('[Branch][getBranchLaunchData]', data);
        resolve(data);
      }
    });
  });

export const useCreateBranchLink = (payload: IShareUrlPayload) => {
  const [link, setLink] = useState<string>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getLink = async () => {
      setLoading(true);
      setLink(undefined);
      const response = await getBranchLink(payload);
      setLink(response || undefined);
      setLoading(false);
    };
    if (!loading && !link) {
      log.debug('[Branch][useCreateBranchLink]', payload);
      getLink();
    }
  }, [payload, loading, link]);

  return link;
};

export const useBranchLaunchData = () => {
  const [data, setData] = useState<SessionData>();
  useEffect(() => {
    const getData = async () => {
      setData(undefined);
      const response = await getBranchLaunchData();
      setData(response || undefined);
    };
    getData();
  }, []);

  return data;
};
