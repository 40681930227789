import React, {useEffect} from 'react';

import {PrimaryButton} from '@chancer/components/lib/Button/PrimaryButton';
import {SecondaryButton} from '@chancer/components/lib/Button/SecondaryButton';
import {
  CompositedTextStyle,
  CompositedViewStyle,
} from '@chancer/components/lib/Styles/StyleTypes';
import {
  AlarmIcon,
  CircleSlashIcon,
  PrizeIcon,
} from '@chancer/components/lib/Svg/SvgIcons';
import {
  trackScreenView,
  trackSelectContent,
} from '../../../analytics/FirebaseAnalytics';
import {IWebConfig} from '../../../config/WebConfig';
import './TempUpgrade.scss';

interface IProps {
  daysRemaining: number;
  analyticsPrefix: 'upgrade_prompt';
  config: IWebConfig;
  onUpgrade: () => void;
  onDismiss: () => void;
}

export const TempUpgrade: React.FC<IProps> = (props) => {
  useEffect(() => {
    trackScreenView('UpgradePromptOverlay');
  }, []);

  const {config} = props;
  const showCloseButton = true; //props.daysRemaining > 0;

  const _goToUpgrade = () => {
    trackSelectContent(`${props.analyticsPrefix}_upgrade`, 'button', {
      days_remaining: props.daysRemaining,
    });
    props.onUpgrade();
  };

  const _onClose = () => {
    trackSelectContent(`${props.analyticsPrefix}_close`, 'button', {
      days_remaining: props.daysRemaining,
    });
    props.onDismiss();
  };

  const bullets = [
    {
      icon: <AlarmIcon height={30} width={30} color="#f6b700" />,
      title: '28 Day Limit',
      description:
        'Temporary logins on Mixnpik last 28 days. You can set up another one, but you will lose your game history and ranking.',
    },
    {
      icon: <PrizeIcon height={30} width={30} color="#f6b700" />,
      title: 'No Prizes',
      description:
        "As we have no contact details, we can't reliably send you a prize.",
    },
    {
      icon: <CircleSlashIcon height={30} width={30} color="#f6b700" />,
      title: 'Lose Access',
      description:
        'We save your temp login data in the browser. This could be wiped at any time.',
    },
  ];

  const title =
    props.daysRemaining === 28
      ? "You're playing with a temporary login"
      : `${props.daysRemaining} days left on your temporary login`;

  return (
    <div className="temp-upgrade__content-container">
      <div
        className="temp-upgrade__mixnpik-logo-container"
        style={{
          height: 48,
          width: 48,
          backgroundImage: `url('${`${config.cdnUrl}/vendors/_default_logo.png`}')`,
        }}
      />
      <div className="temp-upgrade__title">{title}</div>
      <div className="temp-upgrade__footer-title">
        Hope you're having fun! Anyone can jump in with a temporary login, but
        just a heads up, there are some limitations:
      </div>
      <div className="temp-upgrade__footer-copy">
        {bullets.map((limitation, index) => (
          <div className="temp-upgrade__footer-copy-entry" key={index}>
            <div className="temp-upgrade__footer-copy-entry-icon">
              {limitation.icon}
            </div>
            <div className="temp-upgrade__footer-copy-entry-text">
              <div className="temp-upgrade__footer-copy-entry-text-title">
                {limitation.title}
              </div>
              <div className="temp-upgrade__footer-copy-entry-text-description">
                {limitation.description}
              </div>
            </div>
          </div>
        ))}
      </div>
      <PrimaryButton
        style={buttonStyle}
        onPress={_goToUpgrade}
        label="Save my data"
        labelStyle={buttonLabelStyle}
      />
      {showCloseButton && (
        <SecondaryButton
          style={buttonStyle}
          onPress={_onClose}
          label="Not now"
          labelStyle={buttonLabelStyle}
        />
      )}
    </div>
  );
};

const buttonStyle: CompositedViewStyle = {
  marginHorizontal: 8,
  marginTop: 16,
};

const buttonLabelStyle: CompositedTextStyle = {
  textTransform: 'uppercase',
};
