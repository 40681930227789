import firebase from 'firebase/compat/app';

import {initializeApp, FirebaseApp} from 'firebase/app';
import {getAuth, Auth, connectAuthEmulator} from 'firebase/auth';
import {
  getFirestore,
  Firestore,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import {
  getStorage,
  FirebaseStorage,
  connectStorageEmulator,
} from 'firebase/storage';
import {getMessaging, Messaging} from 'firebase/messaging';
import {getAnalytics, Analytics} from 'firebase/analytics';
import {getRemoteConfig, RemoteConfig} from 'firebase/remote-config';
import {initializePerformance} from 'firebase/performance';

import {IWebConfig} from '../config/WebConfig';
import {initializeAnalytics} from '../analytics/FirebaseAnalytics';

let _firebaseApp: FirebaseApp;

export const intialiseFirebase = (config: IWebConfig) => {
  if (!_firebaseApp) {
    firebase.initializeApp(config.firebaseConfig); // Remove when we have updated everything to use modular
    _firebaseApp = initializeApp(config.firebaseConfig);
    if (config.environment === 'local') {
      // firebase.auth().useEmulator('http://localhost:9099'); // Remove when we have updated everything to use modular
      connectFirestoreEmulator(firestore(), 'localhost', 8080);
      connectAuthEmulator(auth(), 'http://localhost:9099');
      connectStorageEmulator(storage(), 'localhost', 9199);
    } else {
      initializeAnalytics(_firebaseApp.name);
      initializePerformance(_firebaseApp);
    }
  }
};

let _auth: Auth;
export const auth = () => {
  if (!_auth) {
    _auth = getAuth(_firebaseApp);
  }
  return _auth;
};

let _firestore: Firestore;
export const firestore = () => {
  if (!_firestore) {
    _firestore = getFirestore(_firebaseApp);
  }
  return _firestore;
};

let _storage: FirebaseStorage;
export const storage = () => {
  if (!_storage) {
    _storage = getStorage(_firebaseApp);
  }
  return _storage;
};

let _messaging: Messaging;
export const messaging = () => {
  if (!_messaging) {
    _messaging = getMessaging(_firebaseApp);
  }
  return _messaging;
};

let _analytics: Analytics;
export const analytics = () => {
  if (!_analytics) {
    _analytics = getAnalytics(_firebaseApp);
  }
  return _analytics;
};

let _remoteConfig: RemoteConfig;
export const remoteConfig = () => {
  if (!_remoteConfig) {
    _remoteConfig = getRemoteConfig(_firebaseApp);
  }
  return _remoteConfig;
};
