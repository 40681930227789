import {createSelector} from 'reselect';

import {ILocalAnswersForComp} from '../../../interfaces/client/ClientInterfaces';
import {
  TFirebaseCompEntry,
  TFirebaseCompSummary,
} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {CompStatus} from '../../../interfaces/firestore/FirestoreInterfaces';
import {getCompetitionClientStatus} from '../../../utils/CompetitionUtils';
import {IAppState} from '../../state/AppState';
import {
  ClientStatus,
  ICompSummaryAndEntry,
} from '../../state/model/CompetitionModel';
import {getLocalAnswers} from '../answers/AnswersSelectors';
import {getUserDisabledContentBundle} from '../bundle/BundleSelectors';
import {getUserEntries} from '../user/UserSelectors';
import {getCompetitionId} from './CompetitionSelectors';

const getCompSummariesResult = (state: IAppState) =>
  state.serverApis.firestore.competitions.response;

enum SortType {
  UPCOMING,
  RESULTED,
  UPCOMING_AND_STATUS,
}

const sortCompetitions = (
  compA: ICompSummaryAndEntry,
  compB: ICompSummaryAndEntry,
  sortType: SortType,
) => {
  if (sortType === SortType.UPCOMING_AND_STATUS) {
    return compA.summary.status !== compB.summary.status
      ? compA.summary.status === CompStatus.INPLAY
        ? -1
        : compA.summary.status === CompStatus.OPEN &&
          compB.summary.status !== CompStatus.INPLAY
        ? 1
        : 0
      : compA.outstandingQuestions !== compB.outstandingQuestions
      ? compA.outstandingQuestions > compB.outstandingQuestions
        ? -1
        : 1
      : compA.entry !== null || compB.entry !== null
      ? compA.entry === null
        ? -1
        : 1
      : compA.summary.starts.toMillis() - compB.summary.starts.toMillis();
  } else if (sortType === SortType.UPCOMING) {
    return compA.summary.starts.toMillis() - compB.summary.starts.toMillis();
  } else {
    return compB.summary.starts.toMillis() - compA.summary.starts.toMillis();
  }
};

const sortUpcomingAndStatusCompetitions = (
  compA: ICompSummaryAndEntry,
  compB: ICompSummaryAndEntry,
) => sortCompetitions(compA, compB, SortType.UPCOMING_AND_STATUS);

const sortUpcomingCompetitions = (
  compA: ICompSummaryAndEntry,
  compB: ICompSummaryAndEntry,
) => sortCompetitions(compA, compB, SortType.UPCOMING);

const sortResultedCompetitions = (
  compA: ICompSummaryAndEntry,
  compB: ICompSummaryAndEntry,
) => sortCompetitions(compA, compB, SortType.RESULTED);

export const getCompSummariesLoaded = createSelector(
  getCompSummariesResult,
  (result) => result !== null,
);

export const getFilteredCompSummaries = createSelector(
  getCompSummariesResult,
  getUserDisabledContentBundle,
  (comps, disabledLeagues) => {
    if (comps) {
      return comps.filter(
        (comp) =>
          comp.vendor &&
          comp.key &&
          comp.status !== CompStatus.SUSPENDED &&
          !disabledLeagues.includes(comp.league),
      );
    }
    return [];
  },
);

export const getFilteredCompSummariesAndEntries = createSelector(
  getFilteredCompSummaries,
  getUserEntries,
  getLocalAnswers,
  (competitions, entries, localAnswers): ICompSummaryAndEntry[] =>
    competitions
      .map((comp) =>
        getSummaryAndEntry(comp, entries.get(comp.id), localAnswers[comp.id]),
      )
      .sort(sortResultedCompetitions),
);

export const getFilteredCompSummariesAndEntriesMap = createSelector(
  getFilteredCompSummariesAndEntries,
  (competitions): {[compId: string]: ICompSummaryAndEntry} =>
    competitions.reduce(
      (acc: {[compId: string]: ICompSummaryAndEntry}, comp) => {
        acc[comp.summary.id] = comp;
        return acc;
      },
      {},
    ),
);

export const getCurrentCompetitionSummary = createSelector(
  getCompetitionId,
  getFilteredCompSummaries,
  (id, summaries) => summaries?.find((s) => s.id === id) ?? null,
);

const getUpcoming = createSelector(
  getFilteredCompSummariesAndEntries,
  (competitions): ICompSummaryAndEntry[] =>
    competitions.filter((comp) => {
      const clientStatus = getCompetitionClientStatus(
        comp.summary.starts,
        comp.summary.status,
      );
      return (
        clientStatus === ClientStatus.OPEN ||
        clientStatus === ClientStatus.PRE_START ||
        clientStatus === ClientStatus.IN_PLAY ||
        clientStatus === ClientStatus.RESULTED
      );
    }),
);

export const getUpcomingCompetitions = createSelector(
  getUpcoming,
  (competitions): ICompSummaryAndEntry[] =>
    competitions.sort(sortUpcomingCompetitions),
);

export const getUpcomingSortByStatusCompetitions = createSelector(
  getUpcoming,
  (competitions): ICompSummaryAndEntry[] =>
    competitions.sort(sortUpcomingAndStatusCompetitions),
);

export const getUpcomingCompetitionsExcludingEntered = createSelector(
  getUpcomingCompetitions,
  getCompetitionId,
  (upcomingCompetitions, compId): ICompSummaryAndEntry[] =>
    upcomingCompetitions.filter(
      (comp) =>
        comp.summary.id !== compId &&
        comp.entry === null &&
        comp.summary.status === CompStatus.OPEN,
    ),
);

export const getEnteredUpcomingCompetitions = createSelector(
  getUpcomingCompetitions,
  (upcomingCompetitions): ICompSummaryAndEntry[] =>
    upcomingCompetitions.filter((comp) => comp.entry !== null),
);

export const getResultedCompetitions = createSelector(
  getFilteredCompSummariesAndEntries,
  (competitions): ICompSummaryAndEntry[] =>
    competitions
      .filter((comp) => {
        const clientStatus = getCompetitionClientStatus(
          comp.summary.starts,
          comp.summary.status,
        );
        return clientStatus === ClientStatus.VERIFIED;
      })
      .sort(sortResultedCompetitions),
);

export const getEnteredResultedCompetitions = createSelector(
  getResultedCompetitions,
  (resultedCompetitions): ICompSummaryAndEntry[] =>
    resultedCompetitions.filter((comp) => comp.entry !== null),
);

export const getLastResultedCompetitions = createSelector(
  getResultedCompetitions,
  (competitions): ICompSummaryAndEntry[] =>
    competitions.filter((comp, i, src) => {
      return (
        i === src.findIndex((c) => c.summary.vendor === comp.summary.vendor)
      );
    }),
);

export const getLeagues = createSelector(
  getUpcomingCompetitions,
  getResultedCompetitions,
  (upcoming, resulted) => {
    const competitions = upcoming.concat(resulted);
    return competitions
      .filter((c) => c.summary.status !== CompStatus.DRAFT)
      .reduce((p: string[], v: ICompSummaryAndEntry) => {
        if (p.find((s) => s === v.summary.league) === undefined) {
          p.push(v.summary.league);
        }
        return p;
      }, []);
  },
);

export const getFeaturedComp = (
  upcomingComps: ICompSummaryAndEntry[],
  resultedComps: ICompSummaryAndEntry[],
): ICompSummaryAndEntry | null => {
  const eligibleComps = upcomingComps.filter(
    (c) => c.summary.status !== CompStatus.DRAFT,
  );
  let featuredComp: ICompSummaryAndEntry | null = eligibleComps[0] ?? null;
  let featuredCompWeight = 0;

  const playStatusWeight = 1;
  const isFeaturedWeight = 1;
  const isOpenWeight = 1;
  const maxWeight = playStatusWeight + isFeaturedWeight + isOpenWeight;

  if (eligibleComps.length > 0) {
    for (let i = 0; i < eligibleComps.length; i++) {
      const comp = eligibleComps[i];
      let compWeight = 0;

      // Entry status
      if (comp.summary.status === CompStatus.OPEN && comp.entry === null) {
        compWeight += playStatusWeight;
      } else if (
        comp.summary.status === CompStatus.INPLAY &&
        comp.entry !== null
      ) {
        compWeight += playStatusWeight / 2;
      }

      // Comp status
      if (comp.summary.status === CompStatus.OPEN) {
        compWeight += isOpenWeight;
      }

      // Featured
      if (
        comp.summary.status === CompStatus.OPEN &&
        comp.summary.featured === true
      ) {
        compWeight += isFeaturedWeight;
      } else if (
        comp.summary.status === CompStatus.INPLAY &&
        comp.summary.featured === true
      ) {
        compWeight += isFeaturedWeight / 3;
      }

      if (compWeight > featuredCompWeight) {
        featuredCompWeight = compWeight;
        featuredComp = comp;
      }
      if (featuredCompWeight === maxWeight) {
        // We wont find one more eligible than this, we're done
        break;
      }
    }
  }
  if (!featuredComp && resultedComps.length > 0) {
    featuredComp =
      resultedComps.find((c) => c.summary.featured === true) ??
      resultedComps[0];
  }
  return featuredComp;
};

export const getSummaryAndEntry = (
  comp: TFirebaseCompSummary,
  entry: TFirebaseCompEntry | undefined,
  localAnswers: ILocalAnswersForComp | undefined,
): ICompSummaryAndEntry => {
  if (entry === undefined && localAnswers === undefined) {
    return {
      summary: comp,
      entry: null,
      outstandingQuestions:
        comp.status === CompStatus.INPLAY ? comp.openQuestions.length : 0,
    };
  } else {
    return {
      summary: comp,
      entry: entry ?? null,
      outstandingQuestions: comp.openQuestions.filter(
        (q) =>
          !Object.keys(entry?.answers ?? {}).includes(q) &&
          !Object.keys(localAnswers?.answers ?? {}).includes(q),
      ).length,
    };
  }
};
