import {getAchievementName} from '@chancer/common/lib/interfaces/achievements/AchievementSpec';
import {
  AchievementType,
  EMPTY_ACHIEVEMENTS,
} from '@chancer/common/lib/interfaces/achievements/Achievements';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {AchievementIcon} from '../../Achievements/AchievementIcon';
import {
  COLOR_GREY_2,
  COLOR_LIGHT,
  FONT_COPY_MEDIUM,
} from '../../Styles/DesignSystem-chancer';

interface IProps {
  vendorName: string;
  achievements: number[];
}

export const PlayerProfileAchievements: React.FC<IProps> = (props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{`${props.vendorName} Achievements`}</Text>
      <View style={styles.achievementsRow}>
        {EMPTY_ACHIEVEMENTS.map((a, index) => {
          const achievementType =
            (index + EMPTY_ACHIEVEMENTS.length - 1) % EMPTY_ACHIEVEMENTS.length;
          return (
            achievementType !== AchievementType.ZERO_CORRECT &&
            achievementType !== AchievementType.PARTICIPANT && (
              <AchievementColumn
                key={index}
                achievement={achievementType}
                count={props.achievements[achievementType] ?? 0}
              />
            )
          );
        })}
      </View>
    </View>
  );
};

interface IAchievementColumnProps {
  achievement: AchievementType;
  count: number;
}
const AchievementColumn: React.FC<IAchievementColumnProps> = (props) => {
  return (
    <View style={styles.achievementContainer}>
      <AchievementIcon
        achievement={props.achievement}
        size={24}
        color={COLOR_LIGHT}
      />
      <Text
        style={styles.achievementName}
        numberOfLines={1}
        ellipsizeMode="tail">
        {getAchievementName(props.achievement)}
      </Text>
      <View style={styles.horizontalRule} />
      <Text style={styles.achievementCount}>{props.count}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    width: '100%',
    marginTop: 40,
    alignItems: 'center',
  },
  achievementsRow: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    marginTop: 24,
  },
  title: {fontFamily: FONT_COPY_MEDIUM, fontSize: 18, color: COLOR_LIGHT},
  achievementContainer: {
    marginHorizontal: 4,
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  achievementName: {
    marginTop: 2,
    marginBottom: 8,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 14,
    color: COLOR_LIGHT,
  },
  horizontalRule: {
    width: '100%',
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderTopColor: COLOR_GREY_2,
  },
  achievementCount: {
    paddingTop: 16,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 24,
    color: COLOR_LIGHT,
  },
});
