import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMatch, useNavigate} from 'react-router-dom';
import {
  closeAccountScreen,
  openAccountScreen,
} from '../../../actions/webViewState/WebViewStateActions';
import {getIsAccountScreenOpen} from '../../../selectors/webViewState/WebViewStateSelectors';

import {
  TabHome,
  TabMore,
  TabYourGames,
} from '@chancer/components/lib/Svg/SvgIcons';
import './BottomNav.scss';

interface IProps {
  unreadMessagesCount?: number;
}

interface IStateProps {
  isAccountScreenOpen: boolean;
}
interface IDispatchProps {
  onOpenAccountScreen: (open: boolean) => void;
}

export const BottomNav: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const openAccountScreenCallback = useCallback(
    (open: boolean) =>
      dispatch(open ? openAccountScreen() : closeAccountScreen()),
    [dispatch],
  );
  return (
    <BottomNavView
      {...props}
      isAccountScreenOpen={useSelector(getIsAccountScreenOpen)}
      onOpenAccountScreen={openAccountScreenCallback}
    />
  );
};

enum Tabs {
  HOME,
  GROUPS,
  YOUR_GAMES,
  ACCOUNT,
}

export const BottomNavView: React.FC<IProps & IStateProps & IDispatchProps> = (
  props,
) => {
  const navigate = useNavigate();

  const competitionPageMatch = useMatch('/:vendor/:key');
  const hidden =
    competitionPageMatch?.params?.vendor &&
    competitionPageMatch?.params?.key !== 's';

  const stateMatch = useMatch('/:path1');

  const selectedTab = props.isAccountScreenOpen
    ? Tabs.ACCOUNT
    : stateMatch?.params?.path1 === 'groups'
      ? Tabs.GROUPS
      : stateMatch?.params?.path1 === 'yourgames'
        ? Tabs.YOUR_GAMES
        : Tabs.HOME;

  const _openAccounts = () => {
    props.onOpenAccountScreen(true);
  };
  const _onHome = () => {
    navigate({
      pathname: '/',
      search: window.location.search,
    });
    props.onOpenAccountScreen(false);
  };

  const _onYourGames = () => {
    navigate({
      pathname: '/yourgames',
      search: window.location.search,
    });
    props.onOpenAccountScreen(false);
  };

  return (
    <div
      className="bottom-nav__container"
      style={hidden ? {bottom: '-71px'} : {}}>
      <div
        className={`bottom-nav__item${
          selectedTab === Tabs.HOME ? ' bottom-nav__item--active' : ''
        }`}
        onClick={_onHome}>
        <TabHome />
        <div>Home</div>
      </div>

      {/* <div
        className={`bottom-nav__item${
          selectedTab === Tabs.GROUPS ? ' bottom-nav__item--active' : ''
        }`}
        onClick={_onGroups}>
        <TabFriends />
        <div>Friends</div>
      </div> */}
      <div
        className={`bottom-nav__item${
          selectedTab === Tabs.YOUR_GAMES ? ' bottom-nav__item--active' : ''
        }`}
        onClick={_onYourGames}>
        <TabYourGames />
        <div>Your Games</div>
      </div>
      <div>
        <div
          className={`bottom-nav__item${
            selectedTab === Tabs.ACCOUNT ? ' bottom-nav__item--active' : ''
          }`}
          onClick={_openAccounts}>
          <TabMore />
          <div>Profile</div>
        </div>
        {props.unreadMessagesCount !== undefined &&
          props.unreadMessagesCount > 0 && (
            <div className="bottom-nav__badge"> </div>
          )}
      </div>
    </div>
  );
};
