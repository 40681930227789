import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setLocalStorage} from '@chancer/common/lib/core/actions/localStorage/LocalStorageActions';
import {LocalStorageKey} from '@chancer/common/lib/core/state/model/LocalStorage';
import {
  IOverlay,
  IOverlayViewProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {IWebConfig} from '../../../config/WebConfig';
import {getConfig} from '../../../selectors/config/WebConfigSelectors';
import {AppDownload} from '../../Core/AppPrompt/AppDownload';
import {ModalScreen} from '../Containers/ModalScreen';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';

interface IProps extends IOverlay, IOverlayViewProps {}

interface IStateProps {
  config: IWebConfig;
}
interface IDispatchProps {
  onDismiss: () => void;
}

export const GetNativePromptOverlay: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const {onClose} = props;
  const onDismissCallback = useCallback(() => {
    dispatch(setLocalStorage(LocalStorageKey.LAST_APP_PROMPT, new Date()));
    onClose();
  }, [dispatch, onClose]);
  return (
    <GetNativePromptOverlayView
      {...props}
      config={useSelector(getConfig)}
      onDismiss={onDismissCallback}
    />
  );
};

export const GetNativePromptOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );

  let closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;

  return (
    <ModalScreen
      closing={closing}
      transitionDuration={props.transitionDuration}>
      <AppDownload
        analyticsPrefix="apps_prompt"
        config={props.config}
        pageUrl={window.location.href}
        onDismiss={props.onDismiss}
      />
    </ModalScreen>
  );
};
