import {getCurrentCompetitionsLocalAnswers} from '@chancer/common/lib/core/selectors/answers/AnswersSelectors';
import {createSelector} from 'reselect';
import {IWebAppState} from '../../state/WebAppState';

const getAccountLoginFlow = (state: IWebAppState) =>
  state.webViewState.isAccountLoginFlow;

export const getIsAccountLoginFlow = createSelector(
  getAccountLoginFlow,
  getCurrentCompetitionsLocalAnswers,
  (isLoginFlow, currentCompAnwsers) =>
    isLoginFlow || Object.keys(currentCompAnwsers.answers).length > 0,
);

export const getAccountInitScreen = (state: IWebAppState) =>
  state.webViewState.accountInitScreen;

export const getIsAccountScreenOpen = (state: IWebAppState) =>
  state.webViewState.isAccountScreenOpen;

export const getAccountShowUpgrade = (state: IWebAppState) =>
  state.webViewState.showUpgrade === true;
