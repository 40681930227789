import {createSelector} from 'reselect';
import {
  IRemoteChatConfig,
  IRemoteEnterCompOverlayConfig,
} from '../../config/RemoteConfig';
import {IAppState} from '../../state/AppState';

export const getRemoteConfig = (state: IAppState) => state.remoteConfig;

export const getRemoteChatConfig = createSelector(
  getRemoteConfig,
  (config) => JSON.parse(config.chat) as IRemoteChatConfig,
);

export const getRemoteEnterCompOverlayConfig = createSelector(
  getRemoteConfig,
  (config) =>
    JSON.parse(config.enter_comp_overlay) as IRemoteEnterCompOverlayConfig,
);
