import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {
  COLOR_DARK_4,
  COLOR_GREY_8,
  COLOR_LIGHT,
  FONT_COPY_BOLD,
  FONT_COPY_MEDIUM,
} from '../../Styles/DesignSystem-chancer';
import {platformValue} from '../../PlatformUtils';

interface IProps {
  value: string | number;
  valueSuffix?: string;
  title: string;
  description?: string;
}

export const CompSummaryDetail: React.FC<IProps> = (props) => {
  return (
    <View style={styles.container}>
      <View style={styles.valueContainer}>
        <Text
          style={styles.value}
          numberOfLines={platformValue(1, {web: 0})}
          adjustsFontSizeToFit={true}>
          {props.value}
          {props.valueSuffix && (
            <Text style={styles.valueSymbol}>{props.valueSuffix}</Text>
          )}
        </Text>
      </View>
      <View style={styles.copyContainer}>
        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.title}>
          {props.title}
        </Text>
        {props.description && (
          <Text
            numberOfLines={2}
            ellipsizeMode="tail"
            style={styles.description}>
            {props.description}
          </Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 24,
  },
  valueContainer: {
    flexGrow: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: 42,
    height: 42,
    borderRadius: 8,
    backgroundColor: COLOR_DARK_4,
  },
  value: {
    color: COLOR_LIGHT,
    fontSize: 18,
    fontFamily: FONT_COPY_BOLD,
  },
  valueSymbol: {
    fontSize: 11,
  },
  copyContainer: {
    flex: 1,
    marginLeft: 8,
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  title: {
    color: COLOR_LIGHT,
    fontSize: 18,
    fontFamily: FONT_COPY_BOLD,
  },
  description: {
    color: COLOR_GREY_8,
    fontSize: 16,
    fontFamily: FONT_COPY_MEDIUM,
  },
});
