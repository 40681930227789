import React from 'react';
import './ModalScreen.scss';

interface IProps {
  closing?: boolean;
  transitionDuration?: number;
  actionIcon?: JSX.Element;
  children: React.ReactNode;
  onActionIconClick?: () => void;
}

export const ModalScreen: React.FC<IProps> = (props) => {
  return (
    <div
      className="modalScreen__container"
      style={{
        animationName: props.closing === true ? 'hide-modal' : 'show-modal',
        animationDuration: `${props.transitionDuration}ms`,
      }}>
      {props.onActionIconClick && (
        <div
          className="modalScreen__icon-container"
          style={props.actionIcon ? {cursor: 'pointer'} : undefined}
          onClick={props.onActionIconClick}>
          {props.actionIcon ? props.actionIcon : null}
        </div>
      )}
      {props.children}
    </div>
  );
};
