import React from 'react';
import {useSelector} from 'react-redux';

import {
  IOverlay,
  IOverlayViewProps,
  OverlayDisplayState,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {IWebConfig} from '../../../config/WebConfig';
import {getConfig} from '../../../selectors/config/WebConfigSelectors';
import {AppDownload} from '../../Core/AppPrompt/AppDownload';
import {ModalScreen} from '../Containers/ModalScreen';
import {useCloseOverlayAfterTransition} from '../OverlayContainer';

interface IProps extends IOverlay, IOverlayViewProps {}

interface IStateProps {
  config: IWebConfig;
}
interface IDispatchProps {}

export const GetNativeLeaderboardsOverlay: React.FC<IProps> = (props) => {
  return (
    <GetNativeLeaderboardsOverlayView
      {...props}
      config={useSelector(getConfig)}
    />
  );
};

export const GetNativeLeaderboardsOverlayView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  useCloseOverlayAfterTransition(
    props.displayState as OverlayDisplayState,
    props.onTransitionOutComplete as () => void,
    props.transitionDuration,
  );

  let closing = props.displayState === OverlayDisplayState.TRANSITION_OUT;

  return (
    <ModalScreen
      closing={closing}
      transitionDuration={props.transitionDuration}>
      <AppDownload
        analyticsPrefix="leaderboards_prompt"
        config={props.config}
        pageUrl={window.location.href}
        title="Season Leaderboards are only in the App"
        description="Who’s top dog? Find out with our season leaderboards. You can compare answers with the best and reveal your own fan ranking."
        imageUri={`${props.config.cdnUrl}/vendors/_default-leaderboards-open-graph.jpg`}
        closeLabel="Maybe later"
        onDismiss={props.onClose}
      />
    </ModalScreen>
  );
};
